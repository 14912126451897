import PropTypes from 'prop-types'
import { ShareIcon } from '../../icons'
import styles from './share-count.module.scss'
import cx from 'classnames'
import { Color } from '../../../constants/colors'
/**
 * Shows the share count on each article card
 * @param {Object} article
 */

const ShareCount = ({
  iconProps = {},
  iconPosition = 'left',
  hideOnEmpty,
  showLabel,
  iconSpacing,
  shareCount
}) => {
  const isPositionedLeft = iconPosition === 'left'
  const haveShares = shareCount !== null && shareCount > 0
  return (
    <div className={cx(styles['drive-article-card__share__wrapper'])}>
      <ShareIcon
        color={shareCount > 0 ? Color.black : Color.gray400}
        className={cx(styles['drive-article-card__share__icon'], {
          hidden: hideOnEmpty && shareCount === null,
          [`mr-${iconSpacing} order-first`]: isPositionedLeft,
          [`ml-${iconSpacing} order-last`]: !isPositionedLeft
        })}
        {...iconProps}
      />
      <span
        className={cx(styles['drive-article-card__share__count'], {
          'text-gray-400': !shareCount || shareCount == 0,
          'text-black': shareCount > 0
        })}
      >
        {haveShares ? shareCount : ''}
      </span>
      {showLabel && haveShares && (
        <span className={cx(styles['drive-article-card__share__count__label'])}>
          {shareCount === 1 ? 'share' : 'shares'}
        </span>
      )}
    </div>
  )
}

ShareCount.propTypes = {
  iconProps: PropTypes.object,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  iconSpacing: PropTypes.number,
  hideOnEmpty: PropTypes.bool,
  showLabel: PropTypes.bool,
  shareCount: PropTypes.number
}

export default ShareCount
