import React, { FunctionComponent, useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './navigation.module.scss'
import { GlideOptions, GlideSubscriber, Status } from '../index'

/**
 * GlideNavBullets Navigation Component.
 * @type {Specific Component}
 * @param {Object} classNames @optional general classnames for component parts
 * @returns {JSX.Element}
 */
export const GlideNavBullets: FunctionComponent<GlideBulletsProps> = (props) => {
  const { classNames, status, subscriber, glideOptions } = props || {}
  const [activeBullets, setActiveBullets] = useState(status.bullets)
  const { eventId, subscribe } = subscriber

  const roundedPerView = glideOptions?.ignorePerViewInControls ? 1 : activeBullets.perView ?? 1
  const totalBullets = Math.ceil(activeBullets.slidesCount / roundedPerView)

  const [bulletInfo, setBulletInfo] = useState<BulletInfo[] | null>(null)

  useEffect(() => {
    subscribe(eventId, (newStatus) => {
      setActiveBullets(newStatus.bullets)
    })
  }, [eventId, subscribe])

  useEffect(() => {
    const newInfo: BulletInfo[] = []

    for (let index = 0; index < totalBullets; index++) {
      const glideDirIndex = index === 0 ? 0 : (roundedPerView ?? 1) * index
      const activeIndex = activeBullets.activeIndex
      const isInactive =
        (activeIndex === undefined && index !== 0) || // set other than first index to inactive
        (activeIndex !== undefined && activeIndex !== index) // set others to inactive other than the activeIndex
      newInfo.push({ glideDirIndex, isInactive, roundedPerView })
    }
    setBulletInfo(newInfo)
  }, [activeBullets.activeIndex, classNames?.dots, roundedPerView, totalBullets])

  const countIndexes = []
  for (let index = 0; index < totalBullets; index++) {
    countIndexes.push(index)
  }

  return (
    <div
      className={cx([styles['glide__bullets'], classNames?.wrapper])}
      data-glide-el='controls[nav]'
    >
      {/*
        - all information needs to be stored inside bulletInfo, or the update will not be picked up by glide
        - countIndexes is used to keep bullets registed by glide.
        - Using bulletInfo.map({},index) other conditional render will fail to register.
      */}
      {countIndexes.map((index) => {
        const isInactive = bulletInfo?.[index]?.isInactive
        const glideDirIndex = bulletInfo?.[index]?.glideDirIndex ?? 0
        const roundedPerView = bulletInfo?.[index]?.roundedPerView ?? 1

        return (
          <button
            key={`glide-bullet-${index}`}
            className={cx(
              styles['glide__bullet-item'],
              {
                [cx([styles['glide__bullet-item--active']])]: !isInactive && roundedPerView > 1, //glidejs takes care of activating bullets when perview is 1
                [cx([styles['glide__bullet-item--inactive']])]: isInactive && roundedPerView > 1
              },
              classNames?.dots
            )}
            data-glide-dir={`=${glideDirIndex}`}
          ></button>
        )
      })}
    </div>
  )
}

export type GlideBulletsProps = {
  classNames?: {
    wrapper?: string
    dots?: string
  }
  glideOptions?: GlideOptions
  status: Status
  subscriber: GlideSubscriber
}

type BulletInfo = {
  glideDirIndex: number
  isInactive: boolean
  roundedPerView: number
}
