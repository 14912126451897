import { DEFAULT_CFS_FILTERS } from '@grille/components/cars-for-sale/listings/constants'
import { generateCFSUrl } from '@grille/components/cars-for-sale/listings/helper'
import { Filters, LabelOptionRecord } from '@grille/types/listings'
import isEmpty from 'lodash/isEmpty'

export const getBreadCrumb = (label: string, newFilters: Partial<Filters>) => {
  return isEmpty(label)
    ? null
    : {
        text: label,
        url: generateCFSUrl({ filters: { ...DEFAULT_CFS_FILTERS, ...newFilters } }).url
      }
}

/**
 * generate breadcrumbs based on filters and options
 * @param filters
 * @param optionRecord
 * @returns
 */
export const getParsedBreadcrumbs = (
  filters: Filters,
  optionRecord: LabelOptionRecord
): ({
  text: string
  url: string
} | null)[] => {
  const { state, region, make, model, listingType: listingTypeArray } = filters ?? {}
  const listingType = [listingTypeArray?.[0] ?? '']

  // ordered list of possible breadcrumbs based on filters
  const breadcrumbs = [
    //1. listType
    { label: optionRecord?.listingType?.selected?.[0]?.label, filters: { listingType } },
    //2. state
    { label: optionRecord?.state?.selected?.label ?? state, filters: { listingType, state } },
    //3. region
    {
      label: optionRecord?.region?.selected?.label ?? region,
      filters: { listingType, state, region }
    },
    //4. make
    {
      label: optionRecord?.make?.selected?.label ?? make,
      filters: { listingType, state, region, make }
    },
    //5. model
    {
      label: optionRecord?.model?.selected?.label ?? model,
      filters: { listingType, state, region, make, model }
    }
    // map over to generate breadcrumbs
  ].map(({ label, filters }) => getBreadCrumb(label ?? '', filters))

  return [
    { text: 'Home', url: '/' },
    { text: 'Marketplace', url: '/cars-for-sale/' },
    { text: 'Search Cars For Sale', url: '/cars-for-sale/search/' },
    ...breadcrumbs
  ].filter((breadCrumb) => !!breadCrumb) //only keep defined breadcrumbs
}
