import isEmpty from 'lodash/isEmpty'
import format from 'date-fns/format'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'

/**
 * Get date in format of mm.dd.yy
 *
 * @param {string} dateString Date string, example 2020-05-03T04:41:12
 * @param {boolean} dashFormat Whether we need date separated by '-' (optional)
 *
 * @return {string} Date.
 */
export const getFormattedDate = (dateString, dateFormat = 'dd MMMM yyyy') => {
  if (isEmpty(dateString)) {
    return ''
  }

  // '-' is not fully support by Date, will fail in safiri, convert to 'T', revert when handled by backend
  const date = new Date(dateString.replace(/ /g, 'T'))
  // const date = new Date(dateString)

  return format(date, dateFormat)
}

/**
 * Prefix number with zero if its single digit
 *
 * Return the number as string.
 *
 * @param {number} theNumber Number.
 *
 * @returns {string} Formatted number as string.
 */
export const prefixWithZero = (theNumber) => {
  const formattedNumber = theNumber.toString()
  return formattedNumber.length === 1 ? `0${formattedNumber}` : formattedNumber
}

/**
 * Get formatted time
 *
 * @param {string} dateString Date string, example 2020-05-03T04:41:12
 *
 * @returns {string}
 */
export const getFormattedTime = (dateString) => {
  if (isEmpty(dateString)) {
    return ''
  }

  const date = new Date(dateString)
  const minutes = prefixWithZero(date.getMinutes())
  const hours = prefixWithZero(date.getHours())

  return `${hours}:${minutes}`
}

/**
 * Calculate time left.
 *
 * @param {String} futureDateTime Future DateTime
 *
 * @return {Object}
 */
export const calculateTimeLeft = (futureDateTime) => {
  let timeLeft = {}

  if (!futureDateTime) {
    return timeLeft
  }

  const currentDate = new Date()
  const compareDate = new Date(futureDateTime.replace(' ', 'T') + '+10:00')
  const difference = differenceInMilliseconds(compareDate, currentDate)
  if (difference <= 0) {
    return timeLeft
  }

  timeLeft = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60)
  }

  return timeLeft
}

/**
 * Check the give data and time is passed or not.
 *
 * @param {String} dateTime DateTime.
 *
 * @return {Bool}
 */
export const isDateTimePassed = (dateTime) => {
  if (!dateTime) {
    return true
  }

  const difference = getFutureDateDiff(dateTime)

  if (difference <= 0) {
    return true // Date time passed.
  }

  return false // Date and time yet to pass.
}

/**
 * Function to get future date difference.
 *
 * @param {String} dateTime Future date time.
 *
 * @return {String}
 */
export const getFutureDateDiff = (dateTime) => {
  // For dateTime string to work with new Date(), replace white spaces to 'T'
  const formattedDateTime = dateTime.replace(' ', 'T')

  return +new Date(formattedDateTime) - +getAuTime()
}

/**
 * Function to get local time. By default return AEDT time.
 *
 * @param {Number} offset Offset to get the local AU time.
 *
 * @return {String}
 */
export const getAuTime = (offset = +11) => {
  const d = new Date()
  const utc = d.getTime() + d.getTimezoneOffset() * 60000
  const nd = new Date(utc + 3600000 * offset)
  return nd
}
