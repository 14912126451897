/**
 * External dependencies.
 */
import cx from 'classnames'
import Link from '../../../common/link'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import styles from '../footerCols.module.scss'

/**
 * ColumnOne component.
 */
const ColumnOne = ({ menuItems = [], classes = '' }) => {
  if (isEmpty(menuItems)) {
    return null
  }

  return (
    <div className={cx('footer-column-one font-primary', classes)}>
      <ul>
        {menuItems.map((menuItem, index) => {
          return (
            <li
              className={cx(styles['drive-footer-col__one__container'])}
              key={`${menuItem?.node?.id}-${index}`}
            >
              <Link href={menuItem?.node?.path}>
                <a className={cx(styles['drive-footer-col__one__item'])}>{menuItem?.node?.label}</a>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ColumnOne.protoTypes = {
  menuItems: PropTypes.array,
  classes: PropTypes.string
}

export default ColumnOne
