import { string, instanceOf } from 'prop-types'
import { useEffect, useState } from 'react'
import { useUpdatePageCtx } from '../contexts/pageContext'
/**
 * @hook CollectStoreAdData Custom Hook.
 * @reason Needed to parse through google ad Iframe dom to collect specific element info -
 * inorder to apply some styles and render component based on what's in the loaded iframe
 * @jobs Stores requested gamIds element attributes key and value in the global Page Context
 * @listens event:slotRenderEnded googletag.pubads
 * @listens variable:isLoaded continiously listens to the Ad.js injected neccessary Dom passed via adStatus
 * @listens variable:hasSearched checks if it has already searched
 *
 * @param {Object} gamInfo        Specific GoogleAd info @required gamId: String
 * @param {Boolean} skip          optional part of gamInfo. Used for to skip this search which benefits able to use this hook
 *                                conditionally while still following the rules of react - Each renders must call same exact
 *                                number of previously ran hooks
 * @param {Object} adStatus       isLoaded and CurrentReactRef Slot Dom info from Ad.js.
 * @param {Array} [$dependencies] optional any dependencies that needs to be watched and run the hook if all the required @params are available
 *
 * @callback afterCollectionCallBack    Runs function after it has searched allowing others to modify it before it stores
 *                                      holds current state of status with errors and collected Data
 *
 * @return {Object}                     Returns current object of status, errors and Collected Data
 */

export const useCollectStoreAdData = (
  gamInfo = { gamId: string, dataToCollect: [], skip: false },
  adStatus = { adElRefCurrent: null, isLoaded: false },
  afterCollectionCallBack = (
    collectedData = {
      processing: false,
      errors: null || instanceOf(new Error()),
      data: null || instanceOf(Object),
      skipStore: false
    }
  ) => collectedData.data,
  $dependencies = []
) => {
  const [hasSearched, setHasSearched] = useState(false)
  const [collectedAdData, setCollectedAdData] = useState({
    processing: true,
    errors: null,
    data: null
  })
  const { updateStore } = useUpdatePageCtx()

  const { gamId, dataToCollect, skip } = gamInfo
  const { adElRefCurrent, isLoaded } = adStatus

  useEffect(() => {
    // run if window is available, only once minimize double render and if it is intended @param !skip.
    if (isLoaded && adElRefCurrent && !skip && typeof window !== 'undefined') {
      const updateToPageStore = ({ processing, errors, gamData }) => {
        let dataToStore = gamData || {}
        if (afterCollectionCallBack) {
          /* run any function here after successfully collected Data
           * here process the function and see anything return to change the collectedData
           */
          const data = afterCollectionCallBack({
            processing,
            errors,
            data: gamData,
            skipStore: false
          })
          // store the data to pageContext store
          if (data) {
            const { skipStore } = data
            if (skipStore) {
              return
            }
            dataToStore = data
          }
        }
        updateStore(gamId, { ...dataToStore })
      }

      const searchAndCollectAttributes = () => {
        if (hasSearched) return
        try {
          // This is reading only - not manipulating dom as iframe was loaded by ad with external javascript
          const iframe =
            adElRefCurrent.getElementsByTagName('iframe')[0]?.contentWindow?.document ?? undefined
          if (iframe) {
            const gamAdStates = {}
            if (dataToCollect.length) {
              dataToCollect.forEach((tag) => {
                const pos = tag?.pos ?? 0 // if tag pos is set then set the pos or defaults to 0 pos
                const nodeAttributes = iframe.getElementsByTagName(tag.name)[pos].attributes
                if (nodeAttributes.length) {
                  const tagsData = {}
                  for (let index = 0; index < nodeAttributes.length; index++) {
                    const attr = nodeAttributes[index]
                    tagsData[attr.name] = attr.value
                  }
                  gamAdStates[tag.name] = { ...tagsData }
                }
              })
            }
            // set the data
            setCollectedAdData({ processing: false, errors: null, data: { ...gamAdStates } })
            // update to pageStore context
            updateToPageStore({ processing: false, errors: null, gamData: { ...gamAdStates } })
          } else {
            // iframe not found
            setCollectedAdData({
              processing: false,
              errors: new Error(`Could not find the iframe for ${gamId}`),
              data: {}
            })
          }
        } catch (error) {
          // any error catched will be tracked that may be code issue or the parsing
          setCollectedAdData({ processing: false, errors: error, data: {} })
        }

        setHasSearched(true) // set this true here as now its searching even its not found, its still searched
      }
      searchAndCollectAttributes()
    }
  }, [
    isLoaded,
    hasSearched,
    adElRefCurrent,
    skip,
    afterCollectionCallBack,
    updateStore,
    gamId,
    dataToCollect,
    $dependencies,
    adStatus
  ])
  return collectedAdData
}
