import FollowIcons from '../common/follow-icons'
import cx from 'classnames'
import styles from './subscribe.module.scss'
import { FOLLOW_ICON_URLS } from '@grille/constants/follow-icon-urls'

const {
  NEXT_PUBLIC_YOUTUBE_URL: YOUTUBE_URL,
  NEXT_PUBLIC_FACEBOOK_URL: FACEBOOK_URL,
  NEXT_PUBLIC_TWITTER_URL: TWITTER_URL,
  NEXT_PUBLIC_INSTAGRAM_URL: INSTAGRAM_URL,
  NEXT_PUBLIC_LINKEDIN_URL: LINKEDIN_URL,
  NEXT_PUBLIC_SPOTIFY_URL: SPOTIFY_URL
} = FOLLOW_ICON_URLS

const SubscribeSuccessMessage = () => {
  return (
    <div className={cx(styles['drive-subscribe-message__wrapper'])}>
      <div className={cx(styles['drive-subscribe-message__heading'])}>
        Thanks, you’re now subscribed to Drive.
      </div>
      <div className={cx(styles['drive-subscribe-message__content'])}>
        You can unsubscribe anytime by following the link in any of our emails.
      </div>
      <FollowIcons
        bgClass={cx(styles['drive-subscribe-message__follow-bg'])}
        iconClassName={cx(styles['drive-subscribe-message__follow-icon'])}
        wrapperClassName={cx(styles['drive-subscribe-message__follow-wrapper'])}
        size={2}
        twitterUrl={TWITTER_URL}
        facebookUrl={FACEBOOK_URL}
        instagramUrl={INSTAGRAM_URL}
        youtubeUrl={YOUTUBE_URL}
        linkedinUrl={LINKEDIN_URL}
        spotifyUrl={SPOTIFY_URL}
        iconColor={''}
      />
    </div>
  )
}

export default SubscribeSuccessMessage
