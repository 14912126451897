import cx from 'classnames'
import {
  InstagramIcon,
  FacebookIcon,
  YoutubeIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  SpotifyIcon
} from '../../icons'
import Link from '../link'
import styles from './followIcons.module.scss'
import { isTestEnv } from '../../../utils/functions/isTestEnv'
const isTest = isTestEnv()

/**
 * FollowIcons Component.
 *
 * @param {Number, String, String, String, String} { size, bgClass, facebookUrl, youtubeUrl, twitterUrl, instagramUrl, linkedinUrl } as props
 *
 * @returns {JSX.Element}
 */
const FollowIcons = ({
  facebookUrl,
  youtubeUrl,
  twitterUrl,
  instagramUrl,
  linkedinUrl,
  spotifyUrl,
  email,
  wrapperClassName,
  iconClassName,
  iconColor = '#ffffff',
  size = 2,
  bgClass = styles['drive-social__background']
}: FollowIconsType) => {
  return (
    <div
      className={cx(styles['drive-social__wrapper'], wrapperClassName)}
      data-testid={isTest ? 'follow-buttons' : undefined}
    >
      {facebookUrl ? (
        <Link
          data-testid={isTest ? 'facebook-follow-button' : undefined}
          href={facebookUrl}
          rel='noopener nofollow'
          className={cx(styles['drive-social__link'], bgClass)}
          style={{ width: `${size}rem`, height: `${size}rem` }}
          isExternal
        >
          <FacebookIcon
            height='16'
            color={iconColor}
            className={cx(styles['drive-social__icon'], iconClassName)}
            alt='Visit Facebook page'
          />
        </Link>
      ) : null}
      {youtubeUrl ? (
        <Link
          data-testid={isTest ? 'youtube-follow-button' : undefined}
          href={youtubeUrl}
          rel='noopener nofollow'
          className={cx(styles['drive-social__link'], bgClass)}
          style={{ width: `${size}rem`, height: `${size}rem` }}
          isExternal
        >
          <YoutubeIcon
            height='16'
            color={iconColor}
            className={cx(styles['drive-social__icon'], iconClassName)}
            alt="Visit Drive's YouTube page"
          />
        </Link>
      ) : null}
      {instagramUrl ? (
        <Link
          data-testid={isTest ? 'instagram-follow-button' : undefined}
          href={instagramUrl}
          rel='noopener nofollow'
          className={cx(styles['drive-social__link'], bgClass)}
          style={{ width: `${size}rem`, height: `${size}rem` }}
          isExternal
        >
          <InstagramIcon
            height='16'
            color={iconColor}
            className={cx(styles['drive-social__icon'], iconClassName)}
            alt='Visit Instagram page'
          />
        </Link>
      ) : null}
      {twitterUrl ? (
        <Link
          data-testid={isTest ? 'twitter-follow-button' : undefined}
          href={twitterUrl}
          rel='noopener nofollow'
          className={cx(styles['drive-social__link'], bgClass)}
          style={{ width: `${size}rem`, height: `${size}rem` }}
          isExternal
        >
          <TwitterIcon
            height='16'
            color={iconColor}
            className={cx(styles['drive-social__icon'], iconClassName)}
            alt='Visit Twitter page'
          />
        </Link>
      ) : null}
      {linkedinUrl ? (
        <Link
          data-testid={isTest ? 'linkedin-follow-button' : undefined}
          href={linkedinUrl}
          rel='noopener nofollow'
          className={cx(styles['drive-social__link'], bgClass)}
          style={{ width: `${size}rem`, height: `${size}rem` }}
          isExternal
        >
          <LinkedinIcon
            height='16'
            color={iconColor}
            className={cx(styles['drive-social__icon'], iconClassName)}
            alt='Visit the Drive Linkedin page'
          />
        </Link>
      ) : null}
      {email ? (
        <Link
          data-testid={isTest ? 'email-follow-button' : undefined}
          href={`mailto:${email}`}
          rel='noopener nofollow'
          className={cx(styles['drive-social__link'], bgClass)}
          style={{ width: `${size}rem`, height: `${size}rem` }}
          isExternal
        >
          <EmailIcon
            height='16'
            color={iconColor}
            className={cx(styles['drive-social__icon'], iconClassName)}
            alt='Visit the Drive Linkedin page'
          />
        </Link>
      ) : null}
      {spotifyUrl ? (
        <Link
          data-testid={isTest ? 'spotify-follow-button' : undefined}
          href={spotifyUrl}
          rel='noopener nofollow'
          className={cx(styles['drive-social__link'], bgClass)}
          style={{ width: `${size}rem`, height: `${size}rem` }}
          isExternal
        >
          <SpotifyIcon
            height='16'
            color={iconColor}
            className={cx(styles['drive-social__icon'], iconClassName)}
            alt='Drive Podcast - Listen on Spotify'
          />
        </Link>
      ) : null}
    </div>
  )
}

type FollowIconsType = {
  size?: number
  bgClass?: string
  facebookUrl?: string
  linkedinUrl?: string
  youtubeUrl?: string
  twitterUrl?: string
  instagramUrl?: string
  spotifyUrl?: string
  email?: string
  wrapperClassName?: string
  iconClassName: string
  iconColor?: string
}

export default FollowIcons
