export const PARTNERS_LOGOS = [
  {
    logoName: 'SmhLogoWhite',
    logoLink: 'https://www.smh.com.au/',
    className: 'drive-footer__partners-logo__logo-mr-50px',
    iconProps: {
      height: 32
    }
  },
  {
    logoName: 'AgeLogoStackedWhite',
    logoLink: 'https://www.theage.com.au/',
    className: 'drive-footer__partners-logo__logo-mr-50px',
    iconProps: {
      height: 18,
      width: 124,
      resize: 'auto',
      color: '#ffffff'
    }
  },
  {
    logoName: 'BtLogoNotaglineWhite',
    logoLink: 'https://www.brisbanetimes.com.au/',
    className: 'drive-footer__partners-logo__logo-mr-32px',
    iconProps: {
      height: 24,
      color: '#ffffff'
    }
  },
  {
    logoName: 'WatodayLogoNotaglineWhite',
    logoLink: 'https://www.watoday.com.au/',
    className: 'drive-footer__partners-logo__logo-mr-3px',
    iconProps: {
      height: 14
    }
  },
  {
    logoName: 'NinecomauLogoWhite',
    logoLink: 'https://www.nine.com.au/',
    className: 'drive-footer__partners-logo__logo-mr-neg-5px',
    iconProps: {
      height: 14
    }
  },
  {
    logoName: 'Svg9NowLogoWhite',
    logoLink: 'https://www.9now.com.au/',
    iconProps: {
      height: 14
    }
  }
]

export const REDBOOK_DISCLAIMER_ID = 'readbook-disclaimer'
