import isInteger from 'lodash/isInteger'
import {
  CFSSearchRawSlugValues,
  HashFilters,
  SearchConfig,
  SlugFilters
} from '@grille/types/listings'
import logger from '@grille/utils/logger'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import {
  CFS_SEARCH_BASE_PATH,
  DEFAULT_CFS_HASH_FILTERS,
  DEFAULT_CFS_SLUG_FILTERS,
  LISTING_TYPE_OPTIONS
} from '../constants'
import { DEFAULT_SORT_OPTION, STATE_NAME_MAP } from '@grille/constants/cars-for-sale'
import isString from 'lodash/isString'
import isNull from 'lodash/isNull'
export * from './cfs-url-conversions'

export const extractInfoFromFacadeSlugArray = (facadeCTXSlugs: string[] = []) => {
  let pageNo = 1
  if (facadeCTXSlugs?.[1] === 'page' && isNumber(parseInt(facadeCTXSlugs?.[2]))) {
    pageNo = parseInt(facadeCTXSlugs[2])
  }

  return {
    facade: facadeCTXSlugs[0],
    pageNo: pageNo
  }
}
/**
 * Function to check if slug filter value is valid
 * @param filter {string|null} filter value
 * @returns {boolean} Returns true if slug filter valid ( null considered as valid value )
 */
const isValidSlugFilter = (filter: string | null) => {
  if (!isNull(filter)) {
    if (!/[a-zA-Z0-9]/.test(filter)) {
      return false
    }
    const invalidFilterValues = ['undefined', 'null', 'false', '', 'NaN']
    if (invalidFilterValues.includes(filter)) {
      return false
    }

    if (!isString(filter)) {
      return false
    }
  }

  return true
}

/**
 * Function to check if listing type slug filter value is valid
 * @param listingType {SlugFilters['listingType']} Listing type value
 * @returns Return true if value is from any of supported listing type values
 */
const isValidListingType = (listingType: string | null) => {
  if (!isNull(listingType)) {
    if (!isValidSlugFilter(listingType)) {
      return false
    }
    if (![...LISTING_TYPE_OPTIONS.map((type) => type.value), 'all'].includes(listingType)) {
      return false
    }
  }
  return true
}

/**
 * Function to check if state slug filter value is valid
 * @param state {SlugFilters['state']} State value
 * @returns Returns true if value is valid state slug
 */
const isValidState = (state: SlugFilters['state']) => {
  if (!isValidSlugFilter(state)) {
    return false
  }
  if (!isNull(state)) {
    // If state is not from STATE_NAME_MAP and is not 'all'
    if (state !== 'all' && !Object.keys(STATE_NAME_MAP).includes(state)) {
      return false
    }
  }
  return true
}

/**
 * Function to check if its valid region.
 * @param state {string} State
 * @param region {string} Region
 * @returns Returns true if region is valid and state and region is not 'all'
 */
const isValidRegionFilter = (state: string | null, region: string | null) => {
  if (isValidSlugFilter(region) && state !== 'all') {
    return true
  }

  if (isValidSlugFilter(region) && state === 'all' && region === 'all') {
    return true
  }
  return false
}

/**
 * //TODO: this may not be needed anymore
 * Function to check slug filter values
 * @param slugFilters {SlugFilters} Slug filter values
 * @returns Returns true if all values are valid
 */
export const isValidSlugFilters = (slugs: string[]) => {
  const slugFilters = getSlugFilters(slugs)
  if (slugFilters === null) {
    return false
  }

  const { listingType, make, model, region, state } = slugFilters ?? {}

  if (!isValidListingType(listingType)) {
    return false
  }

  if (!isValidState(state)) {
    return false
  }

  if (!isValidRegionFilter(state, region)) {
    return false
  }

  if (!isValidSlugFilter(make)) {
    return false
  }

  if (!isValidSlugFilter(model)) {
    return false
  }

  return true
}

/**
 * return null if failed to convert to slug filters
 * @param slugs
 * @returns
 */
export const getSlugFilters = (slugs: string[]): CFSSearchRawSlugValues | null => {
  // handles any empty slugs due to splitting by '/' from pathname
  const slugsClone = slugs?.filter((slug) => !isEmpty(slug)) ?? []

  //we know that page/<pageNo> is optional and will always be at the end of the slug
  // page no from url starts from 1
  let pageNo: number = 1
  if (slugsClone.length >= 2 && slugsClone[slugsClone.length - 2] === 'page') {
    pageNo = parseInt(slugsClone.pop() ?? '') // pop page number
    // invalid page number
    if (!isInteger(pageNo) || pageNo < 1) {
      return null
    }
    slugsClone.pop() // remove page slug
  }
  let listingType = null
  // shift listing type from array if it is the first element.
  if ([...LISTING_TYPE_OPTIONS.map((o) => o.value)].includes(slugsClone?.[0])) {
    listingType = slugsClone.shift() ?? null
  }
  //at this stage, slugClone can have no more than 4 slugs inside
  if (slugsClone.length > 4) {
    return null
  }

  return {
    state: slugsClone?.[0]?.toLowerCase() ?? null,
    region: slugsClone?.[1]?.toLowerCase() ?? null,
    make: slugsClone?.[2]?.toLowerCase() ?? null,
    model: slugsClone?.[3]?.toLowerCase() ?? null,
    listingType: listingType?.toLowerCase() ?? null,
    pageNo
  }
}

/**
 * @description valid raw cfs search url slugs patterns
 * - takes in filters converted from url slugs
 * - validations on:
 *    - pageNo value
 *    - 'all' checks
 * invalid patterns:
 * - all cannot be last slug, only make or page can be immediate slug after location filters.
 *   /<anything>/all/
 * - specific region should be after specific state
 *   /all/region/
 *
 * valid examples:
 *   /<state>/<region>
 *   /<state>/all/*
 *   /all/all/<make or page>
 *   /all/<make or page>
 */
export const validateRawSlugPattern = ({
  state,
  region,
  make,
  pageNo
}: Partial<CFSSearchRawSlugValues>): boolean => {
  //pageNo can be empty, but needs to be must not be less than 1 if defined
  if ((pageNo ?? 1) < 1) {
    return false
  }
  if (isEmpty(region) && isEmpty(state)) {
    //early return if no location filter
    return true
  }
  ////has 'all' in location slugs
  if ([state, region].includes('all')) {
    //if not followed by make or pageNo => invalid
    if (isEmpty(make) && isEmpty(pageNo)) {
      return false
      //specific region should be after specific state
    }
    //followed by make or pageNo, if state is all but region is specific, invalid
    if (state === 'all' && !isEmpty(region) && region !== 'all') {
      return false
    }
  }
  return true
}

/**
 * for search page only
 * return null if failed to convert
 * @param slugs
 * @returns
 */
export const convertSlugArrayToFilterObject = (
  slugs: string[]
): { slugFilters: SlugFilters; pageNo: number } | null => {
  const slugFilters: SlugFilters = {
    ...DEFAULT_CFS_SLUG_FILTERS
  }
  if (isEmpty(slugs)) {
    return { slugFilters, pageNo: 1 }
  }
  const rawSlugs = getSlugFilters(slugs)
  if (rawSlugs === null || !validateRawSlugPattern(rawSlugs)) {
    return null
  }

  const { state, region, listingType, pageNo, make, model } = rawSlugs

  slugFilters.listingType = slugFilters.listingType = listingType ? [listingType] : null
  slugFilters.state = state === 'all' ? null : state
  slugFilters.region = region === 'all' ? null : region
  slugFilters.make = make
  slugFilters.model = model
  return { slugFilters, pageNo }
}

/**
 * @param hashstring #price=[30000,125000]&year=[1900,2000]&seats=3&doors=4&transmission=manual
 * @returns
 */
export const extractDataFromHashString = (
  hashString: string = ''
): { hashFilters: HashFilters; otherHashObjects: { [key: string]: any } } => {
  const hashObject: { [key: string]: any } = convertHashToObject(hashString)
  const hashFilters: HashFilters = { ...DEFAULT_CFS_HASH_FILTERS }
  const otherHashObjects: { [key: string]: any } = {}
  Object.keys(hashObject).forEach((key: string) => {
    if (key in hashFilters) {
      hashFilters[key as keyof HashFilters] = hashObject[key]
    } else {
      otherHashObjects[key] = hashObject[key]
    }
  })

  return {
    hashFilters,
    otherHashObjects
  }
}

/**
 * @description
 * does not depend on window,
 * returns extracted search config or null if extraction failed
 * @param pathname
 * - eg./cars-for-sale/search/new/
 * - pathname will be without page no
 */
export const extractSearchConfigFromRelativeUrl = (pathname: string): SearchConfig | null => {
  const pathNameFragments = pathname.split('#')
  // there can be a leading /
  const slugs = pathNameFragments[0].replace(CFS_SEARCH_BASE_PATH, '').split('/')
  const convertedFilterObject = convertSlugArrayToFilterObject(slugs)
  if (convertedFilterObject === null) {
    return null
  }
  const { slugFilters }: { slugFilters: SlugFilters } = convertedFilterObject
  const hash = pathNameFragments?.[1] || ''

  const { hashFilters, otherHashObjects } = extractDataFromHashString(hash)
  const listingType = [...(slugFilters.listingType ?? []), ...(hashFilters.listingType ?? [])]

  return {
    filters: { ...hashFilters, ...slugFilters, listingType },
    sortBy: otherHashObjects?.sortBy || DEFAULT_SORT_OPTION.id
  }
}

/**
 * @description
 * returns extracted search config or null if extraction failed
 *
 * @param pathname  eg./cars-for-sale/search/new/
 */
export const extractSearchConfigFromCFSSearch = (
  slugsOnly: boolean = false
): SearchConfig | null => {
  if (typeof window !== 'undefined') {
    const slugs = window.location.pathname.replace(CFS_SEARCH_BASE_PATH, '').split('/')
    const filterObject = convertSlugArrayToFilterObject(slugs)
    if (filterObject === null) {
      return null
    }
    const { slugFilters, pageNo }: { slugFilters: SlugFilters; pageNo: number } = filterObject

    if (slugsOnly) {
      return {
        filters: { ...DEFAULT_CFS_HASH_FILTERS, ...slugFilters },
        pageNo: pageNo,
        sortBy: DEFAULT_SORT_OPTION.id
      }
    }

    const hash = window.location.hash
    const { hashFilters, otherHashObjects } = extractDataFromHashString(hash)
    const slugListingTypes = slugFilters?.listingType ?? []
    const hashListingTypes = hashFilters?.listingType ?? []
    const listingType = [...slugListingTypes, ...hashListingTypes]
    return {
      filters: { ...hashFilters, ...slugFilters, listingType },
      pageNo: pageNo,
      sortBy: otherHashObjects?.sortBy || DEFAULT_SORT_OPTION.id
    }
  }
  return {
    filters: { ...DEFAULT_CFS_SLUG_FILTERS, ...DEFAULT_CFS_HASH_FILTERS },
    sortBy: DEFAULT_SORT_OPTION.id
  }
}

/**
 * @param {String} hash @required eg.#price=[30000,125000]&year=[1900,2000]&seats=3&doors=4&transmission=manual&listingType=["demo","used"]
 * @returns {Object}
 */
const convertHashToObject = (hash: string = ''): { [key: string]: any } => {
  hash = hash.replace('#', '')
  if (isEmpty(hash)) {
    return {
      ...DEFAULT_CFS_HASH_FILTERS
    }
  }

  const queryObj: Record<string, any> = {}
  const hashFragments: string[] = hash.split('&')
  hashFragments.forEach((fragment: string) => {
    const parts: string[] = fragment.split('=')
    const key: string = parts[0]
    const valString: string = parts?.[1] ?? ''

    //TODO: so far no issue, there may ba an edge case
    // let decodedVal: string = decodeURIComponent(valString.replace(/-/g, ' '))

    let decodedVal: string = decodeURIComponent(valString)
    // match if array pattern []
    if (/^\[.*\]$/g.test(decodedVal)) {
      try {
        decodedVal = JSON.parse(decodedVal)
      } catch (error) {
        logger.error(error)
      }
    }
    queryObj[decodeURIComponent(key)] = decodedVal
  })

  return queryObj
}

/**
 * @param {String} listingId @required eg.876876 , g-125
 * @returns {Boolean}
 */
export const checkGhostListing = (listingId: string | string[] | undefined): boolean => {
  const ghostlistingRegex = /^g-\d+$/
  return typeof listingId === 'string' ? ghostlistingRegex.test(listingId) : false
}

/**
 * @param {String} listingId @required eg.876876 , g-125
 * @returns {String}
 */
export const extractListingId = (listingId: string | string[] | undefined): string | undefined => {
  const ghostlistingRegex = /^g-(\d+)$/
  const isGhostListing = typeof listingId === 'string' ? ghostlistingRegex.exec(listingId) : null
  return isGhostListing ? isGhostListing[1] : listingId?.toString()
}
