import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './label.module.scss'

const AnnexCardLabel = ({ text, className }) => {
  return <span className={cx(styles['drive-label__annex-card'], className)}>{text}</span>
}

AnnexCardLabel.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
}

AnnexCardLabel.defaultProps = {
  className: ''
}

export default AnnexCardLabel
