'use client'
import cx from 'classnames'
import { TransitionProvider } from '../../common/ad-units/transitions'
import styles from './pageContainer.module.scss'
import AppRouterSchema from './app-router-schema'
import isEmpty from 'lodash/isEmpty'
export default function PageContainer({ children, ldSchema = null }) {
  const transition = {
    /* halting adrender at this point
     * GTMComponent sets other pageData to gam in the pageWrapper
     */
    isTransitioning: false,
    coorelator: `${Math.random() * 1e7}`.split('.')[0]
  }
  return (
    <>
      <TransitionProvider value={transition}>
        <div className={cx(styles['drive-page-wrapper__container'])}>{children}</div>
      </TransitionProvider>
      {!isEmpty(ldSchema) ? <AppRouterSchema schema={ldSchema} /> : null}
    </>
  )
}
