import isEmpty from 'lodash/isEmpty'
import { getImageBySize } from './functions/images'
import {
  CARS_FOR_SALE_LISTING_SINGLE_PAGE,
  CARS_FOR_SALE_ARCHIVES_PAGE,
  CARS_FOR_SALE_ARCHIVES_PAGE_WITHOUT_PIPE
} from './../constants/pages'
import { doesHeroImageExist } from './functions/miscellaneous'
import { getHeroImage } from './functions/images'
/**
 * Parse og title depending on which page user is on
 *
 * @param {String | null} customOgTitle customOgTitle - parsed in certain pages
 * @param {String} pageName pageName: please use utils/constants.js
 * @returns {String} ogTitle
 */
export const getOgTitle = (customOgTitle, pageName) => {
  if (!isEmpty(customOgTitle)) {
    switch (pageName) {
      case CARS_FOR_SALE_ARCHIVES_PAGE_WITHOUT_PIPE:
        return `${customOgTitle}`
      case CARS_FOR_SALE_LISTING_SINGLE_PAGE:
        return `${customOgTitle} - Drive`
      case CARS_FOR_SALE_ARCHIVES_PAGE:
        return `${customOgTitle} | Drive Cars For Sale`
      default:
        return `${customOgTitle} | Drive`
    }
  }

  return customOgTitle
}

/**
 * Parse seo title depending on which page user is on
 *
 * @param {String | null} customPageTitle customPageTitle - parsed in certain pages
 * @param {String} pageName pageName: please use utils/constants.js
 * @param {String} fallbackTitle usually the title from gql
 * @returns {String} seoTitle
 */
export const getSeoTitle = (customPageTitle, pageName, fallbackTitle) => {
  if (!isEmpty(customPageTitle)) {
    switch (pageName) {
      case CARS_FOR_SALE_ARCHIVES_PAGE_WITHOUT_PIPE:
        return `${customPageTitle}`
      case CARS_FOR_SALE_LISTING_SINGLE_PAGE:
      case CARS_FOR_SALE_ARCHIVES_PAGE:
        return `${customPageTitle} - Drive`
      default:
        return `${customPageTitle} | Drive`
    }
  }

  return customPageTitle || fallbackTitle
}

/**
 * Get modified time, some pages have it null and shouldnt appear in meta tags
 *
 * @param {String} modifiedTime modifiedTime: usually comes from gql
 * @param {String} pageName pageName: please use utils/constants.js
 * @returns {String} modifiedTime
 */
export const getModifiedTime = (modifiedTime, pageName) => {
  switch (pageName) {
    case CARS_FOR_SALE_LISTING_SINGLE_PAGE:
      return null
    default:
      return modifiedTime
  }
}

/**
 * Parse seo image data
 *
 * @param {String} srcUrl image src
 * @param {Object} cloudinary {publicId, cloudName, deliveryType}
 * @param {String} sizeName cloudinary size name
 * @returns {Object} returns preloadImageData and seo image data
 */
export const getImageData = (srcUrl, cloudinary, sizeName) => {
  const preloadImageData = getImageBySize(srcUrl, sizeName, cloudinary)

  return {
    preloadImageData,
    customImageMeta: preloadImageData.src
  }
}

/**
 * Parse seo image data for first 2 Archive post images
 *
 * @param {*} posts
 * @returns [{Object}] returns preloadImageData and seo image data for first 2 images
 */
export const getPreloadImagesData = (articles) => {
  const preloadImagesData = []
  if (isEmpty(articles)) {
    return preloadImagesData
  }

  for (let index = 0; index < 2; index++) {
    const article = articles?.edges?.[index]?.node ?? {}

    const image = doesHeroImageExist(article?.singleReview?.reviewsMeta?.hero)
      ? article?.singleReview?.reviewsMeta?.hero ?? {}
      : article?.image?.node || article?.thumbnail?.node || article.image || {}

    let heroImage = image
    if (image?.fileType !== undefined) {
      if (image.fileType === 'video' && 'mediumPoster' in image) {
        heroImage = image.mediumPoster
      } else {
        heroImage = getHeroImage(image, article, false)
      }
    }

    if (!isEmpty(heroImage)) {
      const parsedImageData = getImageData(
        heroImage?.sourceUrl || '',
        heroImage?.cloudinary,
        'ARTICLE_CARD'
      )

      preloadImagesData.push({ ...parsedImageData, as: 'image' })
    }
  }

  return preloadImagesData
}
