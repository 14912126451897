import React, { createContext, useReducer, useContext } from 'react'
import logger from '../../../utils/logger'

export const GtmContext = createContext()
GtmContext.displayName = 'GtmContext'

function gtmReducer(state, action) {
  switch (action.type) {
    case 'started': {
      return { ...state, isTransitioning: true }
    }
    case 'complete': {
      return { ...state, isTransitioning: false }
    }
    case 'updateCoorelator': {
      const coorelator = `${Math.random() * 1e7}`.split('.')[0]
      return { isTransitioning: false, coorelator }
    }
    default: {
      return { ...state }
    }
  }
}

function TransitionProvider({ children, value = { isTransitioning: true } }) {
  const [gtmState, dispatch] = useReducer(gtmReducer, value)
  const gtmStoreHandles = {
    ...gtmState,
    setTransitioning: (action) => {
      dispatch({ type: action })
    }
  }
  return <GtmContext.Provider value={gtmStoreHandles}>{children}</GtmContext.Provider>
}

function useTransitionState() {
  const context = useContext(GtmContext)
  if (context === undefined) {
    logger.error('useTransitionState must be used within a TransitionProvider')
    return {}
  }
  return context
}

export { TransitionProvider, useTransitionState }
