/**
 * External dependencies.
 */
import cx from 'classnames'
import { useState } from 'react'

/**
 * Internal dependencies.
 */
import ColumnFour from './column-four'
import ColumnOne from './column-one'
import ColumnTwo from './column-two'
import ColumnThree from './column-three'
import { MinusIcon, AddIcon } from '../../icons'
import styles from './footerCols.module.scss'
import { Color } from '../../../constants/colors'

/**
 * FooterCols component.
 */
const FooterCols = ({ colOne, colTwo, colThree, colFour }) => {
  const [isDirectoryOpen, setDirectoryOpen] = useState(false)

  /**
   * Handle directory menu click.
   */
  const handleDirectoryClick = () => {
    setDirectoryOpen(!isDirectoryOpen)
  }

  return (
    <div className={cx('container', styles['drive-footer-col'])}>
      {/* Left section */}
      <ColumnOne classes={cx(styles['drive-footer-col__one-wrapper'])} menuItems={colOne} />
      <button onClick={handleDirectoryClick} className={cx(styles['drive-footer-col__one-btn'])}>
        <div className='leading-6 text-white'>
          {isDirectoryOpen ? 'Hide directory links' : 'See all directory links'}
        </div>
        {isDirectoryOpen ? (
          <MinusIcon
            color={Color.coralNormal}
            transform={{ background: '#ff5e55' }}
            className={cx(styles['drive-footer-col__directory-close-icon'])}
          />
        ) : (
          <AddIcon
            height='24'
            color={Color.tealNormal}
            className={cx(styles['drive-footer-col__directory-open-icon'])}
          />
        )}
      </button>
      {/* Right section */}
      <div
        className={cx(styles['drive-footer-col__directory-wrapper'], {
          [cx(styles['drive-footer-col__directory-close'])]: !isDirectoryOpen
        })}
      >
        <ColumnTwo classes={cx(styles['drive-footer-col__two-wrapper'])} menuItems={colTwo} />
        <ColumnThree classes={cx(styles['drive-footer-col__three-wrapper'])} menuItems={colThree} />
        <ColumnFour classes={cx(styles['drive-footer-col__four-wrapper'])} menuItems={colFour} />
      </div>
    </div>
  )
}

export default FooterCols
