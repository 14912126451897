/**
 * Listen to dom changes of the element to be observed (elToObserve)
 * @param elToObserve which element to be observed and listen to
 * @param publish the publish function from PubSub
 * @param channelId the channel id for pubsub
 * @returns null | void
 */
const getChangedDomNodes = (
  elToObserve: Node | null,
  publish: (channel: string, message: Record<string, any>) => void,
  channelId: string,
  config: Props['config'] = {
    attributes: true,
    childList: true,
    subtree: true
  }
) => {
  if (typeof window === 'undefined' || !elToObserve) {
    return null
  }

  const observer = new MutationObserver((mutationList: MutationRecord[]) => {
    publish(channelId, mutationList)
  })
  observer?.observe(elToObserve, config)
}

export type MutationObserverOptions = {
  attributes: boolean
  childList: boolean
  subtree: boolean
}

export type Props = {
  config?: MutationObserverOptions
}

export default getChangedDomNodes
