import { useContext, useEffect, useMemo, useState } from 'react'
import { PageContext } from '@grille/utils/contexts/pageContext'
import { isGamTakeOverPage } from '../functions/miscellaneous'
import isEmpty from 'lodash/isEmpty'
import PubSub, { PubSubStatic } from '../class/PubSub'
import getChangedDomNodes from '../functions/getChangedDomNodes'
import detectElement from '../functions/detectElement'
import { useDynamicRoutePath } from './useDynamicRoutePath'

/**
 * @hook UsePageTakeOver
 *
 * @return {TpageTakeOver} Returns PageTakeOver .gam_oop_pos0 data
 */
export const usePageTakeOver = (options: Toptions = {}) => {
  const { includeTruSkins = false, trueSkinClassName = 'bz-viewability-container' } = options || {}
  const pubSub: PubSubStatic = useMemo(() => new PubSub().export(), [])
  const publishChannelId: string = useMemo<string>(
    () => (Math.random() * 1e7 + '').replace('.', '').toString(),
    []
  )
  const { pageStore } = useContext<{ pageStore: Record<string, any> }>(PageContext) || {}
  const router = useDynamicRoutePath()
  const [pageTakeOver, setPageTakeOver] = useState<TpageTakeOver>(false)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    if (!isEmpty(pageStore?.gam_oop_pos0) && isGamTakeOverPage(router)) {
      setPageTakeOver({ gam_oop_pos0: pageStore.gam_oop_pos0 })
    }

    if (includeTruSkins) {
      getChangedDomNodes(document.body, pubSub.publish, publishChannelId)
      pubSub.subscribe(publishChannelId, async (newNodes: MutationRecord[]) => {
        const detected = await detectElement(newNodes, trueSkinClassName)
        if (detected) {
          setPageTakeOver({ trueSkin: true })
        }
      })
    }
  }, [includeTruSkins, pageStore, pubSub, publishChannelId, router, trueSkinClassName])

  return pageTakeOver
}

type TpageTakeOver =
  | {
      gam_oop_pos0?: Record<string, any>
      trueSkin?: boolean
    }
  | boolean

type Toptions = {
  includeTruSkins?: boolean
  trueSkinClassName?: string
}
