import { AdSize } from '@grille/types/adSize'

export function determineAdSize(height: string | number) {
  if (height < 100) {
    return AdSize.small
  }

  if (height < 250) {
    return AdSize.medium
  }

  return AdSize.large
}
