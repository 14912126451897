import isEmpty from 'lodash/isEmpty'
import { NextSeo } from 'next-seo'
import PropTypes from 'prop-types'
import { isValidUrl } from '../../utils/functions/slugs'
import { isNoFollow, isNoIndex } from '../../utils/functions/robots'
import { getModifiedTime, getOgTitle, getSeoTitle } from '../../utils/seo'
import { getImageBySize } from '../../utils/functions/images'
import { DEFAULT_IMG_URL } from '../../constants/media'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'

/**
 * Custom SEO component
 *
 * Used to seo meta tags for each page
 * UseCanonical flag to load canonical directly from the graphql response without any modification
 * UseCanonical flag is currently only implemented in dcoty categories and landing page
 * UseCanonical flag should be used when there is always consitent data comes from boot and correct
 *
 * @param {Object} seo Seo.
 * @param {Boolean} useCanonical Seo.useCanonical default false
 * @param {string} customPageTitle Custom Page Title <title>.
 * @param {string} customOgTitle Custom <og:title>.
 * @param {string} uri Uri.
 * @see https://www.npmjs.com/package/next-seo
 *
 * @returns {JSX.Element}
 *
 */
const Seo = ({
  seo,
  uri,
  yoastSeo = {
    social: {
      twitter: {
        cardType: '',
        username: ''
      }
    }
  },
  customPageTitle = '',
  customSeoMeta = {},
  customPublishedTime = '',
  pageName = ''
}) => {
  const {
    title = '',
    description,
    metaDesc = '',
    metaRobotsNoindex = false,
    metaRobotsNofollow = false,
    opengraphDescription = '',
    opengraphTitle = '',
    featuredOgImage,
    opengraphImage = { sourceUrl: '' },
    opengraphSiteName = '',
    author,
    canonical = '',
    useCanonical = false,
    customMetaRobotsNoindex
  } = seo || {}

  const currentLocation =
    getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL') ??
    (typeof window !== 'undefined' ? window.location.origin : null)
  let opengraphUrl
  if (
    (canonical &&
      canonical.indexOf(currentLocation) === -1 &&
      canonical.indexOf(getRuntimeEnv('NEXT_PUBLIC_WORDPRESS_URL')) === -1 &&
      !canonical.match(/localhost|-boot/)) ||
    (canonical && useCanonical)
  ) {
    // An external canonical such as Caradvice.com
    opengraphUrl = canonical
  } else {
    // A self-canonical
    opengraphUrl = currentLocation + uri?.toLowerCase()
    opengraphUrl = isValidUrl(opengraphUrl) ? opengraphUrl : null
  }

  const seoDescription = customSeoMeta?.customMetaDesc ?? description ?? metaDesc ?? customPageTitle

  // Handle Robots.
  const noIndex = isNoIndex(customMetaRobotsNoindex ?? metaRobotsNoindex)
  const noFollow = isNoFollow(metaRobotsNofollow)

  const twitterHandle = !isEmpty(author?.node?.seo?.social?.twitter)
    ? `@${author?.node?.seo?.social?.twitter}`
    : '@drivecomau'

  const parsedOgTitle = getOgTitle(customSeoMeta?.customTitle, pageName)
  const seoTitle = getSeoTitle(customSeoMeta?.customTitle, pageName, title)
  const imageMeta = customSeoMeta?.customImageMeta

  // default fallback og image
  const defaultOgImage = getImageBySize(DEFAULT_IMG_URL, 'DEFAULT_OG_IMAGE', null, true)

  return (
    <NextSeo
      title={seoTitle}
      description={seoDescription}
      canonical={opengraphUrl}
      noindex={noIndex}
      nofollow={noFollow}
      robotsProps={{
        maxSnippet: -1,
        maxImagePreview: 'large',
        maxVideoPreview: -1
      }}
      openGraph={{
        type: seo?.opengraphType ?? 'website',
        locale: seo?.opengraphLocale ?? 'en_US',
        url: opengraphUrl,
        title: !isEmpty(parsedOgTitle) ? `${parsedOgTitle}` : opengraphTitle || 'Drive.com.au',
        description: customSeoMeta?.customMetaDesc ?? opengraphDescription,
        images: [
          {
            url: !isEmpty(imageMeta)
              ? imageMeta
              : !isEmpty(featuredOgImage)
              ? featuredOgImage
              : opengraphImage?.sourceUrl ?? defaultOgImage.src,
            width: seo?.ogImageWidth ?? 1280,
            height: seo?.ogImageHeight ?? 720
          }
        ],
        videos: !isEmpty(seo?.video?.url)
          ? [
              {
                url: seo?.video?.url ?? '',
                width: seo?.video?.width ?? '',
                height: seo?.video?.height ?? '',
                alt: seo?.video?.title ?? ''
              }
            ]
          : null,
        site_name: opengraphSiteName,
        article: {
          publishedTime: customPublishedTime ?? seo?.opengraphPublishedTime ?? '',
          modifiedTime: getModifiedTime(seo?.opengraphModifiedTime ?? '', pageName)
        }
      }}
      twitter={{
        handle: twitterHandle,
        site: !isEmpty(yoastSeo?.social?.twitter?.username)
          ? `@${yoastSeo?.social?.twitter?.username}`
          : '@drivecomau',
        cardType: yoastSeo?.social?.twitter?.cardType ?? 'summary_large_image'
      }}
    />
  )
}

Seo.propTypes = {
  seo: PropTypes.object,
  customPageTitle: PropTypes.string,
  customSeoMeta: PropTypes.object,
  customPublishedTime: PropTypes.string,
  pageName: PropTypes.string,
  yoastSeo: PropTypes.object
}

export default Seo
