import { gql } from '@apollo/client'
import {
  MenuFragment,
  FooterMenuFragment,
  HeaderAndFooterMenu,
  HamburgerMenu
} from './fragments/menus'

/**
 * Get Header Footer menus
 */
export const GET_HEADER_AND_FOOTER_MENUS = gql`
query GET_HEADER_AND_FOOTER_MENUS {
  ${HeaderAndFooterMenu}
 }
 ${MenuFragment}
 ${FooterMenuFragment}
 `

export const GET_HAMBURGER_MENU = gql`
query GET_HAMBURGER_MENU {
  ${HamburgerMenu}
 }
 ${MenuFragment}
 `
