import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import ErrorBag from '../errorBag/ErrorBag'

/**
 * Select Component.
 *
 * @TODO add more features and functionalities.
 *
 * @param {Object} { value, className, onChange, type, label, options } as props
 * option:{
 *  value
 *   taxt
 * }
 * @returns {JSX.Element}
 */
const Select = ({ options, placeholder, black, dataCy, ...props }) => {
  const [errorMessages, setErrorMessages] = useState([])
  const [localValue, setLocalValue] = useState(props.value)
  useEffect(() => {
    props.errorMessage && setErrorMessages([props.errorMessage])
  }, [props.errorMessage])

  useEffect(() => setLocalValue(props.value), [props.value])

  const handleOnchange = (event) => {
    const value = event.target.value
    props.validation && setErrorMessages(props.validation(value.toString()))
    setLocalValue(value)
    props.onChange(value)
  }

  const fieldProps = {
    ...props,
    value: localValue,
    onChange: (event) => handleOnchange(event),
    className: `select ${props.className}`
  }

  delete fieldProps.validation
  delete fieldProps.errorMessage
  delete fieldProps.labelClassName
  delete fieldProps.showError

  return (
    <label className={cx('label mt-4', { black: black }, props.labelClassName)}>
      {props.label && <span className='inner-label'>{props.label}</span>}
      <select data-cy={dataCy} {...fieldProps}>
        {placeholder ? (
          <option className='placeholder' value='' disabled>
            {placeholder}
          </option>
        ) : null}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
      <div className='select-arrow'>
        <svg
          className='w-6 h-6 fill-current'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
        >
          <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
        </svg>
      </div>
      <ErrorBag messages={errorMessages} />
    </label>
  )
}

Select.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  black: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  validation: PropTypes.func,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataCy: PropTypes.string,
  labelClassName: PropTypes.string
}

Select.defaultProps = {
  value: '',
  options: [],
  type: 'text',
  className: '',
  black: false,
  onChange: () => null,
  dataCy: '',
  labelClassName: ''
}

export default Select
