import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import Link from '..'
import Icon from '../../icon'
import styles from '../link.module.scss'

const ExternalLink = ({
  text,
  left = false,
  className = '',
  href,
  showIcon = true,
  iconName = 'LinkIcon',
  iconProps,
  showInlineIcon = false,
  isJumpLinkOrHashFragment = false,
  isExternal = true
}) => {
  if (isEmpty(text) || isEmpty(href)) {
    return null
  }

  return (
    <Link href={href} isJumpLinkOrHashFragment={isJumpLinkOrHashFragment} isExternal={isExternal}>
      <a
        className={cx(styles['drive-link__external__wrapper'], className, {
          [cx(styles['drive-link__external__float-right'])]: !left
        })}
      >
        <span className={cx(styles['drive-link__external__text'])}>
          {text}
          {showInlineIcon ? (
            <Icon
              height={20}
              name={iconName}
              className={cx(styles['drive-link__external__icon-inline'])}
              {...(iconProps ?? {})}
            />
          ) : null}
        </span>
        {showIcon ? (
          <Icon
            height={20}
            name={iconName}
            className={cx(styles['drive-link__external__icon'])}
            {...(iconProps ?? {})}
          />
        ) : null}
      </a>
    </Link>
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.string,
  left: PropTypes.bool,
  showIcon: PropTypes.bool,
  showInlineIcon: PropTypes.bool,
  iconName: PropTypes.string,
  iconProps: PropTypes.object,
  isJumpLinkOrHashFragment: PropTypes.bool,
  isExternal: PropTypes.bool
}

export const externalLinkDefaultProps = {
  className: '',
  left: false,
  showIcon: true,
  showInlineIcon: false,
  iconName: 'LinkIcon',
  isJumpLinkOrHashFragment: false,
  isExternal: true,
  showRel: false
}

export default ExternalLink
