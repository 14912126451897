export const MINIMUM_CARS_TO_SHOW = 4

export const DROPDOWN_LABEL_MAP = {
  make: 'Select Make',
  model: 'Select Model',
  variant: 'Select Variant',
  year: 'Select Year'
}

export const POPULAR_MAKES_CURRENT = [
  'AUDI',
  'BMW',
  'FORD',
  'HYUNDAI',
  'KIA',
  'MAZDA',
  'MERCEDES-BENZ',
  'MG',
  'MITSUBISHI',
  'NISSAN',
  'TOYOTA',
  'VOLKSWAGEN'
]

export const POPULAR_MAKES_ALL = [
  'AUDI',
  'BMW',
  'FORD',
  'HOLDEN',
  'HYUNDAI',
  'KIA',
  'MAZDA',
  'MERCEDES-BENZ',
  'MG',
  'MITSUBISHI',
  'NISSAN',
  'TOYOTA',
  'VOLKSWAGEN'
]
