import cx from 'classnames'
import Link from '../link'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import Image from '../image'
import CommentsCount from './comments-count'
import ThumbnailImage from '../thumbnail-image'
import styles from './compact-thumbnail.module.scss'
import hasSpecificTag from '../../../utils/functions/hasSpecificTag'
import { VideoCameraIcon } from '../../icons'

/**
 * CompactThumbnail Component.
 *
 * @param {Object}  props
 *
 * @returns {JSX.Element}
 */
const CompactThumbnail = ({
  uri,
  image,
  title,
  article,
  tags,
  border,
  authorAvatar,
  hideDate,
  isExternalLink,
  commentCount
}) => {
  if (isEmpty(uri) || isEmpty(title)) {
    return null
  }

  const hasStickyType =
    article?.stickyPosts?.stickyType && article?.stickyPosts?.stickyType !== 'none'
  const stickyType = hasStickyType ? article?.stickyPosts?.stickyType : ''
  const isArticleIncludesVideo = hasSpecificTag(article?.tags?.nodes ?? {}, 'video')

  return (
    <div
      className={cx(
        styles['drive-article-card__compact-thumbnail__container'],
        { avatar: authorAvatar },
        { 'no-border mb-5': !border }
      )}
    >
      {tags?.category && article?.categories?.nodes?.[0] ? (
        <span
          className={cx(
            'category',
            styles['drive-article-card__compact-thumbnail__category-label']
          )}
        >
          {article?.categories?.nodes?.[0].name}
        </span>
      ) : null}
      <Link href={uri} isExternal={isExternalLink}>
        <a>
          <figure
            className={cx(styles['drive-article-card__compact-thumbnail__figure'], {
              author: authorAvatar
            })}
          >
            {authorAvatar ? (
              <Image
                altText={article?.author?.node?.name ?? ''}
                postTitle={article?.author?.node?.name}
                sourceUrl={article?.author?.node?.avatar?.url ?? ''}
                className='flex rounded-full'
              />
            ) : (
              <ThumbnailImage
                postTitle={title}
                image={image}
                object={article}
                className={cx(styles['drive-article-card__compact-thumbnail__img-wrapper'])}
                sizeName='ARTICLE_CARD_THUMBNAIL'
              />
            )}
          </figure>
        </a>
      </Link>
      <div
        className={cx(styles['drive-article-card__compact-thumbnail__content'], {
          'py-0': !border,
          'pt-1 pb-2 pr-2': border
        })}
      >
        <h4
          className={cx(styles['drive-article-card__compact-thumbnail__title'], {
            'pt-1.5': border
          })}
        >
          <Link href={uri} isExternal={isExternalLink}>
            <a
              className={cx('truncate-3 text-black', {
                // 'text-14px': border,
                // 'text-base': !border
              })}
              data-cy='review-permalink'
            >
              {isArticleIncludesVideo ? (
                <VideoCameraIcon
                  height='24'
                  className={cx(styles['drive-article-card__video-camera'])}
                />
              ) : null}
              {title}
            </a>
          </Link>
        </h4>
        <div
          className={cx('flex items-end', {
            'justify-between': !hideDate || stickyType,
            'justify-end': hideDate || !stickyType
          })}
        >
          {stickyType && (
            <span className={cx(styles['drive-article-card__compact-thumbnail__sticky'])}>
              {stickyType}
            </span>
          )}
          <CommentsCount article={article} iconPosition='left' commentCount={commentCount} />
        </div>
      </div>
    </div>
  )
}

CompactThumbnail.propTypes = {
  tags: PropTypes.object,
  border: PropTypes.bool,
  image: PropTypes.object,
  article: PropTypes.object,
  authorAvatar: PropTypes.bool,
  uri: PropTypes.string,
  title: PropTypes.string,
  hideDate: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  commentCount: PropTypes.number
}

CompactThumbnail.defaultProps = {
  border: true
}

export default CompactThumbnail
