import { gql } from '@apollo/client'

export const GET_TAILPIPE_DEALS = gql`
  query GET_DEALS($where: WhereOptionsRedbookSpecialOffer, $page: Int!, $pageSize: Int!) {
    RedbookSpecialOffers(
      where: $where
      paginate: { page: $page, pageSize: $pageSize }
      sort: { order: [["expires", "DESC"]] }
    ) {
      results {
        commences
        expires
        id
        RedbookVehicles: vehicles {
          id
          uuid
          vehicle_key: vehicleKey
          make_code: makeCode
          family_code: familyCode
          make {
            uuid
            id
            slug
            description
          }
          model: family {
            familyPrice {
              id
              min
              max
            }
            uuid
            id
            slug
            redbookVehicles(where: { currentRelease: { eq: T } }) {
              uuid
              slug
              id
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
export default GET_TAILPIPE_DEALS

export const getModelDealQuery = (modelDescription) => {
  return gql`
    query GET_TAILPIPE_VARIANTS {
      deals: Families(where: {description: {eq: ${JSON.stringify(
        modelDescription
      )}}, currentRelease: {eq: T}}) {
        results {
          id
          variants: vehicles {
            vehicleKey
            vehicleableUuid
            vehicleable {
              ...on RedbookVehicle {
                vehicleKey
                uuid
                id
                nodes: specialOffers {
                  id
                  expiresAt: expires
                  commences
                }
              }
            }
            
          }
        }
      }
    }
  `
}

export const GET_TP_REVIEWS_MODEL_DEALS = gql`
  query GET_TP_REVIEWS_MODEL_DEALS($whereQuery: WhereOptionsFamily) {
    deals: Families(where: $whereQuery) {
      results {
        id
        familyableUuid
        type
        variants: vehicles(where: { currentRelease: { eq: T } }) {
          vehicleKey
          vehicleableUuid
          description
          driveDescription
          make {
            id
            slug
            makeableUuid
            description
          }
          model: family {
            id
            description
            slug
            familyableUuid
          }
          attributes {
            longDescription
            shortDescription
            gearTypeDescription
          }
          vehicleable {
            ... on RedbookVehicle {
              uuid
              id
              redbookPrice {
                id
                newPrice
              }
              RedbookSpecialOffers: specialOffers {
                title
                id
                expiresAt: expires
                states
                commences
                description
                deals: inclusions {
                  title
                  type
                  value
                  id
                  inclusion_id: specialOfferId
                  disclaimer
                }
              }
            }
          }
        }
      }
    }
  }
`
