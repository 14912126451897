import { GEO_CACHE_KEY, GEO_CACHE_PERSIST_SECONDS } from '@grille/constants/localStorage'
import { IPageContext } from '@grille/types/core'
import { getDriveCache } from '@grille/utils/class/DriveCache'
import { PageContext } from '@grille/utils/contexts/pageContext'
import isEmpty from 'lodash/isEmpty'
import { useContext } from 'react'

/**
 * @hook GeoLocation Reusable Custom Hook.
 * @reason Needed to trigger and give geoLocation data to any component that listens and uses geo location data
 * @jobs Handles geoPublish event and checks the geoCache storage via DriveCache and triggers callback that was given to this hook
 * @listens event:geoLocationDetected geoWrapper.pubSub
 * @listens localStorage:drive.geo checks geoCache data via DriveCache upon render
 * @uses reactHook the onLoaded event can be used inside or outside the react hook
 * @callback onLoaded @required Triggers when there location has been loaded by the geoWrapper either via pubSub or during render from geoCache Drive storage
 * @callback onLoaded @returns {Record<string, any> | undefined}  Returns either geoLocation data or undefined when error
 * @return {useGeoLocationRes}  Returns onLoaded function event for the component to consume
 */

export const useGeoLocation = (): useGeoLocationRes => {
  const { pageStore } = useContext<IPageContext>(PageContext)
  const { pagePubSub } = pageStore || {}

  return {
    onLoaded: (callBackFn: (data: Record<string, any> | undefined) => void) => {
      /* run this for any component that loads or rendered after geo publish event fired
       */
      const geoCache = getDriveCache(GEO_CACHE_KEY, GEO_CACHE_PERSIST_SECONDS)
      const geoData = geoCache.get() // get all geoCache including timestamp and errors

      /* if has error return undefined */
      if (geoData?.error) {
        callBackFn(undefined)
      }

      /* if no geoData, geoWrapper must be still fetching or became unsuccessful.
       * If its fetching the pubsub will trigger later once its fetched
       */
      if (!isEmpty(geoData?.[GEO_CACHE_KEY])) {
        callBackFn(geoData[GEO_CACHE_KEY])
      }

      /* run this when publish event occurs after this component renders
       */
      pagePubSub?.subscribe('geoLocationDetected', async (geoData: Record<string, any>) => {
        callBackFn(geoData)
      })
    }
  }
}

type useGeoLocationRes = {
  onLoaded: (callBackFn: (data: Record<string, any> | undefined) => void) => void
}
