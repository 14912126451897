/**
 * External dependencies.
 */
import cx from 'classnames'
import { REDBOOK_DISCLAIMER_ID } from '../../../constants/footer'
import styles from './disclaimers.module.scss'

/**
 * Component Disclaimers.
 */
const Disclaimers = ({ showFullDescription, setShowFullDescription }) => {
  const initialContent =
    "DAP Pricing– Unless otherwise stated, all prices are shown as Manufacturer's Recommended List Price (MRLP) inclusive of GST, exclusive of options and on road costs."

  return (
    <div className={cx(styles['drive-disclaimers'])}>
      <div className={cx(styles['drive-disclaimers__btn-wrapper'], 'items-top')}>
        <p
          className={cx(styles['drive-disclaimers__btn-content'], {
            [cx(styles['drive-disclaimers__btn-content-compact'])]: !showFullDescription,
            [cx(styles['drive-disclaimers__btn-content-full'])]: showFullDescription
          })}
        >
          {initialContent}
        </p>
        <button
          className={cx(styles['drive-disclaimers__btn'])}
          onClick={() => setShowFullDescription(!showFullDescription)}
        >
          {' '}
          All Disclaimers {showFullDescription ? '–' : '+'}{' '}
        </button>
      </div>
      <div>
        {showFullDescription && (
          <div id={REDBOOK_DISCLAIMER_ID}>
            <p className={cx(styles['drive-disclaimers__content'])}>
              DAP includes dealer and government costs borne by the consumer, such as stamp duty,
              dealer delivery (if it is a new vehicle) and accessories that are either factory
              fitted or dealer fitted on this particular vehicle. Other costs associated with car
              insurance etc are not included unless stated. drive.com.au takes every care in
              ensuring Drive Away Pricing throughout the site is up to date however due to the
              complexity and rate of changes with on road cost calculations we can never guarantee
              that every price is correct. Always consult a dealer to determine the exact charges
              which are applicable to you. On occasion, the DAP of a car being displayed may be over
              and above the price range you have entered. The reason for this is that all searches
              are conducted based on the base price of the vehicle which excludes any on road costs.
            </p>
            <p className={cx(styles['drive-disclaimers__content'])}>
              <br /> Data and valuations Disclaimer <br />
              Automotive Data Services Pty Ltd (RedBook) has prepared this data and valuations from
              information gathered from a variety of sources. Whilst all care is taken in producing
              the data and valuations, RedBook cannot guarantee or make any representations
              regarding the use of, or reliance, on it. RedBook is not responsible for all the
              information provided to it and you should not rely on the data or valuations without
              making your own independent assessment of the vehicle and other sources of
              information. RedBook is not liable for any loss or damages (other than in respect of
              any liability which may not lawfully be excluded) relating to your use of, or reliance
              on, this valuation and data.
            </p>
            <p className={cx(styles['drive-disclaimers__content'])}>
              Personal and Non Commercial Use Only <br />
              Data and valuations on this website are provided for your personal and non-commercial
              use only. You must not, without the written approval of the Website owner:
              <br /> &#9679; modify, copy, distribute, transmit, display, perform, reproduce,
              publish or license any data and valuations from this website;
              <br /> &#9679; use or attempt to use any data and valuations published on this website
              to create any web site or publication;
              <br /> &#9679; mirror or frame any data and valuations published within this website;
              <br /> &#9679; use any automated process of any sort to query, access or copy any data
              and valuations on this website or generate or compile any document or database based
              on the data and valuations published on this website; or
              <br /> &#9679; transfer or sell any data and valuations offered on this website
              <br /> The website owner may terminate your right to access and use this website at
              any time. <br /> <br />
            </p>
            <p className={cx(styles['drive-disclaimers__content'])}>
              <sup>*</sup>This weekly repayment estimate is provided by Stratton Finance Pty Ltd
              (Australian Credit Licence: 364340) ("Stratton"). Stratton Finance is a finance
              broker. This estimated repayment range is calculated with an interest rate range from
              6.52% p.a to 18% p.a, a 0% residual / balloon payment (also referred to as a final
              payment), a 20% assumed deposit over a term of 60 months. Other residual / balloon
              amounts are available. The vehicle price shown may not include other additional costs
              such as stamp duty, government fees and other charges payable in relation to the
              vehicle. #The comparison rate range of 6.95% to 23% p.a. is based on Stratton's
              secured loan of $30,000 over 5-year term. WARNING: This comparison rate is true only
              for the examples given and may not include all fees and charges. Different terms, fees
              or other loan amounts might result in a different comparison rate. In order to provide
              this estimate, Stratton Finance is required to make several eligibility assumptions,
              which may include the customer having had previous finance and property ownership, as
              well as the asset being used for personal use. This estimate is based on several
              assumptions outlined above and should be used for information purposes only and is not
              an offer of finance on particular terms. Credit fees, service fees and charges may
              apply. Credit to approved applicants only. A quote, details of all fees and charges
              may be obtained by contacting Stratton Finance via https://strattonfinance.com.au or
              calling 1300 STRATTON (1300 787 288). This estimated average savings represents a $
              amount of estimated savings based on the aggregate average % saving achieved per
              Manufacture (Make) during 2020. Stated savings calculation is based on the final
              purchase price compared with the RRP as published by https://redbook.com.au . Compared
              RRP prices do not include any additional costs or government fees and charges. Stated
              savings do not guarantee future savings for any make or model.
            </p>
            <p className={cx(styles['drive-disclaimers__content'])}>
              <sup>^</sup>The Drive Price Rating feature is intended to assist buyers in deciding
              whether a car's price is right for them based upon the specifications, kilometers,
              and/or condition information gathered from internal and a variety of third-party data
              sources. You are responsible for making your own assessment of the car before
              purchase. Drive makes no representations or warranties (express or implied) as to the
              accuracy, completeness, availability, quality, fitness for purpose or merchantability
              of the rating data or the underlying information and does not accept liability for
              loss or damage relating to the allocation or use of, or reliance on, any Drive Price
              Rating.
            </p>
          </div>
        )}
        <button
          className={cx(styles['drive-disclaimers__btn-mobile'])}
          onClick={() => setShowFullDescription(!showFullDescription)}
        >
          {' '}
          All Disclaimers {showFullDescription ? '–' : '+'}{' '}
        </button>
      </div>
    </div>
  )
}

export default Disclaimers
