import { REDBOOK_DISCLAIMER_ID } from '../../../constants/footer'

import cx from 'classnames'
import styles from './readbookCopyrights.module.scss'
import { FunctionComponent, useContext } from 'react'
import { PageContext } from '@grille/utils/contexts/pageContext'
import { useQuery } from '@apollo/client'
import { GET_CARS_FOR_SALE_DEALER_LISTING } from '../../../queries/cars-for-sale/listings/get-cars-for-sale-dealer-listing'
import { useRouter } from 'next/router'
import { GET_GHOST_LISTING } from '@grille/queries/cars-for-sale/listings/get-ghost-listing'
import {
  checkGhostListing,
  extractListingId
} from '@grille/components/cars-for-sale/listings/helper'

/**
 * RedbookCopyright component.
 */
const RedbookCopyright: FunctionComponent<Props> = ({ setShowFullDescription }) => {
  const { pageStore } = useContext(PageContext) ?? {}
  const router = useRouter()
  const { listingId } = router?.query ?? {}
  const pathname = router?.pathname
  const isListingPage = pathname == '/cars-for-sale/car/[listingId]'

  const listingIdValue = extractListingId(listingId)
  const isGhostListing = checkGhostListing(listingId)

  const { data: listingResponse, loading: loadingListing } = useQuery(
    isGhostListing ? GET_GHOST_LISTING : GET_CARS_FOR_SALE_DEALER_LISTING,
    {
      context: { listing: true },
      variables: { id: Number(listingIdValue) },
      skip: !isListingPage || !listingIdValue //only get listing data if on listing page and listing id is available
    }
  )

  let isListingAvailable = pageStore?.isListingAvailable
  if (!loadingListing && listingResponse?.dealerListing) {
    const listingData = listingResponse?.dealerListing
    isListingAvailable = !listingData?.isSold && !listingData?.isHidden
  }

  const handleOnClick = () => {
    if (setShowFullDescription) {
      setShowFullDescription(true)
    }
  }
  return (
    <div
      className={cx(styles['drive-redbook-copyrights'], {
        // for cfs listing page
        [styles['drive-redbook-copyrights--listing-not-available']]:
          isListingPage && !isListingAvailable
      })}
    >
      <a
        href={`#${REDBOOK_DISCLAIMER_ID}`}
        onClick={handleOnClick}
        className={cx(styles['drive-redbook-copyrights__link'])}
      >
        Data By <span className='font-bold'> Redbook &#169;</span>. All Rights Reserved.
      </a>
    </div>
  )
}

export default RedbookCopyright

type Props = {
  setShowFullDescription?: (show: boolean) => void
  classname?: string
}
