/** original code https://stackoverflow.com/a/51976805/4766925
 * @param  {Ref} target target to be observed
 * @param  {Function} returnCallback returns function with observations of target in an Object
 * @param  {Ref} options specifies options for IntersectionObserver, eg threshold, root.
 *    - current default options: thresholdArray(20)
 */
export const observeRef = (target, returnCallback, options) => {
  if (!process.browser) {
    return null
  }

  let previousY = 0
  let previousRatio = 0

  const thresholdArray = (steps) =>
    Array(steps + 1)
      .fill(0)
      .map((_, index) => index / steps || 0)

  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      const currentY = entry.boundingClientRect.y
      const currentRatio = entry.intersectionRatio
      const isIntersecting = entry.isIntersecting

      // Scrolling down/up
      if (currentY < previousY) {
        if (currentRatio > previousRatio && isIntersecting) {
          returnCallback({
            targetIntersectFromAbove: true,
            targetIntersectFromBelow: false,
            scrollIsBelowTarget: false,
            scrollIsAboveTarget: false,
            isIntersecting: isIntersecting
          })
        } else {
          // below target
          returnCallback({
            targetIntersectFromAbove: false,
            targetIntersectFromBelow: false,
            scrollIsBelowTarget: true,
            scrollIsAboveTarget: false,
            isIntersecting: isIntersecting
          })
        }
      } else if (currentY > previousY) {
        if (currentRatio === 0) {
          returnCallback({
            targetIntersectFromAbove: false,
            targetIntersectFromBelow: false,
            scrollIsBelowTarget: false,
            scrollIsAboveTarget: true,
            isIntersecting: isIntersecting
          })
        } else {
          returnCallback({
            targetIntersectFromAbove: false,
            targetIntersectFromBelow: true,
            scrollIsBelowTarget: false,
            scrollIsAboveTarget: false,
            isIntersecting: isIntersecting
          })
        }
      }

      previousY = currentY
      previousRatio = currentRatio
    })
  }

  const defaultOptions = {
    threshold: thresholdArray(20) // [0.0, 0.2, 0.4, 0.6, 0.8, 1.0]
  }
  const observer = new window.IntersectionObserver(handleIntersect, options ?? defaultOptions)

  observer.observe(target)
}
