import { useEffect, useRef, useState } from 'react'

/**
 * - returns touchStatus, only when touch ends.
 * - touch actions include drag/taps/click/double tap
 * @param element the element which touch events are observed from
 * @returns touch status in an object, or null if ref is not defined
 */
export const useTouch = (element: HTMLElement | null): TouchStatus => {
  const [status, setStatus] = useState<TouchStatus>({ touchStart: null, touchEnd: null })
  //reference of touchStart for handleTouchEnd to access the latest value.
  const touchStart = useRef<number | null>(null)

  const handleTouchStart = (e: TouchEvent) => {
    touchStart.current = e.targetTouches[0].clientX
  }

  //only send update when touch has ended
  const handleTouchEnd = (e: TouchEvent) => {
    setStatus({
      touchStart: touchStart.current,
      touchEnd: e.changedTouches[0].clientX
    })
  }

  // only add listener to element once and remove listeners when unmount
  useEffect(() => {
    if (element !== null) {
      element.addEventListener('touchstart', handleTouchStart)
      element.addEventListener('touchend', handleTouchEnd)
    }

    return () => {
      element?.removeEventListener('touchstart', handleTouchStart)
      element?.removeEventListener('touchend', handleTouchEnd)
    }
  }, [element])

  return status
}

export type TouchStatus = {
  touchStart: number | null
  touchEnd: number | null
}
