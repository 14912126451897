import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

const ErrorBag = ({ messages }) => {
  if (isEmpty(messages)) {
    return null
  }

  return (
    <span>
      {Array.isArray(messages) && messages.length > 0 ? (
        <span className='error'>
          {messages.map((message, index) => (
            <p className='m-0' key={index}>
              {message}
            </p>
          ))}
        </span>
      ) : (
        ''
      )}
    </span>
  )
}

ErrorBag.propTypes = {
  messages: PropTypes.array
}

export default ErrorBag
