import { useContext, useEffect, useState } from 'react'
import { NOTIFICATION_MAIN_NAVIGATION_STICKY_STATUS } from '@grille/constants/notification-banner'
import { PageContext } from '@grille/utils/contexts/pageContext'

const useHeaderMenuIsVisible = () => {
  const [headerMenuIsVisible, setHeaderMenuIsVisible] = useState(false)

  const { pageStore } = useContext(PageContext)

  const pagePubSub = pageStore?.pagePubSub ?? null

  useEffect(() => {
    // Update our state when the header menu is visible
    pagePubSub?.subscribe(NOTIFICATION_MAIN_NAVIGATION_STICKY_STATUS, (status: boolean) => {
      setHeaderMenuIsVisible(status)
    })
  }, [pagePubSub])

  return { headerMenuIsVisible }
}

export default useHeaderMenuIsVisible
