'use client'
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import Link from '../common/link'
import Logo from './../common/logo'
import BackToTop from './back-to-top'
import FooterCols from './footer-cols'
import Disclaimers from './disclaimers'
import PartnersLogo from './partners-logo'
import FooterBottomMenu from './bottom-menu'
import CopyRightText from './copyright'
import CompareBucketEngine from '../../utils/class/CompareBucketEngine'

import cx from 'classnames'
import styles from './footer.module.scss'
import { PageContext } from '../../utils/contexts/pageContext'
import { isTestEnv } from '../../utils/functions/isTestEnv'
const isTest = isTestEnv()

/**
 * FooterWithProps component.
 */

const FooterWithProps: FunctionComponent<TFooterWithProps> = ({
  data,
  showFullDescription,
  setShowFullDescription
}) => {
  const { pageStore, updateStore } = useContext(PageContext)

  const cbEngine = useMemo(() => CompareBucketEngine.getCurrentInstance()?.export() ?? null, [])
  const [isBucketEmpty, setIsBucketEmpty] = useState(true)

  const footerData = {
    colOne: data?.footerMenusColOne?.edges,
    colTwo: data?.footerMenusColTwo?.edges,
    colThree: data?.footerMenusColThree?.edges,
    colFour: data?.footerMenusColFour?.edges,
    bottomMenu: data?.footerMenusBottom?.edges
  }

  useEffect(() => {
    const channelId = 'footer-compare-bucket'

    if (typeof window !== 'undefined' && cbEngine) {
      const items = cbEngine.getItems()
      setIsBucketEmpty(items.length === 0)

      cbEngine?.subscribe(channelId, (newItems) => {
        setIsBucketEmpty(newItems.length === 0)
      })
    }
  }, [cbEngine])

  return (
    <footer
      className={cx(styles['drive-footer'], {
        [cx(styles['drive-footer--preserve-bucket-height'])]: !isBucketEmpty
      })}
    >
      <BackToTop />
      <div className={cx(styles['drive-footer__logo-wrapper'])}>
        <Link href='/'>
          <a>
            <Logo id='footer-sm-drive-logo' className='w-140px' />
          </a>
        </Link>
      </div>
      <FooterCols {...footerData} />
      <div className={cx(styles['drive-footer__left-section-wrapper'])}>
        <div className={cx(styles['drive-footer__left-section'])}>
          {/* Site Logo - Left Section */}
          <div className='hidden md:block'>
            <Link href='/'>
              <a>
                <Logo
                  id='footer-md-drive-logo'
                  className='w-140px'
                  data-testid={isTest ? 'drive-logo' : undefined}
                />
              </a>
            </Link>
          </div>
          {/* Partners Logo - Right Section */}
          <PartnersLogo />
        </div>
        <div className={cx(styles['drive-footer__right-section'])}>
          <CopyRightText className={cx(styles['drive-footer__copyright-desktop'])} />
          <FooterBottomMenu
            menuItems={footerData?.bottomMenu}
            classes={cx(styles['drive-footer__bottom-menu-wrapper'])}
          />
        </div>
        <CopyRightText className={cx(styles['drive-footer__copyright-mobile'])} />
        <Disclaimers
          showFullDescription={showFullDescription || pageStore?.footerDescription?.show}
          setShowFullDescription={() => {
            const show = !showFullDescription
            setShowFullDescription?.(show)
            updateStore('footerDescription', { show: show })
          }}
        />
      </div>
    </footer>
  )
}

export type TFooterWithProps = {
  showFullDescription?: boolean
  setShowFullDescription?: Dispatch<SetStateAction<boolean>>
  data: {
    [key: string]: {
      edges: Record<string, any>[]
    }
  }
}

export default FooterWithProps
