import cx from 'classnames'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import MenuLink from '../../menu-link'
import Icon from '../../../common/icon'
import MenuTitle from '../../menu-title'
import styles from '../footerCols.module.scss'

const ColumnTwo = ({ menuItems = [], classes = '' }) => {
  if (isEmpty(menuItems) && !isArray(menuItems)) {
    return null
  }

  return (
    <div className={cx('footer-column-two', classes)}>
      <MenuTitle title='Find New cars by type' />
      <ul className={cx(styles['drive-footer-col__two__container'])}>
        {menuItems.map((menuItem, index) => {
          return (
            <li
              className={cx(styles['drive-footer-col__two__item'])}
              key={`footer-col-two-${index}`}
            >
              {menuItem?.node?.cssClasses['0'] ? (
                <Icon
                  className={cx(styles['drive-footer-col__two__icon'])}
                  name={menuItem?.node?.cssClasses['0']}
                />
              ) : null}
              <MenuLink
                uri={`${menuItem?.node?.path}`}
                label={menuItem?.node?.label}
                classes={cx(styles['drive-footer-col__two__link'])}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ColumnTwo.protoTypes = {
  menuItems: PropTypes.array,
  classes: PropTypes.string
}

export default ColumnTwo
