/**
 * ArticleCard Loading Component.
 *
 * @param {String}  title    Lading title.
 *
 * @returns {JSX.Element}
 */
const ArticleLoadingCard = ({ title = 'Loading...' }) => {
  return (
    <div className='article-thumbnail animate-pulse'>
      <div className='relative'>
        <figure className='w-full bg-gray-200' style={{ height: '225px' }}>
          <span className='article-image-wrapper' />
        </figure>
        <span className='bottom-0 -mb-3 category labels'>
          <span>{title}</span>
        </span>
      </div>
      <div className='flex flex-col justify-between h-full py-3 pl-5 pr-3'>
        <div className='items-baseline'>
          <h4 className='flex-grow h-8 max-w-full mr-2 bg-gray-200 title' />
          <h4 className='flex-grow h-8 max-w-full mr-2 bg-gray-200 title' />
        </div>
        <div className='flex flex-col'>
          <div className='flex items-center justify-between text-xs text-gray-400'>
            <div className='flex'>
              <figure className='m-0 bg-gray-200 rounded-full avatar-small' />
              <span className='w-64 h-6 ml-2 bg-gray-200' />
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex items-center ml-5'>
                <span className='text-sm font-medium leading-4'>0</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleLoadingCard
