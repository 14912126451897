import { SortOption } from '@grille/types/listings'

export const sortOptions: SortOption[] = [
  {
    id: 'recommended',
    label: 'Recommended',
    title: 'Recommended',
    query: {
      order: [['recommended', 'DESC']]
    }
  },
  {
    id: 'recency',
    label: 'Recency',
    title: 'Recency',
    query: {
      order: [['createdAt', 'DESC']]
    }
  },
  {
    id: 'makeASC',
    label: 'Make (A - Z)',
    title: 'Make (A - Z)',
    query: {
      order: [['makeDescription', 'ASC']]
    },
    ghostListingQuery: {
      order: [['makeDescription', 'ASC']]
    }
  },
  {
    id: 'makeDESC',
    label: 'Make (Z - A)',
    title: 'Make (Z - A)',
    query: {
      order: [['makeDescription', 'DESC']]
    },
    ghostListingQuery: {
      order: [['makeDescription', 'DESC']]
    }
  },
  {
    id: 'priceLow',
    label: 'Price (Low - High)',
    title: 'Price',
    query: {
      order: [['overallPrice', 'ASC']]
    },
    ghostListingQuery: {
      order: [['newPrice', 'ASC']]
    }
  },
  {
    id: 'priceHigh',
    label: 'Price (High - Low)',
    title: 'Price',
    query: {
      order: [['overallPrice', 'DESC']]
    },

    ghostListingQuery: {
      order: [['newPrice', 'DESC']]
    }
  },
  {
    id: 'kmsLow',
    label: 'Kms (Low - High)',
    title: 'Kms',
    query: {
      order: [['odometer', 'ASC']]
    }
  },
  {
    id: 'kmsHigh',
    label: 'Kms (High - Low)',
    title: 'Kms',
    query: {
      order: [['odometer', 'DESC']]
    }
  },
  {
    id: 'newest',
    label: 'Year (Newest - Oldest)',
    title: 'Year',
    query: {
      order: [['year', 'DESC']]
    },

    ghostListingQuery: {
      order: [['yearGroup', 'DESC']]
    }
  },
  {
    id: 'oldest',
    label: 'Year (Oldest - Newest)',
    title: 'Year',
    query: {
      order: [['year', 'ASC']]
    },
    ghostListingQuery: {
      order: [['yearGroup', 'ASC']]
    }
  }
]
export const DEFAULT_SORT_OPTION = {
  id: 'recommended',
  label: 'Recommended',
  title: 'Recommended',
  query: {
    order: [['recommended', 'DESC']]
  }
}

export const DEFAULT_MIN_PRICE = 0
export const DEFAULT_MAX_PRICE = 2000000

export const DEFAULT_MIN_YEAR = 2000
export const DEFAULT_MAX_YEAR = new Date().getFullYear()

export const DEFAULT_MIN_KMS = 0
export const DEFAULT_MAX_KMS = 500000

export const STATE_NAME_MAP: Record<string, { name: string }> = {
  act: { name: 'Australian Capital Territory' },
  nsw: { name: 'New South Wales' },
  nt: { name: 'Northern Territory' },
  qld: { name: 'Queensland' },
  sa: { name: 'South Australia' },
  tas: { name: 'Tasmania' },
  vic: { name: 'Victoria' },
  wa: { name: 'Western Australia' }
}

export const TRANSMISSION_OPTIONS: Record<string, string> = {
  auto: 'Automatic',
  manual: 'Manual'
}

// image sizeName
export const CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL = 'CARS_FOR_SALE_LISTING_SINGLE_CAROUSEL'

export const CARS_FOR_SALE_SECTION_CARDS = 3

/** Dealers licence format according to state */
export const DEALER_STATE_LICENCE_FORMAT: Record<
  string,
  (props: { [key: string]: any }) => string
> = {
  ACT: ({ licenceNo }) => `Motor Vehicle Dealer License: ${licenceNo}`,
  NSW: ({ licenceNo, name }) => `Dealer License No. ${licenceNo}, ${name}`,
  NT: ({ licenceNo, postcode, suburb, name }) =>
    `LMVD: ${licenceNo}, ${name}, ${suburb} ${postcode}`,
  QLD: ({ licenceNo }) => `Motor Dealer License: ${licenceNo}`,
  SA: ({ licenceNo }) => `LVD: ${licenceNo}`,
  TAS: ({ licenceNo }) => `Motor Vehicle Trader License: ${licenceNo}`,
  VIC: ({ licenceNo }) => `LMCT: ${licenceNo}`,
  WA: ({ licenceNo, postcode, suburb, name }) =>
    `LVD: ${licenceNo}, ${name}, ${suburb} ${postcode}`,
  default: ({ licenceNo, name }) => `Dealer License No: ${licenceNo}, ${name}`
}

export const DEFAULT_MIN_SEAT_COUNT = 2
export const DEFAULT_MAX_SEAT_COUNT = 15
