import isEmpty from 'lodash/isEmpty'
import { useContext } from 'react'
import { PageContext } from '../../../utils/contexts/pageContext'
import { getFormattedDealerLicence } from '../../cars-for-sale/listings-single/utility'
import styles from './dealerLicence.module.scss'

const DealerLicence = () => {
  const { pageStore } = useContext(PageContext)
  const { carsForSale } = pageStore?.variant || {}
  const { dealer } = carsForSale?.dealerListing || {}

  if (isEmpty(dealer)) {
    return null
  }

  const dealerLicence = getFormattedDealerLicence(dealer)

  return (
    <div className={styles['drive-dealer-licence']}>
      <div className={styles['drive-dealer-licence__overlay-div']} />
      {dealerLicence}
    </div>
  )
}

export default DealerLicence
