/**
 * @description
 * by default, find '.' between consecutive characters and replace with '. ',
 * for the purpose of preventing links to be rendered in the email context.
 * @usage
 * prevent rendering domains or url fragments as they are clickable in consumer emails
 */
const findAndReplaceInBetween = (htmlHayStack: string, replaceWith = '. ') => {
  let re = /([^\s])([.])([^\s])/g
  let text

  while ((text = re.exec(htmlHayStack))) {
    htmlHayStack = htmlHayStack.replace(text[0], text[1] + replaceWith + text[3])
  }
  return htmlHayStack
}

export default findAndReplaceInBetween
