abstract class AbstractPubSub<MessageType> {
  protected readonly listeners: { [key: string]: (message: MessageType) => void } = {}
  protected uniqueIdSeed: number = 0

  /**
   * Generates a unique listener ID.
   *
   * Implementation:
   * - All numbers are converted to hexadecimal
   * - Current time + Incrementing Seed
   */
  protected generateListenerId(): string {
    return Date.now().toString(16) + (this.uniqueIdSeed++).toString(16)
  }

  /**
   * @returns {string} The listener ID which is used to unsubscribe.
   */
  subscribe(listener: (message: MessageType) => void): string {
    const listenerId = this.generateListenerId()
    this.listeners[listenerId] = listener
    return listenerId
  }

  unsubscribe(listenerId: string): void {
    delete this.listeners[listenerId]
  }

  publish(message: MessageType): void {
    Object.keys(this.listeners).forEach((listenerId) => this.listeners[listenerId](message))
  }
}

export default AbstractPubSub
