import SingleCarDetails from '../../../../utils/class/SingleCarDetails'
import { getMultiCarRatingData } from '../../../review/multicar-rating/functions'
import isEmpty from 'lodash/isEmpty'
import {
  CarDetaials,
  DealsData,
  DriveDeal,
  MultiCarRating,
  Review,
  VariantsDetail,
  VehicleSpecs
} from '../../../../types/singleReviews'
import find from 'lodash/find'
import getEnquiryVehicle from '../../../../utils/functions/enquiryForm/getEnquiryVehicle'

/***
 * Parse Deals Data
 */
export const parseDealsData = (driveDeals: DriveDeal[]): DealsData => {
  return driveDeals?.map((deal) => {
    if (isEmpty(deal?.variantMeta?.deals)) {
      return false
    }
    const makeLogoUrl: string = deal?.parentModel?.parentModel?.makeMeta?.makeLogoUrl ?? ''
    const modelName: string = deal?.parentModel?.parentModel?.title
    return {
      expiresAt: deal?.variantMeta?.expiresAt,
      featuredImage: deal?.featuredImage?.node,
      makeLogo: makeLogoUrl,
      modelName: modelName,
      variantName: deal?.title,
      redbookPrice: deal?.redbook?.newPrice,
      deals: deal?.variantMeta?.deals,
      hideRedbookPrice: deal?.variantMeta?.hideRedbookPrice,
      variant: [deal]
    }
  })
}

/***
 * Parse Deals Data from Tailpipe
 */
export const parseTailpipeDeals = (vehicles: { [key: string]: any }[]): DealsData => {
  const dealsList: DealsData[] = []
  vehicles?.map((vehicle) => {
    const variant = vehicle?.variant ?? {}
    const deals = variant?.deals?.variants ?? []

    if (isEmpty(deals)) {
      return false
    }

    deals?.forEach((dealData: { [key: string]: any; variant: string; vehicleKey: string }) => {
      const deal = dealData?.vehicleable?.RedbookSpecialOffers?.[0] ?? {}
      if (isEmpty(deal)) {
        return false
      }

      const makeLogo: string = vehicle?.make?.logo ?? {}
      const modelName: string = vehicle?.model?.title ?? ''

      const enquiryData = getEnquiryVehicle(dealData)

      dealsList.push({
        expiresAt: deal?.expiresAt,
        featuredImage: vehicle?.model?.featuredImage ?? {},
        makeLogo: makeLogo,
        modelName: modelName,
        variantName: dealData?.description ?? '',
        driveDescription: dealData?.driveDescription ?? '',
        states: deal?.states ?? [],
        redbookDescription: dealData?.description ?? '',
        redbookPrice: dealData?.vehicleable?.redbookPrice?.newPrice,
        deals: deal?.deals ?? [],
        hideRedbookPrice: vehicle?.variantMeta?.hideRedbookPrice, // TODO: confirm
        variant: [enquiryData ?? {}]
      })
    })
  })
  return dealsList
}

export const createVariantDescription = (vehicle: { [key: string]: any }): string => {
  const {
    badgeDescription,
    badgeSecondaryDescription,
    manufacturerBodyStyle,
    manufacturerBodyConfig
  } = vehicle ?? {}

  return `${badgeDescription ?? ''} ${badgeSecondaryDescription ?? ''} ${
    manufacturerBodyStyle ?? ''
  } ${manufacturerBodyConfig ?? ''}`
}

/**
 * @description
 * - mutable, does not perform cloning within the function
 * - updates data according to carVariantRatingsData
 * @returns {carVariantRatingsData, review, isMultiCarReview, multiCarRating }
 */
export const updateDataByVariantRatings = ({
  vehicles: attachedVehicles,
  review: reviewMeta,
  isOwnerReviewPage,
  redbook
}: UpdateDataByVariantRatingsProps): UpdateDataByVariantRatingsReturns => {
  const variant = redbook?.[0] ?? {}
  let isMultiCarReview: boolean = false
  let multiCarRating: MultiCarRating = { overallRatingData: [], ratingBreakdown: [] }
  let carDetails: CarDetaials = {}

  if (attachedVehicles?.length === 1 && !isEmpty(redbook?.[0])) {
    // Reviews with single attached vehicle
    const redbookVehicle: VehicleSpecs = redbook[0]
    const attachedVehicle = attachedVehicles[0]
    const model = attachedVehicle?.model ?? {}
    carDetails = {
      brand: redbookVehicle?.make?.description ?? redbookVehicle?.make,
      model: redbookVehicle?.model?.description ?? redbookVehicle?.model,
      name: createVariantDescription(redbookVehicle),
      variant: redbookVehicle?.attributes?.[0]?.shortDescription,
      price: redbookVehicle?.vehicleable?.redbookPrice?.newPrice ?? redbookVehicle?.newPrice,
      made: redbookVehicle?.yearGroup,
      rate: attachedVehicle?.ratings?.overall ?? null,
      isMultiCarReview,
      modelUri: model?.uri ?? null,
      modelId: model?.databaseId ?? '',
      categories: reviewMeta?.categories?.nodes,
      vehicleKey: variant?.vehicleKey ?? ''
    }

    attachedVehicles[0].variant = redbookVehicle
  } else if (attachedVehicles?.length > 1) {
    // Reviews with multiple attached vehicles
    const variantsDetails: VariantsDetail[] = attachedVehicles?.map(
      (attchedVehicle: { [key: string]: any }, i: number) => {
        const redbookVehicle =
          find(redbook, (redbookVehicle: Record<string, any>) => {
            if (!isEmpty(redbookVehicle?.uuid)) {
              // multi review with variant
              return attchedVehicle.vehicleUuid === redbookVehicle.uuid
            } else {
              // multi review without variant
              return attchedVehicle?.model?.uuid === redbookVehicle?.model?.uuid
            }
          }) ?? {}

        attachedVehicles[i].variant = { ...redbookVehicle }
        return new SingleCarDetails({
          make: redbookVehicle?.make?.description ?? '',
          model: redbookVehicle?.model?.description ?? '',
          variant: redbookVehicle?.description ?? '',
          variantDescription: createVariantDescription(redbookVehicle),
          modelUri: attchedVehicle?.model?.uri ?? '',
          id: attchedVehicle?.slug ?? ''
        }).create()
      }
    )

    isMultiCarReview = true
    carDetails = {
      title: reviewMeta?.multiCarDetails?.subHeading,
      isMultiCarReview,
      variantCount: attachedVehicles?.length ?? 0,
      variants: variantsDetails,
      categories: reviewMeta?.categories?.nodes
    }

    multiCarRating = getMultiCarRatingData(attachedVehicles)
  } else {
    // Reviews without attached vehicles
    const attachedVehicle: { [key: string]: any } = attachedVehicles?.[0] ?? {}
    const brand: string = attachedVehicle?.make?.title ?? ''
    const model: string = attachedVehicle?.model?.title ?? ''
    const made: string = attachedVehicle?.year ?? ''
    const modelShowroomUri: string =
      !isEmpty(brand) && !isEmpty(model) ? `${attachedVehicle?.model?.uri ?? ''}` : ''

    carDetails = {
      categories: reviewMeta?.categories?.nodes ?? [],
      rate: isOwnerReviewPage ? attachedVehicles?.[0]?.ratings?.overall : null,
      variantNotAttached: true,
      brand,
      model,
      made,
      variant: attachedVehicle?.variant?.attributes?.[0]?.shortDescription,
      modelId: attachedVehicle?.model?.databaseId,
      modelUri: modelShowroomUri
    }
  }

  return { carDetails, isMultiCarReview, multiCarRating }
}

export type UpdateDataByVariantRatingsProps = {
  vehicles: { [key: string]: any }[]
  review: Review
  isOwnerReviewPage: boolean
  [key: string]: any
}

export type UpdateDataByVariantRatingsReturns = {
  carDetails: CarDetaials
  isMultiCarReview: boolean
  multiCarRating: MultiCarRating
}
