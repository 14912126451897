import { SHOWROOMS_DEALS_PER_PAGE, SHOWROOMS_MODELS_PER_PAGE } from '.'
import { DcotyWinnerIcon, DealsDollarSignIcon } from '../components/icons'
import { SHOWROOMS_MODEL_SLUG } from './slug'

export const SHOWROOMS_CAR_TYPE_SLUGS_TO_EXCLUDE = ['video']
export const SHOWROOMS_FILTER_ITEMS_COUNT = 100
export const childFiltersOnModelsByMake = [
  {
    label: 'Show Deals only',
    icon: <DealsDollarSignIcon className='mr-2 text-lg fill-current' />,
    type: 'dealsOnly',
    value: true,
    selected: false
  },
  {
    label: 'Show Top-rated only',
    icon: <DcotyWinnerIcon className='mr-2 text-lg fill-current' />,
    type: 'awardTags',
    value: 'dcoty-category-winner, dcoty-overall-winner, finalist',
    selected: false
  }
]

export const filtersLabelMap = {
  make: 'Make',
  segment: 'Segment',
  carType: 'Car Type',
  'price-range': 'Price Range'
}

export const queryVariablesInitialStateModelsByCarType = {
  showroomType: SHOWROOMS_MODEL_SLUG,
  make: '', // used for makeIds
  carType: '',
  priceRange: '',
  segment: '',
  order: 'DESC',
  dealsOnly: false,
  awardTags: '',
  priceRangeOrder: '',
  first: SHOWROOMS_MODELS_PER_PAGE,
  after: ''
}

export const queryVariablesInitialStateModelsByMake = {
  showroomType: SHOWROOMS_MODEL_SLUG,
  parentMakeSlug: '',
  carType: '',
  priceRange: '',
  segment: '',
  order: 'DESC',
  dealsOnly: false,
  awardTags: '',
  priceRangeOrder: '',
  first: SHOWROOMS_MODELS_PER_PAGE,
  after: ''
}

export const queryVariablesInitialStateModelsBySegment = {
  showroomType: SHOWROOMS_MODEL_SLUG,
  segment: '',
  dealsOnly: false,
  first: SHOWROOMS_MODELS_PER_PAGE,
  after: ''
}

export const queryVariablesInitialStateModelsByDeals = {
  showroomType: SHOWROOMS_MODEL_SLUG,
  dealsOnly: true,
  segment: '',
  make: '',
  priceRange: '',
  /** Just for testing, use SHOWROOMS_DEALS_PER_PAGE */
  first: SHOWROOMS_DEALS_PER_PAGE,
  after: ''
}

/** Disabled Price filters till priceRangeFilter is fixed on wordpress.
 *   Ticket Ref : https://driveau.atlassian.net/browse/MP2-336
 */
export const sortOptions = [
  { type: 'defaultSort', value: 'DESC', text: 'Z-A' },
  { type: 'sort', value: 'ASC', text: 'A-Z' }
  /* { type: 'priceRangeOrder', value: 'ASC', text: 'Price (Ascending)' }, */
  /* { type: 'priceRangeOrder', value: 'DESC', text: 'Price (Descending)' } */
]

export const MODEL_CARS_FOR_SALE_CARDS = 3
export const CAR_TYPE_CARS_FOR_SALE_CARDS = 3

export const INITIAL_MODELS_COUNT = 8
export const SEE_MORE_MODELS_COUNT = 16
export const SEE_MORE_BTN_ID = 'see-more-btn'

export const HERO_SECTION_MAKE = 'hero-section-make'
export const HERO_SECTION_MODEL = 'hero-section-model'
export const SUB_NAVIGATION = 'sub-nav'
export const RATINGS_AND_OPINION = 'ratings-and-opinion' // container 2 components
export const REVIEW_SLIDER = 'review-slider'

export const VARIANT_SELECT = 'variant-selector' // contains sub-nav and variant-info
export const DEVIDER = 'devider'
export const VARIANT_PRICE = 'variant-price'
export const IMAGE_GALLERY = 'image-gallery'

export const VIDEO_SHOWCASE = 'video-showcase'
export const SAFETY_CONTENT = 'safety-content'
export const INLINE_ENQUIRY = 'inline-enquiry-form'
export const MARKETPLACE = 'market-place'

export const NEWS_SLIDER = 'news-slider'
export const CARADVICE_SLIDER = 'caradvice-slider'
export const OWNER_REVIEW_SLIDER = 'owner-review-slider'

export const MODEL_RANGE_SLIDER = 'model-range-slider'
export const RIVALS = 'rivals'
export const FAQ_SECTION = 'faq-section'
export const MODEL_DEALS = 'model-deals' //with see all feature
export const CARS_FOR_SALE_SECTION = 'cars-for-sale-section'
export const MRLP_DISCLAIMER = 'mrlp-desclaimer'
export const AD_STICKY = 'ad-sticky'

export const RELATED_REVIEWS = 'related-reviews'
export const BRAND_OVERVIEW = 'brand-overview'
export const MODELS_FILTER = 'models-filter'

export const TEXT_STICKY_AD = 'text-sticky-ad'
export const DISCLAIMER = 'disclaimer'
export const SHOWROOM_SHORTCUTS = 'showroom-shortcuts'

export const EXPLORE_OTHER_BRANDS = 'explore-other-brands'
export const DIMENSION_SPECS = 'dimension-specs'
export const DISCOVER_360 = 'discover-360'

export const SPONSORED_CONTENT = 'sponsored-content'
export const SPONSORED_CONTENT_MODEL = 'sponsored_content_model'

export const componentIds = {
  HERO_SECTION_MAKE,
  HERO_SECTION_MODEL,
  AD_STICKY,
  SUB_NAVIGATION,
  RATINGS_AND_OPINION,
  REVIEW_SLIDER,
  VARIANT_PRICE,
  VARIANT_SELECT,
  IMAGE_GALLERY,
  VIDEO_SHOWCASE,
  SAFETY_CONTENT,
  INLINE_ENQUIRY,
  MARKETPLACE,
  NEWS_SLIDER,
  CARADVICE_SLIDER,
  OWNER_REVIEW_SLIDER,
  MODEL_RANGE_SLIDER,
  RIVALS,
  FAQ_SECTION,
  MODEL_DEALS,
  CARS_FOR_SALE_SECTION,
  MRLP_DISCLAIMER,
  RELATED_REVIEWS,
  BRAND_OVERVIEW,
  MODELS_FILTER,
  TEXT_STICKY_AD,
  DISCLAIMER,
  SHOWROOM_SHORTCUTS,
  EXPLORE_OTHER_BRANDS,
  DIMENSION_SPECS,
  DISCOVER_360,
  SPONSORED_CONTENT,
  SPONSORED_CONTENT_MODEL
}

export const subNavLinkMapping = {
  [RATINGS_AND_OPINION]: 'Ratings',
  [INLINE_ENQUIRY]: 'Enquiry'
}
