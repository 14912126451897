import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import ErrorBag from '../errorBag/ErrorBag'
import styles from './input.module.scss'
import Icon from '../../../common/icon'
import findAndReplaceInBetween from '../../../../utils/functions/findAndReplaceInBetween/findAndReplaceInBetween'

/**
 * Input Component.
 *
 * @TODO add more features and functionalities.
 *
 * @param {Object} { value, className, onChange, type, label } as props
 * @returns {JSX.Element}
 */
const Input = ({
  type = 'text',
  value = '',
  className = '',
  iconClassName = 'text-2xl',
  onChange = () => null,
  showError = true,
  errorMessage = null,
  onBlurValidation = false,
  icon = null,
  label = null,
  onBlur = () => null,
  validation = () => null,
  labelClassName = '',
  iconProps = {},
  icononclick = () => null,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value)
  const [errorMessages, setErrorMessages] = useState([])
  const [isPasted, setIsPasted] = useState(false)

  useEffect(() => {
    setErrorMessages([errorMessage])
  }, [errorMessage])

  useEffect(() => setLocalValue(value), [value])

  const handleOnchange = (event) => {
    let value = event.target.value

    // add space when typing and when not pasting
    if (event.target.type === 'textarea' && !isPasted) {
      value = findAndReplaceInBetween(value)
    }

    if (!onBlurValidation) {
      validation && setErrorMessages(validation(value))
    }
    setLocalValue(value)
    onChange(value)

    if (isPasted) {
      setIsPasted(false)
    }
  }

  const handleOnBlur = (event) => {
    let value = event.target.value

    // add space when typing and when not pasting
    if (event.target.type === 'textarea' && !isPasted) {
      //We can’t allow domains or url fragments to be entered as they are clickable and will get rendered in consumer emails
      value = findAndReplaceInBetween(value)
    }

    if (onBlurValidation) {
      // Do field validation on blur event and set error message
      validation && setErrorMessages(validation(value))
    }

    // Call parent function
    onBlur?.(event)
  }

  // Check if error set explicitly from props
  let errors = []
  if (errorMessages?.length > 0) {
    errors = errorMessages
  }

  if (errorMessage) {
    errors = [errorMessage]
  }

  let errorClassName = 'input-field-error'
  // there are times when errors will be [null] which also indicates there are no error messages
  if (isEmpty(errors) || (errors.length === 1 && isEmpty(errors[0]))) {
    errorClassName = ''
  }
  const fieldProps = {
    ...props,
    value: localValue,
    onChange: (event) => handleOnchange(event),
    onPaste: () => setIsPasted(true),
    className: cx(
      'input',
      { [cx(styles['drive-form-input__text-area'])]: type === 'textarea' },
      className,
      errorClassName
    ),
    onBlur: (event) => handleOnBlur(event)
  }
  delete fieldProps.validation
  delete fieldProps.errorMessage
  delete fieldProps?.iconClassName
  delete fieldProps.labelClassName
  delete fieldProps.showError
  delete fieldProps.iconProps
  if (type === 'textarea') {
    delete fieldProps.type
  }
  const isErrorState = !isEmpty(errors?.[0])

  return (
    <label className={cx('label', styles['drive-form-input__wrapper'], labelClassName)}>
      {label ? (
        <span
          className={cx('inner-label', {
            error: isErrorState
          })}
        >
          {label}
        </span>
      ) : null}
      {icon ? (
        <span
          className={cx('icon', iconClassName)}
          onClick={icononclick}
          role='button'
          tabIndex='0'
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              icononclick()
            }
          }}
          onTouchStart={icononclick}
        >
          <Icon name={icon} {...(iconProps ?? {})} />
        </span>
      ) : null}
      {showError ?? null}
      {type === 'textarea' ? <textarea {...fieldProps} /> : <input {...fieldProps} />}
      {showError ? <ErrorBag messages={errors} /> : null}
    </label>
  )
}

Input.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onBlurValidation: PropTypes.bool,
  showError: PropTypes.bool,
  validation: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  iconClassName: PropTypes.string,
  iconProps: PropTypes.object,
  labelClassName: PropTypes.string,
  onKeyDown: PropTypes.func,
  icononclick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number
}

export default Input
