export class GenerateAdSpacing {
  isInreadSticky: boolean
  isMobile: boolean

  /**
   * @param isInreadSticky whether if this is a sticky ad eg.pos3 pos5
   * @param isMobile is current screen width mobile width
   */
  constructor(isInreadSticky: boolean, isMobile: boolean) {
    this.isInreadSticky = isInreadSticky
    this.isMobile = isMobile
  }

  defaultAd(options: { [key: string]: string } = {}) {
    if (this.isMobile) {
      if (this.isInreadSticky) {
        return {
          paddingTop: options?.paddingTop ?? '0.625rem', //10px
          paddingBottom: options?.paddingBottom ?? '0.625rem',
          ...options
        }
      }
      return {
        paddingTop: options?.paddingTop ?? '2.25rem', //36px
        paddingBottom: options?.paddingBottom ?? '2.25rem',
        ...options
      }
    } else {
      return {
        paddingTop: options?.paddingTop ?? '2.75rem', //44px
        paddingBottom: options?.paddingBottom ?? '2.75rem',
        ...options
      }
    }
  }

  articleAd(options: { [key: string]: string } = {}) {
    if (this.isMobile) {
      if (this.isInreadSticky) {
        return {
          paddingTop: options?.paddingTop ?? '0',
          paddingBottom: options?.paddingBottom ?? '0',
          ...options
        }
      }
      return {
        paddingTop: options?.paddingTop ?? '2.25rem', //36px
        paddingBottom: options?.paddingBottom ?? '2.25rem',
        ...options
      }
    } else {
      return {
        paddingTop: options?.paddingTop ?? '2.75rem', //44px
        paddingBottom: options?.paddingBottom ?? '2.75rem',
        ...options
      }
    }
  }

  bestCarListSingleAd(options: { [key: string]: string } = {}) {
    if (this.isMobile) {
      if (this.isInreadSticky) {
        return {
          paddingTop: options?.paddingTop ?? '0',
          paddingBottom: options?.paddingBottom ?? '0',
          ...options
        }
      }

      return {
        paddingTop: options?.paddingTop ?? '1.5rem', //24px
        paddingBottom: options?.paddingBottom ?? '1.5rem',
        ...options
      }
    } else {
      return {
        paddingTop: options?.paddingTop ?? '2rem', //32px
        paddingBottom: options?.paddingBottom ?? '2rem',
        ...options
      }
    }
  }

  roofrack() {
    if (this.isMobile) {
      return {
        paddingTop: '10px',
        paddingBottom: '10px'
      }
    }
    return {
      paddingTop: '15px',
      paddingBottom: '15px',
      width: '100%'
    }
  }

  export() {
    return {
      defaultAd: (options: { [key: string]: string } = {}) => this.defaultAd(options),
      articleAd: (options: { [key: string]: string } = {}) => this.articleAd(options),
      bestCarListSingleAd: (options: { [key: string]: string } = {}) =>
        this.bestCarListSingleAd(options),
      roofrack: () => this.roofrack()
    }
  }
}
