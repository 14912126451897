/**
 * External dependencies.
 */
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import styles from '../footerCols.module.scss'

/**
 * Internal dependencies.
 */
import MenuLink from '../../menu-link'
import MenuTitle from '../../menu-title'

/**
 * ColumnThree component.
 */
const ColumnThree = ({ menuItems = [], classes = '' }) => {
  if (isEmpty(menuItems)) {
    return null
  }

  return (
    <div className={cx('footer-column-three', classes)}>
      <MenuTitle title='Popular makes' />
      <ul className={cx(styles['drive-footer-col__three__container'])}>
        {menuItems.map((menuItem, index) => {
          return (
            <li
              className={cx(styles['drive-footer-col__three__item'])}
              key={`${menuItem?.node?.id}-${index}`}
            >
              <MenuLink uri={menuItem?.node?.path} label={menuItem?.node?.label} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ColumnThree.protoTypes = {
  menuItems: PropTypes.array,
  classes: PropTypes.string
}

export default ColumnThree
