import cx from 'classnames'
import { useEffect, useState } from 'react'
import { BackToTopIcon } from '../../icons'
import { useScrollPosition } from '../../../utils/hooks/useScrollPosition'
import useWindowWidth from '@grille/utils/hooks/use-window-width'
import { MOBILE_DEVICE_SIZES } from '@grille/constants'

import styles from './backToTop.module.scss'
import { isTestEnv } from '../../../utils/functions/isTestEnv'
import { Color } from '../../../constants/colors'
import useBlockIsLoaded from '../../../utils/hooks/useBlockIsLoaded'
import { BlockType } from '../../../constants/block-type'

const isTest = isTestEnv()

/**
 * Function to handle scroll to top.
 */
const handleBackToTopScroll = () => {
  window?.scrollTo(0, 0)
}

/**
 * BackToTop component.
 */
const BackToTop = () => {
  const { loaded: jumpLinksLoaded } = useBlockIsLoaded(BlockType.DRIVE_JUMP_LINKS)
  const [showMobileBackToPBtn, setShowMobileBackToPBtn] = useState(false)
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const { windowSize } = useWindowWidth()

  const topDistance = 400

  useEffect(() => {
    if (MOBILE_DEVICE_SIZES.includes(windowSize)) {
      setIsMobileDevice(true)
    }
  }, [windowSize])

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const scrollingUp = currPos.y < prevPos.y

      if (isMobileDevice && scrollingUp && Math.abs(currPos.y) > topDistance) {
        setShowMobileBackToPBtn(true)
      } else {
        setShowMobileBackToPBtn(false)
      }
    },
    null,
    null,
    true,
    100
  )

  const btnCssClasses = cx(styles['drive-footer__back-to-top'], {
    flex: showMobileBackToPBtn,
    hidden: !showMobileBackToPBtn
  })

  if (!isMobileDevice) {
    return null
  }

  if (jumpLinksLoaded) {
    return null
  }

  return (
    <button
      className={btnCssClasses}
      onClick={handleBackToTopScroll}
      data-testid={isTest ? 'back-to-top' : undefined}
    >
      <BackToTopIcon
        cropMode='crop'
        color={Color.white}
        width='28'
        className={cx(styles['drive-footer__back-to-top__icon'])}
      />
    </button>
  )
}

export default BackToTop
