import { BlockType } from '@grille/constants/block-type'
import { createDriveCache } from '@grille/utils/class/DriveCache'
import { LOADED_BLOCKS_CACHE_KEY } from '@grille/constants/localStorage'
import { useEffect, useRef, useState } from 'react'
import blockIsLoadedPubSub from '@grille/utils/pubSubs/blockIsLoaded'

const cache = createDriveCache(
  {},
  {
    persist: false,
    cacheKey: LOADED_BLOCKS_CACHE_KEY
  }
)

/**
 * @param {BlockType} blockType The block type in question.
 * @param {boolean} isLoaded Specifies that the block is loaded.
 */
const useBlockIsLoaded = (blockType: BlockType, isLoaded: boolean = false) => {
  // Init the loaded blocks array in the cache
  const loadedBlocks = useRef<string[]>(cache.get('loaded_blocks') ?? [])
  cache.set('loaded_blocks', loadedBlocks.current)

  const [loaded, setLoaded] = useState(isLoaded)

  // On mount...
  useEffect(() => {
    // ...check if this block is already loaded
    if (loadedBlocks.current.includes(blockType)) {
      setLoaded(true)
    }

    // ...subscribe to block loaded events
    const listenerId = blockIsLoadedPubSub.subscribe(() => {
      if (loadedBlocks.current.includes(blockType)) {
        setLoaded(true)
      }
    })

    return () => blockIsLoadedPubSub.unsubscribe(listenerId)
  }, [blockType])

  // On mount, ensure the loaded blocks array is updated with our initial "isLoaded" argument
  useEffect(() => {
    // The block was not specified as loaded
    if (!isLoaded) {
      return
    }

    // The block has been specified as loaded but has already been included in the loaded blocks array
    if (loadedBlocks.current.includes(blockType)) {
      return
    }

    // Load the block into the array and inform subscribers
    loadedBlocks.current.push(blockType)
    blockIsLoadedPubSub.publish(blockType)
  }, [blockType, isLoaded])

  return {
    loaded
  }
}

export default useBlockIsLoaded
