import isEmpty from 'lodash/isEmpty'
import { useParams, usePathname } from 'next/navigation'

/**
 * @function useDynamicRoutePath This custom hook gets the nextJs dynamic route segment path by generating based on current next params and pathname
 * @reason After the nextJs upgrade from page route to app router, nextJs default useRouter hook doesn't work with app router pages
 *         which makes it hard to get the dynamic route such as [slug][id][hub], etc and at the time on writing this code, there is no
 *         builtin function to get the dynamic route path as we get previous from useRouter().route. This function is written to generate
 *         similar output as previous useRouter().route
 * @param {string | null} pathName @required
 * @param {Object | null} params @optional
 * @return {string}  Returns generated dynamic path
 */
export const generateNextJsDynamicRoutePath = (
  pathName: string | null,
  params: Record<string, string | string[]> | null
): string | null => {
  if (typeof pathName !== 'string' || isEmpty(pathName)) {
    return null
  }

  /* convert string to array and filter out any empty value  */
  const pathParts = pathName.split('/').filter((part) => !isEmpty(part))
  const routeNames: string[] = []
  if (!isEmpty(pathParts)) {
    pathParts?.forEach((part) => {
      if (isEmpty(params)) {
        routeNames.push(part)
        return
      }
      const matchedKey = Object.keys(params).find((key) => {
        const paramValue = params[key]
        return Array.isArray(paramValue) ? paramValue.includes(part) : paramValue === part
      })
      if (matchedKey) {
        /* add dynamic route including string brackets */
        const dynamicSegment = Array.isArray(params[matchedKey])
          ? `[...${matchedKey}]`
          : `[${matchedKey}]`
        if (!routeNames.includes(dynamicSegment)) {
          routeNames.push(dynamicSegment)
        }
      } else {
        /* add the static non dynamic path as is */
        routeNames.push(part)
      }
    })
  }
  return `/${routeNames.join('/')}`
}

/**
 * @reactHook
 * @function useDynamicRoutePath This custom hook gets the nextJs dynamic route segment path by generating based on current next params and pathname
 * @reason After the nextJs upgrade from page route to app router, nextJs default useRouter hook doesn't work with app router pages
 *         which makes it hard to get the dynamic route such as [slug][id][hub], etc and at the time on writing this code, there is no
 *         builtin function to get the dynamic route path as we get previous from useRouter().route. This function is written to generate
 *         similar output as previous useRouter().route
 * @uses ReactClientComponent Can only be used or called in Client side component. It will not work in server component
 * @uses next^13.0.0 Must use NextJs version 13 and above
 * @uses generateNextJsDynamicRoutePath
 * @return {string | null}  Returns dynamic route
 */

export const useDynamicRoutePath = (): string | null => {
  const pathName = usePathname()
  const params = useParams()
  return generateNextJsDynamicRoutePath(pathName, params)
}
