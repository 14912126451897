import { getRuntimeEnv } from '../get-runtime-env'

import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

const apiUrl = getRuntimeEnv('NEXT_PUBLIC_SHARED_COUNT_API_URL')
const Host = 'www.drive.com.au'

export const getArticleUri = (article: any) => {
  if (!article) return null
  return article.uri ?? article.node?.uri
}

export const getArticleUris = (articles: any[]) => {
  const uris: string[] = []
  articles?.forEach((article) => {
    const uri = getArticleUri(article)
    uri && !isEmpty(uri) && uris.push(uri)
  })
  return uris
}

export const fetchShareCount = async (uri: string) => {
  if (!uri || isEmpty(uri) || typeof window === 'undefined') return 0
  try {
    const res = await fetch(`${apiUrl}?host=${Host}&paths=${encodeURIComponent(uri)}`)
    if (res.ok && res.status < 400) {
      const { paths } = await res.json()
      return paths[uri]?.shareCount ?? 0
    }
  } catch (error) {
    return 0
  }
}

export const fetchShareCounts = async (uris: string[]) => {
  if (!uris || !isArray(uris) || isEmpty(uris) || typeof window === 'undefined') return {}
  try {
    const res = await fetch(`${apiUrl}?host=${Host}&paths=${encodeURIComponent(uris.join(','))}`)
    if (res.ok && res.status < 400) {
      const { paths } = await res.json()
      return paths
    }
  } catch (error) {
    return {}
  }
}
