/**
 * External dependencies.
 */
import Link, { linkDefaultProps } from '../link'

import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

/**
 * Internal dependencies.
 */
import AuthorAvatar from '../author-avatar'
import styles from './article-author.module.scss'
import { FunctionComponent } from 'react'
/**
 * ReviewAuthor component.
 */
const ReviewAuthor: FunctionComponent<ReviewAuthorProps> = ({ author }) => {
  if (isEmpty(author)) {
    return null
  }

  const { name, slug } = author || {
    uri: '',
    name: '',
    slug: '',
    avatar: {
      url: ''
    }
  }
  const link = `/about-drive/our-journalists/${slug}/`

  return (
    <div className='flex'>
      <AuthorAvatar author={author} size='small' link={link} linkClassName={undefined} />
      {!isEmpty(name) && !isEmpty(slug) && (
        <Link {...linkDefaultProps} href={`/about-drive/our-journalists/${slug}/`}>
          <a className={cx(styles['drive-article-card__author-link'])}>{name}</a>
        </Link>
      )}
    </div>
  )
}

export type ReviewAuthorProps = {
  author: Author
}

export type Author = {
  uri: string
  name: string
  slug: string
  avatar: {
    url: string
  }
}

export default ReviewAuthor
