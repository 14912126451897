/**
 * External dependencies.
 */
import cx from 'classnames'
import styles from './menuLink.module.scss'
import Link from '../../common/link'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'

/**
 * MenuLink component,
 */
const MenuLink = ({ uri = '', label = '', classes = '' }) => {
  if (isEmpty(uri) || isEmpty(label)) {
    return null
  }

  return (
    <Link href={uri}>
      <a className={cx(styles['drive-footer__menu-link'], classes)}>{label}</a>
    </Link>
  )
}

MenuLink.propTypes = {
  uri: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.string
}

export default MenuLink
