/**
 * @param currentRef
 * @param compareHeight
 *  - in some cases, ellipsis is determined by height instead of width.
 *  - eg:
 *    - element in flex container
 *    - ellipsis caused by tailwind class such as 'truncate-4'  or css style '-webkit-box-orient: verticle'
 * @returns whether the element hasEllipsis
 */
export const hasEllipsis = (
  currentRef: HTMLElement | null,
  compareHeight: boolean = false
): boolean => {
  // currentRef is ref.current
  if (currentRef === null) {
    return false
  }
  let result: boolean = false
  if (compareHeight) {
    result = currentRef.offsetHeight < currentRef.scrollHeight
  } else {
    result = currentRef.offsetWidth < currentRef.scrollWidth
  }
  return result
}
