import isString from 'lodash/isString'
/**
 * Transforms for the ratings in model page
 * @param overall number to be transformed
 * @returns number or string
 */
const transformOverallRating = (overall: number): number | string => {
  let transformedOverallRating: number | string = overall
  if (transformedOverallRating < 10 && transformedOverallRating > 0) {
    transformedOverallRating = isString(transformedOverallRating)
      ? parseFloat(transformedOverallRating)
      : transformedOverallRating
    transformedOverallRating = transformedOverallRating.toFixed(1)
  }
  return transformedOverallRating
}

export default transformOverallRating
