import cx from 'classnames'
import { FunctionComponent, useState } from 'react'
import { getCurrentPageUrl } from '../../../utils/functions/slugs'

import { Color } from '../../../constants/colors'
import { CommentIcon } from '../../icons'
import Link, { linkDefaultProps } from '../link'
import styles from './comments-count.module.scss'

/**
 * @description
 * - Comments Count Component for article card.
 * - may need to be decoupled with article card for more flexibility.
 * @see https://github.com/disqus/disqus-react#commentcount
 */
const CommentsCount: FunctionComponent<Props> = ({
  article = {
    uri: '',
    id: ''
  },
  iconPosition = 'right',
  iconProps = {},
  iconSpacing = 1.5,
  showLabel = false,
  isExternal = false,
  commentCount = 0
}) => {
  const pageUrl: string = getCurrentPageUrl(article?.uri)
  const threadIdentifier: string = article?.disqus?.threadIdentifier ?? ''
  const [hoverState, setHoverState] = useState<boolean>(false)
  const isPositionedLeft: boolean = iconPosition === 'left'

  /* only send to comments section if clicked on comment link when its internal links */
  const commentHash = isExternal ? '' : '#comments'
  return (
    <Link
      {...linkDefaultProps}
      href={`${pageUrl}${commentHash}`}
      isJumpLinkOrHashFragment
      isExternal={isExternal}
    >
      <a
        className={cx(
          styles['drive-article-card__comment__wrapper'],
          commentCount > 0 ? 'text-black' : 'text-gray-400'
        )}
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
      >
        <CommentIcon
          height='24'
          className={cx({
            [`mr-${iconSpacing} order-first`]: isPositionedLeft,
            [`ml-${iconSpacing} order-last`]: !isPositionedLeft
          })}
          hoverColor={Color.blueNormal}
          hoverState={hoverState}
          {...iconProps}
          color={commentCount > 0 ? Color.black : Color.gray400}
        />
        <span
          data-disqus-threadid={threadIdentifier}
          className={cx(styles['drive-article-card__comment__count'])}
        >
          {/* handles null */}
          {commentCount}
        </span>
        {showLabel ? (
          <span className={cx(styles['drive-article-card__comment__count__label'])}>
            {/* 0 coments, 1 comment, 2 comments, parseInt is used to avoid strings from other js file */}
            {parseInt(`${commentCount}`) !== 1 ? 'comments' : 'comment'}
          </span>
        ) : null}
      </a>
    </Link>
  )
}

type Props = {
  classes?: string
  isExternal?: boolean
  className?: string
  article: {
    uri: string
    id: string
    disqus?: { [key: string]: any }
  }
  iconPosition?: 'left' | 'right'
  iconSpacing?: number
  iconProps?: object
  showLabel?: boolean
  commentCount?: number
}

export default CommentsCount
