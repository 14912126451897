/**
 * Check if the page should be set to noindex otherwise index it.
 *
 * @param yoastIndex
 * @returns {boolean}
 */
export const isNoIndex = (yoastIndex) => {
  return yoastIndex === 'noindex'
}

/**
 * Check if the page should be set to nofollow otherwise index it.
 *
 * @param yoastFollow
 * @returns {boolean}
 */
export const isNoFollow = (yoastFollow) => {
  return yoastFollow === 'nofollow'
}
