import Disqus from './disqus'
import DriveImageFragment from './image'

export const FooterMenuFragment = `
fragment FooterMenuFragment on MenuItem {
  id
  label
  url
  path
  cssClasses
  childItems {
    edges {
      node {
        id
        label
        title
        cssClasses
        url
        path
      }
    }
  }
}
`

/**
 * Query argument "first" has vavlue of 10 by default, first:100 is provided in query to retrieve more.
 * */

export const MenuFragment = `
fragment MenuFragment on MenuItem {
    id
    label
    url
    path
    cssClasses
    childItems (first:100){
      edges {
        node {
          id
          label
          title
          cssClasses
          url
          path
          childItems {
            edges {
              node {
                id
                label
                cssClasses
                url
                path
                connectedNode {
                  node {
                    ... on CarType {
                      taxonomyFields {
                        iconName
                      }
                    }
                    ... on ReviewType {
                      taxonomyFields {
                        iconName
                      }
                    }
                    ... on Review {
                      id
                      uri
                      title
                      ${Disqus}
                      singleReview {
                        reviewsMeta {
                          hero {
                            fileType
                            video
                            mediumThumbnail: gallery {
                              ...DriveImageFragment
                            }
                          }
                        }
                      }
                      stickyPosts {
                        stickyType
                      }
                      categories: reviewsFormat(first: 1) {
                        nodes {
                          name
                        }
                      }
                    }
                    ... on News {
                      id
                      uri
                      title
                      date
                      ${Disqus}
                      image: featuredImage {
                        node {
                          ...DriveImageFragment
                        }
                      }
                      stickyPosts {
                        stickyType
                      }
                      categories: newsTopics(first: 1) {
                        nodes {
                          name
                        }
                      }
                    }
                    ... on CarAdvice {
                      id
                      uri
                      title
                      ${Disqus}
                      image: featuredImage {
                        node {
                          ...DriveImageFragment
                        }
                      }
                      stickyPosts {
                        stickyType
                      }
                      categories: carAdviceSections(first: 1) {
                        nodes {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
}
${DriveImageFragment}
`

export const HeaderAndFooterMenu = `
  headerMenus: menuItems(where: {location: DRIVE_HEADER_MENU, parentDatabaseId: 0}) {
    edges {
      node {
        ...MenuFragment
      }
    }
  }
  footerMenusColOne: menuItems(where: {location: DRIVE_FOOTER_MENU_COL_ONE, parentDatabaseId: 0}, first: 100) {
    edges {
      node {
        ...FooterMenuFragment
      }
    }
  }
  footerMenusColTwo: menuItems(where: {location: DRIVE_FOOTER_MENU_COL_TWO, parentDatabaseId: 0}, first: 100) {
    edges {
      node {
        ...FooterMenuFragment
      }
    }
  }
  footerMenusColThree: menuItems(where: {location: DRIVE_FOOTER_MENU_COL_THREE, parentDatabaseId: 0}, first: 100) {
    edges {
      node {
        ...FooterMenuFragment
      }
    }
  }
  footerMenusColFour: menuItems(where: {location: DRIVE_FOOTER_MENU_COL_FOUR, parentDatabaseId: 0}, first: 100) {
    edges {
      node {
        ...FooterMenuFragment
      }
    }
  }
  footerMenusBottom: menuItems(where: {location: DRIVE_FOOTER_MENU_BOTTOM, parentDatabaseId: 0}, first: 100) {
    edges {
      node {
        ...FooterMenuFragment
      }
    }
  }
`

export const HamburgerMenu = `
  hamburgerMenus: menuItems( where: { location: DRIVE_HAMBURGER_MENU, parentDatabaseId: 0},first: 100) {
    edges {
      node {
        ...MenuFragment
      }
    }
  }
`
