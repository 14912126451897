import cx from 'classnames'
import transformOverallRating from '../../../utils/functions/showrooms/transformOverallRating'
import { FunctionComponent } from 'react'
import styles from './rate.module.scss'

/**
 * Rate Component.
 *
 * @param {String|Number} rate
 * @param {String} size ['compact', 'big']
 *
 * @returns {JSX.Element}
 */
const Rate: FunctionComponent<RateProps> = ({
  circleRating = false,
  rate = 0,
  size = null,
  outOf = false,
  className,
  textColor = 'text-blue-dark',
  outOfColor = 'text-black',
  showRateOutOf = true
}) => {
  const rating = transformOverallRating(typeof rate === 'number' ? rate : parseFloat(rate ?? '0'))
  const rateOutOf = outOf || 'bottom'
  let sizeClasses = {
    rate: cx(styles['drive-rate__default__rate']),
    outOf: cx(styles['drive-rate__default__out-of'])
  } // 32
  if (size === 'compact') {
    sizeClasses = {
      rate: cx(styles['drive-rate__compact__rate']),
      outOf: cx(styles['drive-rate__compact__out-of'])
    } // 24
  } else if (size === 'big') {
    sizeClasses = {
      rate: cx(styles['drive-rate__big__rate']),
      outOf: cx(styles['drive-rate__big__out-of'])
    } // 48xs
  }

  return rate ? (
    <h3
      className={cx(
        styles['drive-rate__container__rate'],
        { [sizeClasses.rate]: !!sizeClasses.rate },
        { [cx(styles['drive-rate__out-of'])]: rateOutOf && rateOutOf !== 'bottom' },
        { [cx(styles['drive-rate__container--circle-rating'])]: circleRating },
        textColor,
        className
      )}
    >
      {rating}
      {showRateOutOf && rateOutOf && (
        <span
          className={cx(
            styles['drive-rate__container__out-of'],
            sizeClasses.outOf,
            outOfColor,
            rateOutOf,
            rating
          )}
        >
          / 10
        </span>
      )}
    </h3>
  ) : (
    <h3 className={cx(styles['drive-rate__heading'])}>
      NA <span className={cx(styles['drive-rate__content'])}>Not yet rated</span>
    </h3>
  )
}

export const rateDefaultProps = {
  rate: 0,
  size: null,
  outOf: false,
  className: '',
  textColor: 'text-blue-dark',
  outOfColor: 'text-black',
  showRateOutOf: true,
  circleRating: false
}

type RateProps = {
  rate: number | string
  size?: string | null
  outOf: boolean | string
  className?: string
  textColor?: string
  outOfColor?: string
  showRateOutOf?: boolean
  circleRating?: boolean
}

export default Rate
