import isString from 'lodash/isString'
import { getRuntimeEnv } from './get-runtime-env'

/**
 * - get current data version from env, for example COMPARE_BUCKET_VERSION
 * - used for data version control with local storage
 * */
export const getEnvLocalStorageVersion = (key: string): string => {
  const versionValue: string | Record<string, string> = getRuntimeEnv(key) || ''
  return isString(versionValue) ? (versionValue as string) : ''
}
