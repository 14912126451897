import cx from 'classnames'

import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import CommentsCount from '../../article-card/comments-count'
import styles from './subtexts.module.scss'

const SubTexts = ({ article, showDate, showStickyType, iconProps, commentCount }) => {
  const { date, stickyPosts } = article

  const subTextsClasses =
    showDate || (showStickyType && stickyPosts?.stickyType) ? 'justify-between' : 'justify-end'
  return (
    <div className={cx('flex', subTextsClasses)}>
      {showDate && (
        <span className={cx(styles['drive-annex-card__subtext-date'])}>
          {date && format(parseISO(date), 'EEE d MMM')}
        </span>
      )}
      {showStickyType && stickyPosts?.stickyType && (
        <span className={cx(styles['drive-annex-card__subtext-sticky'])}>
          {stickyPosts?.stickyType !== 'none' ? stickyPosts?.stickyType : ''}
        </span>
      )}
      <CommentsCount
        article={article}
        iconPosition='left'
        iconProps={iconProps}
        commentCount={commentCount}
      />
    </div>
  )
}

export default SubTexts
