import { useEffect, useState } from 'react'
import { getAdPath } from '../../../utils/functions/gtm'
import logger from '../../../utils/logger'
import { IAS_PUB_ID } from '@grille/constants/ads'
import isEmpty from 'lodash/isEmpty'

const handleSlotRenderEvent = (slotId, event) => {
  const data = window.pageAdSlots[slotId]
  if (!data) {
    return
  }
  const { config, adElRefCurrent } = data
  const { size } = event
  const resize = config.isReservedHeight === true && size != null && !event.isEmpty
  const heightAttribute = config.heightAttribute ?? 'height'
  if (resize) {
    setTimeout(() => {
      const targetHeight = size[1]
      const wrapperDiv = adElRefCurrent?.closest('div.gam-ad-wrapper')
      if (adElRefCurrent) {
        adElRefCurrent.style[heightAttribute] = `${targetHeight}px`
      }
      if (wrapperDiv) {
        wrapperDiv.style[heightAttribute] = `${targetHeight}px`
      }
    })
  }
  data.setAdResponse({
    id: data.id,
    isLoaded: true,
    isEmpty: event.isEmpty,
    adElRefCurrent: adElRefCurrent,
    resize: resize,
    targetDimensions: size
  })
}

export function useAdSlot({ id, config, adPath, transition, adElRef }) {
  const [adResponse, setAdResponse] = useState({
    id: id,
    isLoaded: false,
    isEmpty: false,
    adElRefCurrent: adElRef.current,
    resize: null,
    targetDimensions: null
  })

  useEffect(() => {
    const { isTransitioning } = transition

    if (!adPath || !config || !window?.gamData || !adElRef.current) {
      return
    }

    window.pageAdSlots = window.pageAdSlots ? window.pageAdSlots : {}

    if (adElRef.current && isTransitioning === false && window.googletag) {
      const { googletag, gamData } = window
      const { dimension, targeting, sizes, isReservedHeight } = config
      const unitPath = `${adPath}${getAdPath(gamData.adTargeting ?? {})}`
      if (gamData._gamDAAT === true) {
        return
      }
      googletag.cmd.push(function () {
        logger.debug(
          `ads.useEffect display: [${unitPath}>${id}] el=[${!!adElRef.current}] resHeight=[${!!isReservedHeight}]`
        )
        let slot
        if (window.pageAdSlots[id]) {
          googletag.destroySlots([window.pageAdSlots[id].slot])
          delete window.pageAdSlots[id]
        }

        if (id.indexOf('_oop_') > -1) {
          slot = googletag.defineOutOfPageSlot(unitPath, id)
        } else {
          slot = googletag.defineSlot(unitPath, dimension, id)
          sizes?.length > 0 && slot?.defineSizeMapping(sizes)
        }

        window.pageAdSlots[id] = {
          id,
          config,
          adElRefCurrent: adElRef.current,
          slot,
          setAdResponse
        }

        if (!slot) {
          // Handle slot not created, usually becuase it already exists on the same page and pageview
          return
        }

        targeting.forEach((t) => {
          slot = slot.setTargeting(t[0], t[1])
        })

        slot.addService(googletag.pubads())
        googletag.enableServices()
        displayWithIAS(googletag, id, slot)

        const pubads = window.googletag.pubads()

        // We want a single slotRenderEnded event to fire for all slots on page
        // Otherwise we will end up adding 1 event for each slot on the page
        !window.pageAdEventListener &&
          pubads.addEventListener('slotRenderEnded', (event) => {
            if (!event || !event.slot) {
              return
            }
            handleSlotRenderEvent(event.slot.getSlotElementId(), event)
          })
        window.pageAdEventListener = true
      })
    }
  }, [id, transition, adPath, config, adElRef])

  return adResponse
}

const displayWithIAS = (googletag, id, slot) => {
  const { __iasPET } = window || {}

  if (isEmpty(__iasPET)) {
    setTimeout(() => displayWithIAS(googletag, id, slot), 50)
    return
  }
  const urlParams = new URLSearchParams(window?.location?.search ?? '')
  const isDebugging = urlParams.get('debugIAS') !== null

  __iasPET.queue = __iasPET?.queue ?? []
  __iasPET.pubId = IAS_PUB_ID

  /* used after making IAS request or in conjunction with IAS event below */
  const requestAd = () => googletag?.display(id)

  const iasRequestTimeoutId = setTimeout(requestAd, 1000)

  const iasDataHandler = () => {
    clearTimeout(iasRequestTimeoutId)
    __iasPET?.setTargetingForGPT()
    requestAd()
  }

  const sizesForIAS = slot
    ?.getSizes?.()
    ?.map((size) =>
      size?.getWidth && size?.getHeight ? [size.getWidth(), size.getHeight()] : [1, 1]
    ) ?? [1, 1]

  /* prepares the slot for IAS payload */
  const adSlot = {
    adSlotId: id,
    size: sizesForIAS,
    adUnitPath: slot?.getAdUnitPath()
  }

  // eslint-disable-next-line no-console
  isDebugging && console.info('IAS_PAYLOAD:', adSlot)

  /* fire the event and display the ad
   * adSlot property can either be any array of adSlot or just object of adSlot
   */
  __iasPET.queue.push({
    adSlots: adSlot,
    dataHandler: iasDataHandler
  })
}
