import { useQuery } from '@apollo/client'
import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { GET_HEADER_AND_FOOTER_MENUS } from '@grille/queries/get-menus'
import FooterWithProps from './footer-with-props'
import { FooterProps } from '.'
import Error from '../common/error'

export const LegacyFooter: FunctionComponent<Omit<FooterProps, 'isAppRoute'>> = (props) => {
  const { data, error, networkStatus } = useQuery(GET_HEADER_AND_FOOTER_MENUS)

  const status = {
    code: networkStatus === 8 ? 400 : false,
    message: networkStatus === 8 ? 'error' : 'OK'
  }

  if (error) {
    return <Error status={status} children={undefined} />
  }

  const { showFullDescription, setShowFullDescription, ...restProps } = props || {}

  const footerData = data || restProps

  return (
    <FooterWithProps
      data={footerData}
      showFullDescription={showFullDescription as boolean}
      setShowFullDescription={setShowFullDescription as Dispatch<SetStateAction<boolean>>}
    />
  )
}
