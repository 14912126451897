import React from 'react'
import styles from './marketplaceUspPoster.module.scss'
import cx from 'classnames'
import UspItem from '../usp-item'

const MarketplaceUSPPoster = ({ usps }: MarketplaceUSPPosterProps) => {
  return (
    <div className={cx(styles['drive-marketplace-usp-poster'])}>
      <div className={cx(styles['drive-marketplace-usp-poster__header'])}>
        Why Drive Marketplace?
      </div>
      <div className={cx(styles['drive-marketplace-usp-poster__items-container'])}>
        {usps.map((usp, index) => (
          <UspItem
            className={cx(styles['drive-marketplace-usp-poster__item'])}
            uspItemValue={usp}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}

type MarketplaceUSPPosterProps = {
  usps: string[]
  className?: string
}

export default MarketplaceUSPPoster
