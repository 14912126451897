import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Link from '../link'
import Image from '../image'
import { DEFAULT_AVATAR_URL } from '../../../constants/media'
import styles from './authorAvatar.module.scss'

const AuthorAvatar = ({
  author,
  linkClassName,
  link = '',
  size = 'xlarge',
  classes = '',
  imageSizeName = 'AUTHOR_AVATAR',
  otherImageProps = { height: 25, width: 25 }
}) => {
  if (isEmpty(author)) {
    return null
  }

  const containerClass = cx(
    styles['drive-author-avatar__container'],
    { [cx(styles['drive-author-avatar__xlarge'])]: size === 'xlarge' },
    { [cx(styles['drive-author-avatar__large'])]: size === 'large' },
    { [cx(styles['drive-author-avatar__medium'])]: size === 'medium' },
    { [cx(styles['drive-author-avatar__small'])]: size === 'small' },
    classes
  )

  let avatarUrl = author?.avatar?.url || author?.url || DEFAULT_AVATAR_URL
  avatarUrl =
    !isEmpty(avatarUrl) && !avatarUrl.includes('https')
      ? avatarUrl?.replace('http', 'https')
      : avatarUrl
  avatarUrl = avatarUrl.indexOf('gravatar') > -1 ? DEFAULT_AVATAR_URL : avatarUrl
  const getAvatar = () => (
    <figure className={containerClass}>
      <Image
        sourceUrl={avatarUrl}
        cloudinary={author?.avatar?.cloudinary}
        sizeName={imageSizeName}
        isDefault={avatarUrl.includes('/cms/theme/default-avatar') ? true : false}
        className={cx(styles['drive-author-avatar__img'])}
        altText={author?.nicename || author?.name}
        loading='lazy'
        {...otherImageProps}
      />
    </figure>
  )

  return link ? (
    <Link href={link}>
      <a className={cx(styles['drive-author-avatar__link'], linkClassName)}>{getAvatar()}</a>
    </Link>
  ) : (
    getAvatar()
  )
}

AuthorAvatar.propTypes = {
  link: PropTypes.string,
  classes: PropTypes.string,
  author: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['xlarge', 'large', 'medium', 'small', 'default']),
  imageSizeName: PropTypes.string,
  otherImageProps: PropTypes.object
}

export default AuthorAvatar
