import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import MainThumbnail from './main-thumbnail'
import CompactThumbnail from './compact-thumbnail'
import { sanitize, doesHeroImageExist } from '../../../utils/functions/miscellaneous'
import { getFormattedDate } from '../../../utils/functions/date'

/**
 * ArticleCard Component.
 *
 * @param {Object}  article    Post data.
 * @param {Boolean} compact    Compact size card.
 *
 * @returns {JSX.Element}
 */
const ArticleCard = ({
  article,
  rateOutOf = '',
  hideDate,
  small,
  commentIconProps,
  shareCountIconProps,
  showCircleRating,
  imageSizeName,
  imageAttributes = {},
  border = true,
  compact = false,
  showRate = true,
  compactTags = null,
  authorAvatar = false,
  imageLoading = 'lazy',
  heroOptions = { postType: 'singleReview', metaKey: 'reviewsMeta' },
  showArticleExcerpt = false,
  commentCount = 0,
  shareCount = 0
}) => {
  if (isEmpty(article)) {
    return null
  }

  let { categories, title, author, uri } = article || {}
  let heroMeta = null
  if (!isEmpty(article[heroOptions?.postType])) {
    if (!isEmpty(article[heroOptions.postType][heroOptions?.metaKey])) {
      heroMeta = article[heroOptions.postType][heroOptions.metaKey]?.hero
    } else {
      heroMeta = article[heroOptions.postType]
    }
  }
  const hero = doesHeroImageExist(heroMeta)
    ? heroMeta
    : article?.image?.node || article?.thumbnail?.node || article.image
  let isExternalLink = false

  // Add ad link tracking URLs if present for the article.
  if (article.clickthroughUrl) {
    article.uri = article.clickthroughUrl
    uri = article.clickthroughUrl
    isExternalLink = true
  }

  const sanitizedTitle = sanitize(title)

  let ratings = ''

  // Use ratings from attachedVehicles
  if (!isEmpty(article?.attachedVehicles?.[0]?.vehicleRating)) {
    ratings = article.attachedVehicles?.[0]?.vehicleRating
  }

  if (!isEmpty(article?.ratings)) {
    ratings = article.ratings
  }

  const date = article?.date ? getFormattedDate(article?.date, 'd MMM yyyy') : null

  return compact ? (
    <CompactThumbnail
      hideDate={hideDate}
      uri={uri}
      isExternalLink={isExternalLink}
      image={hero}
      border={border}
      article={article}
      tags={compactTags}
      title={sanitizedTitle}
      authorAvatar={authorAvatar}
      commentCount={commentCount}
    />
  ) : (
    <MainThumbnail
      imageSizeName={imageSizeName}
      small={small}
      uri={uri}
      isExternalLink={isExternalLink}
      image={{ ...hero, ...imageAttributes }}
      border={border}
      article={article}
      rateOutOf={rateOutOf}
      author={author?.node}
      title={sanitizedTitle}
      categories={categories}
      ratings={showRate && ratings}
      imageLoading={imageLoading}
      date={date}
      showArticleExcerpt={showArticleExcerpt}
      commentIconProps={commentIconProps}
      shareCountIconProps={shareCountIconProps}
      showCircleRating={showCircleRating}
      commentCount={commentCount}
      shareCount={shareCount}
    />
  )
}

ArticleCard.propTypes = {
  border: PropTypes.bool,
  compact: PropTypes.bool,
  showRate: PropTypes.bool,
  rateOutOf: PropTypes.string,
  authorAvatar: PropTypes.bool,
  compactTags: PropTypes.object,
  article: PropTypes.object.isRequired,
  imageLoading: PropTypes.oneOf(['lazy', 'eager']),
  showArticleExcerpt: PropTypes.bool,
  commentIconProps: PropTypes.object,
  shareCountIconProps: PropTypes.object,
  showCircleRating: PropTypes.bool,
  imageSizeName: PropTypes.string,
  commentCount: PropTypes.number,
  shareCount: PropTypes.number
}

export default ArticleCard
