export const SHOWROOMS_MAKE_SLUG = 'make'
export const SHOWROOMS_MODEL_SLUG = 'model'
export const SHOWROOMS_VARIANT_SLUG = 'variant'
export const SHOWROOMS_CAR_TYPE_SLUG = 'car-type'
export const SHOWROOMS_SEGMENT_SLUG = 'segment'
export const SHOWROOMS_DEALS_SLUG = 'deals'
export const SHOWROOMS_ALL_SEGMENTS_SLUG = 'all-segment'
export const SHOWROOMS_PRICE_RANGE_SLUG = 'price-range'
export const SHOWROOMS_CPT_SLUG = 'showrooms'
export const DCOTY_OVERALL_WINNER_TERM_SLUG = 'dcoty-overall-winner'
export const DCOTY_CATEGORY_WINNER_TERM_SLUG = 'dcoty-category-winner'
export const DCOTY_FINALIST_TERM_SLUG = 'finalist'
export const DCOTY_TOP_FIVE_TERM_SLUG = 'top-5'
export const TAG_CURRENT_RELEASE = 'current-release'
export const TAG_STICKY = 'sticky'

/**
 * Where clause constants.
 */

export const STICKY_TYPE_ONLY = 'sticky-only'
export const NON_STICKY = 'non-sticky'

// News
export const NEWS_ARCHIVE_FILTERS_ALLOWED_TOPICS = [
  'new-models',
  'tech-news',
  'industry-sales-results',
  'motor-shows',
  'recalls'
]
