import isEmpty from 'lodash/isEmpty'

/**
 * Detect element from the nodes from Mutation Observer
 * @param newNodes
 * @param classToFind
 * @returns Promise
 */
const detectElement = (newNodes: MutationRecord[], classToFind: string) => {
  return new Promise((resolve) => {
    let loopCompleted = false
    let classDetected = false
    let result = false

    if (!isEmpty(newNodes)) {
      outerLoop: for (let i = 0; i < newNodes.length; i++) {
        const mutation = newNodes[i]
        const node = mutation.target as HTMLDivElement
        if (node?.className?.includes(classToFind)) {
          classDetected = true
          loopCompleted = true
          break
        }

        for (let j = 0; j < mutation?.addedNodes.length; j++) {
          const div = mutation?.addedNodes[j] as HTMLDivElement
          if (div?.className?.includes(classToFind)) {
            classDetected = true
            loopCompleted = true
            break outerLoop
          }
        }
        if (i === newNodes.length) {
          loopCompleted = true
        }
      }

      if (classDetected && loopCompleted) {
        result = true
      }
    }

    resolve(result)
  })
}

export default detectElement
