import React from 'react'
import Ad from '@grille/components/common/ad-units/ad'
import AdSpacing from '@grille/components/common/ads/AdSpacing'
import styles from './sponsoredContent.module.scss'
import { usePageTakeOver } from '@grille/utils/hooks/usePageTakeOver'
import cx from 'classnames'
import useResponsive from '@grille/utils/hooks/useResponsive'

const SponsoredContent: React.FC<any> = ({
  adId = 'gam_sponsored_content_pos1',
  customStyle,
  customStyleTab,
  customStyleMobile,
  overwriteSpacing = false
}: Props) => {
  const pageTakeOver = usePageTakeOver()

  const { isMobileScreen, isTabletScreen } = useResponsive()

  const customStyles = isTabletScreen
    ? customStyleTab
    : isMobileScreen
    ? customStyleMobile
    : customStyle
  return (
    <AdSpacing
      overwriteSpacing={overwriteSpacing}
      className={cx(styles['drive-sponsored-content__ad-spacing'], {
        [styles['drive-sponsored-content__take-over']]: pageTakeOver
      })}
      customStyle={customStyles}
    >
      <Ad id={adId} onLoadHandle={undefined} />
    </AdSpacing>
  )
}

type Props = {
  adId: string
  customStyle: { [key: string]: string } | null
  customStyleTab: { [key: string]: string } | null
  customStyleMobile: { [key: string]: string } | null
  overwriteSpacing?: boolean
}

export default SponsoredContent
