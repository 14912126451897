import Link, { linkDefaultProps } from '../../common/link'
import styles from './bucketComparisonCTA.module.scss'
import cx from 'classnames'
import React, { FunctionComponent, useMemo } from 'react'
import CompareBucketEngine, {
  CompareBucketEngineStatic
} from '../../../utils/class/CompareBucketEngine'
import { useRouter } from 'next/router'

const BucketComparisonCTA: FunctionComponent<BucketComparisonCTAType> = ({ uri }) => {
  const cbEngine: CompareBucketEngineStatic | null = useMemo(
    () => CompareBucketEngine.getCurrentInstance()?.export() ?? null,
    []
  )
  const router = useRouter()

  const onClick = () => {
    if (router.route === '/compare-specs') {
      cbEngine?.clearBucket()
    }
  }

  return (
    <div className={cx(styles['bucket-comparison-cta'])} onClick={onClick}>
      <Link {...linkDefaultProps} href={uri} isJumpLinkOrHashFragment>
        <a>Compare</a>
      </Link>
    </div>
  )
}

export default BucketComparisonCTA
type BucketComparisonCTAType = {
  uri: string
}
