import Link from '../link'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import Rate from '../rate'
import ArticleAuthor from './article-author'
import CommentsCount from './comments-count'
import ThumbnailImage from '../thumbnail-image'
import ShareCount from './share-count'
import styles from './articleCard.module.scss'
import cx from 'classnames'
import hasSpecificTag from '../../../utils/functions/hasSpecificTag'
import { VideoCameraIcon, PlayIconRounded } from '../../icons'
import { hasEllipsis } from '../../../utils/functions/hasEllipsis'
import { useRef, useState } from 'react'
import { sanitize } from '../../../utils/functions/miscellaneous'

/**
 * MainThumbnail Component.
 *
 * @param {Object}  props.
 *
 * @returns {JSX.Element}
 */
const MainThumbnail = ({
  uri,
  image,
  imageSizeName = 'ARTICLE_CARD',
  title,
  author,
  article,
  ratings,
  categories,
  rateOutOf,
  isExternalLink = false,
  small,
  showArticleExcerpt = false,
  commentIconProps,
  shareCountIconProps,
  showCircleRating,
  commentCount,
  shareCount,
  isAddArticleCard = false,
  imageLoading = 'lazy',
  date = null
}) => {
  const titleRef = useRef(null)
  const [showHoverTitle, setShowHoverTitle] = useState(false)

  const onHover = () => {
    const show = hasEllipsis(titleRef.current, true)
    if (show !== showHoverTitle) {
      setShowHoverTitle(show)
    }
  }
  const overallRating =
    !isEmpty(ratings) && parseInt(ratings?.overall) > 0 ? ratings?.overall : null
  let stickyType = article?.stickyPosts?.stickyType
  stickyType = stickyType !== 'none' && stickyType !== 'None' ? stickyType : null
  const isArticleIncludesVideo = hasSpecificTag(article?.tags?.nodes ?? {}, 'video')

  const categoryTag = article?.postTypeLabel ?? article.postType
  // TODO: Refactor 'article-thumbnail class for homepage layout'

  const categoryName = categories?.nodes[0]?.name ?? ''
  const articlePublishedDate = date ?? ''
  const articleType = article.reviewsFormat?.nodes[0]?.name ?? ''

  const Tag = ({ categoryType, date }) => (
    <span className={cx(styles['drive-article-card__blackLabel-tag'])}>
      <span className='tag-label'>{categoryType}</span> | <span className='date'>{date}</span>
    </span>
  )

  return (
    <div className={cx('article-thumbnail', styles['drive-article-card__article-thumbnail'])}>
      <div className='relative'>
        {stickyType ? (
          <span className={cx(styles['drive-article-card__thumbnail__sticky'])}>{stickyType}</span>
        ) : null}
        <Link href={uri} isExternal={isExternalLink}>
          <a data-cy='review-permalink'>
            <figure className={cx('figure', styles['drive-article-card__figure'])}>
              <ThumbnailImage
                postTitle={title}
                image={image}
                object={article}
                className={cx(styles['drive-article-card__thumbnail__img-wrapper'])}
                sizeName={imageSizeName}
                imageLoading={imageLoading}
              />
              {isArticleIncludesVideo && (
                <span className={cx(styles['drive-article-card__thumbnail__play-btn-wrapper'])}>
                  <PlayIconRounded
                    height='50'
                    className={cx(styles['drive-article-card__thumbnail__play-icon'])}
                    color='#fff'
                  />
                </span>
              )}
            </figure>
          </a>
        </Link>
        {categories?.nodes?.length && categories?.nodes[0]?.name !== 'Uncategorised' ? (
          <span className={cx(styles['drive-article-card__thumbnail-category'])}>
            {date ? (
              <Tag categoryType={categoryName} date={articlePublishedDate} />
            ) : (
              categories?.nodes[0]?.name
            )}
          </span>
        ) : article.contentType?.node?.name === 'reviews' &&
          article.reviewsFormat?.nodes?.length &&
          article.reviewsFormat?.nodes[0]?.name !== 'Uncategorised' ? (
          <span className={cx(styles['drive-article-card__thumbnail-category'])}>
            {date ? (
              <Tag categoryType={articleType} date={articlePublishedDate} />
            ) : (
              article.reviewsFormat?.nodes[0]?.name
            )}
          </span>
        ) : article.postTypeLabel || article.postType ? ( // adding backward compatibility Just in case
          <span className={cx('labels', styles['drive-article-card__thumbnail-category'])}>
            {date ? <Tag categoryType={categoryTag} date={articlePublishedDate} /> : categoryTag}
          </span>
        ) : isAddArticleCard ? null : date ? (
          <span className={cx('labels', styles['drive-article-card__thumbnail-category'])}>
            <span>{date}</span>
          </span>
        ) : null}
        {overallRating && showCircleRating && (
          <div className={cx(styles['drive-article-card__thumbnail__circular-rating'])}>
            <Rate
              textColor={'text-white'}
              showRateOutOf={false}
              outOf={rateOutOf}
              rate={overallRating}
              circleRating={showCircleRating}
            />
          </div>
        )}
      </div>
      <div className={cx(styles['drive-article-card__thumbnail__content'])}>
        <div className='flex items-baseline'>
          <h3
            onMouseOver={onHover}
            ref={titleRef}
            className={cx('title', 'truncate-3', styles['drive-article-card__thumbnail__title'])}
            title={showHoverTitle ? title : ''}
          >
            <Link href={uri} isExternal={isExternalLink}>
              <a
                className={cx(styles['drive-article-card__thumbnail__link'], {
                  [cx(styles['drive-article-card__thumbnail__link-short'])]: small,
                  [cx(styles['drive-article-card__thumbnail__link-long'])]: !small
                })}
                data-cy='review-permalink'
              >
                {isArticleIncludesVideo ? (
                  <VideoCameraIcon
                    height='24'
                    className={cx(styles['drive-article-card__video-camera'])}
                  />
                ) : null}
                {title}
              </a>
            </Link>
          </h3>
          {overallRating && !showCircleRating && <Rate outOf={rateOutOf} rate={overallRating} />}
        </div>

        {showArticleExcerpt && !isEmpty(article?.excerpt) && (
          <div
            className={cx(styles['drive-article-card__thumbnail__description'])}
            dangerouslySetInnerHTML={{ __html: sanitize(article?.excerpt) }}
          />
        )}

        <div className='flex flex-col'>
          <div className={cx(styles['drive-article-card__thumbnail__comments'])}>
            <ArticleAuthor author={author} />
            {/*
             * Requirent -
             * 1 - On client ad served Related Content cards - do not show comments and share icon
             * 2 - However, if a Drive article is ad served into these slots eg a Review,
             *     then we should retain the author profile, as well as the comments and share icons
             */}
            {isAddArticleCard && !author ? null : (
              <div className='flex items-center justify-between'>
                <CommentsCount
                  article={article}
                  iconPosition='left'
                  iconProps={{ width: '16', height: '16', ...commentIconProps }}
                  isExternal={isExternalLink}
                  commentCount={commentCount}
                />
                <ShareCount
                  shareCount={shareCount}
                  iconProps={{ width: '20', height: '20', ...shareCountIconProps }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

MainThumbnail.propTypes = {
  image: PropTypes.object,
  author: PropTypes.object,
  article: PropTypes.object,
  rateOutOf: PropTypes.string,
  uri: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isAddArticleCard: PropTypes.bool,
  date: PropTypes.string,
  imageLoading: PropTypes.oneOf(['lazy', 'eager']),
  small: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  border: PropTypes.bool,
  categories: PropTypes.object,
  ratings: PropTypes.any,
  commentIconProps: PropTypes.object,
  shareCountIconProps: PropTypes.object,
  showCircleRating: PropTypes.bool,
  commentCount: PropTypes.number,
  shareCount: PropTypes.number
}

export default MainThumbnail
