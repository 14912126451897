/**
 * External dependencies.
 */
import cx from 'classnames'
import styles from './bottomMenu.module.scss'
import Link from '../../common/link'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'

/**
 * FooterBottomMenu component.
 */
const FooterBottomMenu = ({ menuItems = [], classes = '' }) => {
  if (isEmpty(menuItems)) {
    return null
  }

  return (
    <div className={cx('footer-bottom-menu', classes)}>
      <ul className={cx(styles['drive-footer__bottom-menu__container'])}>
        {menuItems.map((menuItem, index) => {
          return (
            <li
              className={cx(styles['drive-footer__bottom-menu__item'])}
              key={`${menuItem?.node?.id}-${index}`}
            >
              <Link href={menuItem?.node?.path}>
                <a className={cx(styles['drive-footer__bottom-menu__link'])}>
                  {menuItem?.node?.label}
                </a>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

FooterBottomMenu.propTypes = {
  menuItems: PropTypes.array,
  classes: PropTypes.string
}

export default FooterBottomMenu
