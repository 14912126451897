import cx from 'classnames'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useContext, useMemo, useState, useEffect } from 'react'
import ExternalLink from '../link/external'
import Link from '../../common/link'
import ArticleAnnexCard from './article-annex-card'
import sliceData from '../../../utils/functions/slice-data'
import { PageContext } from '../../../utils/contexts/pageContext'
import styles from './articleAnnexCards.module.scss'
import { Color } from '../../../constants/colors'
import {
  fetchCommentCounts,
  getThreadIdentifier,
  getThreadIdentifiers
} from '../../../utils/functions/social-counts/comments'

const ArticleAnnexCards = ({ articles, ...props }) => {
  const {
    headingElement,
    heading,
    limit,
    thumbnail,
    subTexts,
    headingLink,
    headingLinkLabel,
    thumbnailClassName = '',
    linkUrl = null,
    bottomLinkLabel = null,
    topLinkLabel = null,
    carListBanner,
    imageAttributes,
    layoutType,
    sizeName,
    loading,
    headingClass = cx(styles['drive-annex-cards__heading']),
    className = '',
    showDate = true,
    showStickyType = false,
    showCategory = false,
    onHomePage = false,
    isCarsForSaleDefaultImage = false,
    usePageStoreAnnexPos1Size = false,
    isExternalLink = true
  } = props

  const { pageStore } = useContext(PageContext)

  const newArticles = useMemo(() => {
    if (pageStore?.homepageAnnexPos1Size && usePageStoreAnnexPos1Size) {
      const limitHomepageTechNews =
        pageStore?.homepageAnnexPos1Size > 0 && pageStore?.homepageAnnexPos1Size < 600 ? 4 : 2
      return limitHomepageTechNews ? sliceData(articles, limitHomepageTechNews) : articles
    }
    return limit ? sliceData(articles, limit) : articles
  }, [articles, limit, pageStore?.homepageAnnexPos1Size, usePageStoreAnnexPos1Size])

  const containerClass = onHomePage
    ? cx(styles['drive-annex-cards__home-page-container'])
    : cx(styles['drive-annex-cards__container'])

  const [commentCounts, setCommentCounts] = useState({})
  const threadIdentifiers = useMemo(() => {
    return getThreadIdentifiers(articles)
  }, [articles])

  useEffect(() => {
    const fetchAndShow = async () => {
      const threads = await fetchCommentCounts(threadIdentifiers)
      setCommentCounts(threads)
    }
    threadIdentifiers?.length > 0 && fetchAndShow()
  }, [threadIdentifiers])

  if (isEmpty(articles) || !Array.isArray(articles)) {
    return null
  }

  const hasItem = newArticles.some((item) => {
    const article = typeof item?.node !== 'undefined' ? item.node : item
    return article?.uri ?? false
  })

  if (!hasItem) {
    return null
  }

  return (
    <div className={cx(containerClass, className, layoutType)}>
      <div
        className={cx(styles['drive-annex-cards__heading-wrapper'], {
          'hp-no-carlist': isEmpty(carListBanner)
        })}
      >
        {linkUrl ? (
          <Link href={linkUrl}>
            <a>
              <h2 className={cx(headingClass)}>{topLinkLabel}</h2>
            </a>
          </Link>
        ) : null}
        {heading && <h3 className={cx(headingClass)}>{heading}</h3>}
        {headingLink && headingLinkLabel ? (
          isExternalLink ? (
            <ExternalLink
              href={headingLink}
              text={headingLinkLabel}
              className={cx(styles['drive-annex-cards__heading-link'])}
            />
          ) : (
            <Link href={headingLink}>
              <a className={cx(styles['drive-annex-cards__heading-link'])}>{headingLinkLabel}</a>
            </Link>
          )
        ) : null}
      </div>
      <div className='articles-wrapper'>
        {newArticles.map((item, index) => {
          const article = typeof item?.node !== 'undefined' ? item.node : item

          const threadIdentifier = getThreadIdentifier(article)
          const commentCount = threadIdentifier
            ? commentCounts?.[threadIdentifier]?.count ?? null
            : null

          return (
            <ArticleAnnexCard
              imageAttributes={imageAttributes}
              loading={!isEmpty(loading) ? loading : index < 4 ? 'eager' : 'lazy'}
              headingElement={headingElement}
              key={article?.id ?? index}
              article={article}
              thumbnail={thumbnail}
              subTexts={subTexts}
              onHomePage={onHomePage}
              showDate={showDate}
              showStickyType={showStickyType}
              showCategory={showCategory}
              isCarsForSaleDefaultImage={isCarsForSaleDefaultImage}
              thumbnailClassName={thumbnailClassName}
              iconProps={{ color: onHomePage ? Color.black : null }}
              commentCount={commentCount}
              sizeName={
                sizeName ? sizeName : layoutType === 'col-3' ? 'ARTICLE_THREE_COL_LAYOUT' : null
              }
            />
          )
        })}
      </div>
      <Link href={linkUrl}>
        <a className={styles['drive-annex-cards__bottom-link']}>{bottomLinkLabel}</a>
      </Link>
    </div>
  )
}

ArticleAnnexCards.propTypes = {
  heading: PropTypes.string,
  headingClass: PropTypes.string,
  articles: PropTypes.array,
  limit: PropTypes.number,
  className: PropTypes.string,
  showDate: PropTypes.bool,
  usePageStoreAnnexPos1Size: PropTypes.bool,
  showStickyType: PropTypes.bool,
  showCategory: PropTypes.bool,
  onHomePage: PropTypes.bool,
  thumbnail: PropTypes.func,
  subTexts: PropTypes.func,
  isCarsForSaleDefaultImage: PropTypes.bool,
  headingLink: PropTypes.string,
  headingLinkLabel: PropTypes.string,
  isExternalLink: PropTypes.bool
}

export default ArticleAnnexCards
