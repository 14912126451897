/**
 * Internal dependencies.
 */
import Link from '../../common/link'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import cx from 'classnames'
import { PARTNERS_LOGOS } from '../../../constants/footer'
import Icon from '../../common/icon'
import styles from './partnersLogo.module.scss'
import { isTestEnv } from '../../../utils/functions/isTestEnv'
const isTest = isTestEnv()

/**
 * PartnerLogos component.
 */
const PartnersLogo = () => {
  if (isEmpty(PARTNERS_LOGOS) || !isArray(PARTNERS_LOGOS)) {
    return null
  }

  return (
    <div>
      <div className={cx('drive-footer-logo', styles['drive-footer__partners-logo'])}>
        {PARTNERS_LOGOS.map((logo, index) => {
          if (!logo?.logoLink) {
            return null
          }

          return (
            <div
              className={cx(
                styles['drive-footer__partners-logo__wrapper'],
                styles[logo?.className ?? ''],
                {
                  'md:pr-0': index === 2 || index === 5
                }
              )}
              key={`footer-partner-logo-${index}`}
            >
              <Link href={logo?.logoLink ?? ''}>
                <a className={cx(styles['drive-footer__partners-logo__link'])}>
                  <Icon
                    width='auto'
                    height='auto'
                    data-testid={isTest ? logo?.logoName : undefined}
                    name={logo?.logoName ?? ''}
                    className={cx(styles['drive-footer__partners-logo__icon'], 'white')}
                    {...(logo.iconProps ?? {})}
                  />
                </a>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PartnersLogo
