import { Dispatch, SetStateAction, useState } from 'react'
import Form from '../common/form'
import { SUBSCRIBE_TO_NEWSLETTER } from '../../constants/Forms'
import SubscribeSuccessMessage from './subscirbe-success-message'
import { EmailSubscriptionRequest, subscribeToNewsletter } from './helper'
import FollowIcons from '../common/follow-icons'
import styles from './subscribe.module.scss'
import cx from 'classnames'
import { Color } from '../../constants/colors'
import { FOLLOW_ICON_URLS } from '@grille/constants/follow-icon-urls'

const {
  NEXT_PUBLIC_YOUTUBE_URL: YOUTUBE_URL,
  NEXT_PUBLIC_FACEBOOK_URL: FACEBOOK_URL,
  NEXT_PUBLIC_TWITTER_URL: TWITTER_URL,
  NEXT_PUBLIC_INSTAGRAM_URL: INSTAGRAM_URL,
  NEXT_PUBLIC_LINKEDIN_URL: LINKEDIN_URL,
  NEXT_PUBLIC_SPOTIFY_URL: SPOTIFY_URL
} = FOLLOW_ICON_URLS

const SubscribeToNewsletterContent = (props: SubscribeToNewsletterContentProps) => {
  const [isSuccess, setSuccess] = useState<boolean | null>(null)
  const [isHideForm, setIsHideForm] = useState(false)

  const closeSubscribeForm = () => {
    setTimeout(function () {
      setIsHideForm(true)
      if (props?.setIsShowSubscribeButton) {
        props.setIsShowSubscribeButton(true)
      }
    }, 5000)
  }
  const handleFormSubmit = async (formData: any) => {
    // Generate API request body
    let data: EmailSubscriptionRequest = {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      marketingOptInFirstParty: formData.checkboxes.some(
        (checkbox: boolean | null) => checkbox !== null
      )
        ? true
        : null,
      marketingOptInThirdParty: formData.checkboxes.includes('updatesAndPromotions') ? true : null,
      commsOptin: {
        E2: formData.checkboxes.includes('offerNewAndUsed') ? true : null
      }
    }

    try {
      const response = await subscribeToNewsletter(data)
      if (response.success) {
        closeSubscribeForm()
        setSuccess(true)
      } else {
        setSuccess(false)
      }
    } catch (error) {
      setSuccess(false)
    }
  }

  const onFormChangeHandle = (
    event: any,
    setErrors: Dispatch<SetStateAction<string[]>>,
    setErrorMessages: Dispatch<SetStateAction<Record<string, any>>>,
    setAllowSubmit: Dispatch<SetStateAction<boolean>>
  ) => {
    if (!event.target) {
      const checkboxes = ['newsAndReviews', 'offerNewAndUsed', 'updatesAndPromotions']
      const valid = checkboxes.some((checkbox) => event[checkbox] === true)
      setErrorMessages((prev = {}) => {
        if (valid) {
          delete prev?.checkboxes
          return prev
        }
        return { ...prev, checkboxes: 'you must check at least on of the checkboxes' }
      })
      setErrors((prev: string[] = []) => {
        const newErrors = valid
          ? prev?.filter((value) => !checkboxes.includes(value))
          : [...prev, 'newsAndReviews', 'offerNewAndUsed', 'updatesAndPromotions']
        return newErrors
      })
      setAllowSubmit(valid)
    }
  }

  return (
    <div id='drive-subscribe-newsletter'>
      {isHideForm ? null : (
        <div
          className={cx(styles['drive-subscribe-content__wrapper'], props?.className)}
          style={{ height: `${props?.height ? props.height : 'auto'}` }}
        >
          <div className={cx(styles['drive-subscribe-content__container'])}>
            {isSuccess ? (
              <SubscribeSuccessMessage />
            ) : (
              <div className={cx(styles['drive-subscribe-content__form'])}>
                <div className={cx(styles['drive-subscribe-content__form-wrapper'])}>
                  <div className={cx(styles['drive-subscribe-content__form-container'])}>
                    {isSuccess === false ? (
                      <div className={cx(styles['drive-subscribe-content__form-heading'])}>
                        Oh no! Something's gone wrong.
                        <div className={cx(styles['drive-subscribe-content__form-msg'])}>
                          Please try again.
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={cx(styles['drive-subscribe-content__form-heading'])}>
                          Subscribe to Drive
                        </div>
                        <div className={cx(styles['drive-subscribe-content__form-msg-content'])}>
                          Latest news, reviews and offers.
                        </div>
                      </>
                    )}
                    <div className={cx(styles['drive-subscribe-content__form-follow'])}>
                      <FollowIcons
                        iconColor={Color.tealDark}
                        bgClass={cx(styles['drive-subscribe-content__form-follow-bg'])}
                        iconClassName={cx(styles['drive-subscribe-content__form-follow-icon'])}
                        size={2}
                        twitterUrl={TWITTER_URL}
                        facebookUrl={FACEBOOK_URL}
                        instagramUrl={INSTAGRAM_URL}
                        youtubeUrl={YOUTUBE_URL}
                        linkedinUrl={LINKEDIN_URL}
                        spotifyUrl={SPOTIFY_URL}
                      />
                    </div>
                  </div>
                </div>
                <div className={cx(styles['drive-subscribe-content__newsletter-wrapper'])}>
                  <div className={cx(styles['drive-subscribe-content__newsletter-container'])}>
                    <Form
                      formClasses={cx(styles['drive-subscribe-content__newsletter-form'])}
                      fieldsClasses={cx(styles['drive-subscribe-content__newsletter-form-fields'])} //TODO: Move to module
                      buttonsClasses={cx(styles['drive-subscribe-content__newsletter-btn'])} //TODO: Move to module
                      formData={SUBSCRIBE_TO_NEWSLETTER}
                      onSubmit={handleFormSubmit}
                      initialData={{
                        checkboxes: ['newsAndReviews', 'offerNewAndUsed']
                      }}
                      hideCheckBoxErrors
                      onFormChange={onFormChangeHandle}
                      childrenBeforeSubmitButton={
                        <div
                          className={cx(
                            styles['drive-subscribe-content__enquiry-agree-label'],
                            'text-white form_enquiry-agree-label'
                          )}
                        >
                          By clicking the Subscribe button you acknowledge that you have read and
                          agree to the Drive{' '}
                          <a
                            className='text-white underline'
                            target='_blank'
                            href='/about-drive/terms-conditions/'
                          >
                            Terms and Conditions{' '}
                          </a>
                          and{' '}
                          <a
                            className='text-white underline'
                            target='_blank'
                            href='/about-drive/privacy-policy/'
                          >
                            Privacy Policy.
                          </a>
                        </div>
                      }
                    />
                    <div className={cx(styles['drive-subscribe-content__newsletter-follow'])}>
                      <FollowIcons
                        bgClass={cx(styles['drive-subscribe-content__newsletter-follow-bg'])}
                        iconClassName={cx(
                          styles['drive-subscribe-content__newsletter-follow-icon']
                        )}
                        wrapperClassName={cx(
                          styles['drive-subscribe-content__newsletter-follow-container']
                        )}
                        size={2}
                        iconColor={Color.tealDark}
                        twitterUrl={TWITTER_URL}
                        facebookUrl={FACEBOOK_URL}
                        instagramUrl={INSTAGRAM_URL}
                        youtubeUrl={YOUTUBE_URL}
                        linkedinUrl={LINKEDIN_URL}
                        spotifyUrl={SPOTIFY_URL}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

type SubscribeToNewsletterContentProps = {
  className?: string
  height?: string
  setIsShowSubscribeButton?: (isShow: boolean) => void
}

export default SubscribeToNewsletterContent
