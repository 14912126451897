import { gql } from '@apollo/client'

export const GET_DRIVE_SPECS = gql`
  query GET_DRIVE_SPECS($query: WhereOptionsRedbookVehicle) {
    variant_id: RedbookVehicles(where: $query, paginate: { page: 0, pageSize: 1 }) {
      results {
        uuid
        slug
        variant_id: uuid
        vehicle_key: vehicleKey
        fuelType {
          id
          name
        }
        warrantyKm
        family {
          uuid
          id
          slug
        }
      }
    }
    overview: RedbookVehicles(where: $query) {
      results {
        uuid
        slug
        vehicle_key: vehicleKey
        attributes {
          id
          shortDescription
          longDescription
        }
        RedbookMake: make {
          id
          description
          code
        }
        RedbookVehicleFamily: family {
          family_code: familyCode
          description
        }
        makeCode
        familyCode
        modelCode
        yearGroup
        seriesModelYear
        badgeDescription
        badgeSecondaryDescription
        badgeCode
        manufacturerBodyStyle
        bodyStyleDescription
        manufacturerBodyConfig
        bodyConfigDescription
        limitedEdition
        buildCountryOriginDescription
        seriesPublic
      }
    }
    ownershipAndSafety: RedbookVehicles(where: $query) {
      results {
        uuid
        slug
        vehicle_key: vehicleKey
        ancapRating
        ancapYear
        doorNum
        seatCapacity
        payLoad
        towingNoBrakes
        towingBrakes
        warrantyYears
        warrantyCustAssist
        firstServiceKm
        firstServiceMonths
        regServiceKm
        regServiceMonths
        freeScheduledService
      }
    }
    engineAndPerformance: RedbookVehicles(where: $query) {
      results {
        uuid
        slug
        vehicle_key: vehicleKey
        engineDescription
        engineConfigurationDescription
        engineLocation
        inductionDescription
        engineTypeDescription
        vehicleTypeCode
        altEngEngineType
        altEngBatteryType
        altEngCurrentType
        altEngAmpHours
        altEngVolts
        altEngPower
        altEngPowerFrom
        altEngTorque
        torque
        torqueRpmFrom
        torqueRpmTo
        combinedPower
        altEngEngineCode
        altEngChargingMethod
        normalChargeMins
        quickChargeMins
        normalChargeVoltage
        quickChargeVoltage
        kmRangeElectricEng
        electricEngineLocation
        topSpeedElectricEng
        altEngEngineNumber
        altEngDrive
        cylinders
        camDescription
        fuelDeliveryDescription
        power
        powerRpmFrom
        powerRpmTo
        acceleration
      }
    }
    transmission: RedbookVehicles(where: $query) {
      results {
        uuid
        slug
        vehicle_key: vehicleKey
        driveCode
        gearNum
        gearTypeDescription
        gearLocationDescription
      }
    }
    fuelAndEnviroment: RedbookVehicles(where: $query) {
      results {
        uuid
        slug
        vehicle_key: vehicleKey
        ronRating
        maxEthanolBlend
        fuelCapacity
        fuelCombined
        fuelUrban
        fuelExtraUrban
        co2Combined
        emissionStandard
      }
    }
    dimensions: RedbookVehicles(where: $query) {
      results {
        uuid
        slug
        vehicle_key: vehicleKey
        manufacturerWheelBaseConfig
        wheelBaseConfig
        roofline
        height
        length
        width
        frontRimDesc
        frontTyreSize
        rearRimDesc
        rearTyreSize
        kerbWeight
        tareMass
        grossVehicleMass
        grossCombinationMass
      }
    }
    standardEquipment: RedbookVehicles(where: $query) {
      results {
        uuid
        slug
        vehicle_key: vehicleKey
        RedbookVehicleEquipmentList: equipment {
          id
          description
          group
        }
      }
    }
  }
`

export const LISTING_CARD_SPECS = gql`
  query LISTING_CARD_SPECS($query: WhereOptionsRedbookVehicle!) {
    listingSpecs: RedbookVehicles(where: $query) {
      results {
        variant_id: uuid
        fuelType {
          id
          name
        }
        vehicleKey
        driveCode
        gearTypeDescription
        engineSize
        familyCode
        badgeDescription
        badgeSecondaryDescription
        bodyStyleDescription
        bodyConfigDescription
        gears: gearNum
        cylinders
        engineDescription
        manufacturerBodyStyle
        manufacturerBodyConfig
        fuelCombined
        fuelTypeDescription
        ronRating
        gearLocationDescription
        badgeDescription
        badgeSecondaryDescription
        bodyStyleDescription
        bodyConfigDescription
        makeCode
        familyCode
        redbookFamily: family {
          family_code: familyCode
          familyDescription: description
        }
        photos {
          id
          externalId
          redbookPhotoId
          url
          redbookPhoto {
            view
          }
        }
      }
    }
  }
`
