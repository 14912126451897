import { FORM_FIELDS_TYPES } from '@grille/components/enquiry/constants'
import { RULES } from '@grille/utils/validation'
import { Form } from '../'

export const CONTACT_SELLER: Form = {
  fields: [
    {
      labelClassName: 'firstName',
      placeholder: 'First name',
      label: 'First name',
      name: 'firstName',
      type: FORM_FIELDS_TYPES.ANIMATED_TEXT,
      rules: [
        {
          rule: RULES.isValidString,
          minLength: 3,
          message: 'Please enter a first name'
        }
      ]
    },
    {
      labelClassName: 'lastName',
      placeholder: 'Last name',
      label: 'Last name',
      name: 'lastName',
      type: FORM_FIELDS_TYPES.ANIMATED_TEXT,
      rules: [
        {
          rule: RULES.isValidString,
          minLength: 1,
          message: 'Please enter a valid last name'
        }
      ]
    },
    {
      labelClassName: 'email',
      placeholder: 'Email',
      label: 'Email',
      name: 'email',
      type: FORM_FIELDS_TYPES.ANIMATED_TEXT,
      rules: [{ rule: RULES.isEmail, minLength: 1, message: 'Please enter a valid email address' }]
    },
    {
      labelClassName: 'phone',
      placeholder: 'Phone number',
      label: 'Phone number',
      name: 'enquiryPhone',
      prefix: '+61',
      type: FORM_FIELDS_TYPES.ANIMATED_PHONE,
      defaultHidden: true,
      rules: [{ rule: RULES.isMobilePhone, message: 'Please enter a valid phone number' }]
    },
    {
      labelClassName: 'postcode',
      placeholder: 'Postcode',
      label: 'Postcode',
      name: 'postcode',
      type: FORM_FIELDS_TYPES.ANIMATED_TEXT,
      defaultHidden: true,
      rules: [{ rule: RULES.isPostalCode, args: ['AU'], message: 'Please enter a valid post code' }]
    },

    {
      placeholder: 'Message',
      label: 'Message',
      labelClassName: 'comments w-full',
      className: 'enquiry-comments',
      defaultHidden: true,
      defaultOption: 'Hi, I’m interested in purchasing this car. Thank you.',
      name: 'additionalComments',
      type: FORM_FIELDS_TYPES.ANIMATED_TEXTAREA,
      rules: [
        { rule: RULES.isValidComments, message: 'Please don’t include hyperlinks in your comments' }
      ]
    },
    {
      className: 'checkboxes',
      header: 'I’m also interested in:',
      labelClassName: '',
      name: 'checkboxes',
      defaultHidden: true,
      type: FORM_FIELDS_TYPES.CHECKBOX,
      options: [
        {
          value: 'financeOffers',
          label: 'Finance options',
          name: 'financeCheckbox'
        },
        { value: 'marketplace', label: `Marketplace news and offers`, name: 'marketplaceCheckBox' }
      ],
      rules: []
    }
  ],
  labels: [
    {
      defaultHidden: true,
      id: 'enquiry-agree-label',
      className: '',
      label: `By clicking the Check availability button you acknowledge that you have read and agree to the Drive Terms and Conditions and Privacy Policy.`
    }
  ],
  buttons: [
    {
      className: 'blue contact-seller-btn-primary',
      color: 'transparent',
      text: 'Check availability',
      type: 'submit'
    }
  ]
}
