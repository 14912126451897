import { getRuntimeEnv } from '../get-runtime-env'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
const commentCountApiUrl = getRuntimeEnv('NEXT_PUBLIC_COMMENT_COUNT_API_URL')
export const getThreadIdentifier = (article: any) => {
  const disqus = article?.disqus ?? article?.node?.disqus ?? {}
  return disqus.threadIdentifier ?? null
}

export const getThreadIdentifiers = (articles: any[]) => {
  const threadIds: string[] = []
  articles?.forEach((article) => {
    const threadId = getThreadIdentifier(article)
    !isEmpty(threadId) && threadIds.push(threadId)
  })
  return threadIds
}

export const fetchCommentCount = async (threadIdentifier: string) => {
  if (!threadIdentifier || isEmpty(threadIdentifier) || typeof window === 'undefined') return 0
  try {
    const res = await fetch(`${commentCountApiUrl}?threadIdentifiers=${threadIdentifier}`)
    if (res.ok && res.status < 400) {
      const { threads } = await res.json()
      return threads[threadIdentifier]?.count ?? 0
    }
  } catch (error) {
    return 0
  }
}

export const fetchCommentCounts = async (threadIdentifiers: string[]) => {
  if (
    !threadIdentifiers ||
    !isArray(threadIdentifiers) ||
    isEmpty(threadIdentifiers) ||
    typeof window === 'undefined'
  )
    return {}
  try {
    const res = await fetch(
      `${commentCountApiUrl}?threadIdentifiers=${threadIdentifiers.join(',')}`
    )
    if (res.ok && res.status < 400) {
      const { threads } = await res.json()
      return threads
    }
  } catch (error) {
    return {}
  }
}
