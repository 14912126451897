import toUpper from 'lodash/toUpper'
import { DEALER_STATE_LICENCE_FORMAT } from '../../../constants/cars-for-sale'

export default function formatNumber(number, options = { maximumSignificantDigits: 3 }) {
  if (number) return new Intl.NumberFormat('en-AU', options).format(number)
  else return ''
}

export function isRenderRedbookCopyrightComponent(pathName, shouldRenderRedbookCopyright = true) {
  if (!shouldRenderRedbookCopyright) {
    return false
  }
  if (pathName && (pathName.startsWith('/cars-for-sale') || pathName.startsWith('/showrooms'))) {
    return true
  }
  return false
}

/**
 * Check if we want to render dealers licence information
 * @param {String} pathName URL Path
 * @returns {Boolean} Returns true if its single listing page
 */
export function renderDealerLicence(pathName) {
  if (pathName && pathName.startsWith('/cars-for-sale/car')) {
    return true
  }
  return false
}

/**
 * Get dealer licence according to dealer state
 * @param {Object} dealerInfo Listing Dealer Object
 * @returns {String} Returns formatted dealer licence according to state
 */
export function getFormattedDealerLicence(dealerInfo) {
  const { name, dealerDepartment, state, suburb, postcode } = dealerInfo || {}
  const licenceNo = dealerDepartment?.[0]?.dealerLicence ?? ''

  const stateSlug = toUpper(state)
  if (DEALER_STATE_LICENCE_FORMAT[stateSlug]) {
    return DEALER_STATE_LICENCE_FORMAT[stateSlug]({ licenceNo, postcode, suburb, name })
  } else {
    return DEALER_STATE_LICENCE_FORMAT.default({ licenceNo, postcode, suburb, name })
  }
}
