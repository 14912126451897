import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { LegacyFooter } from './legacy-footer'
import FooterWithProps, { TFooterWithProps } from './footer-with-props'

/**
 * Footer component.
 */

const Footer: FunctionComponent<FooterProps> = ({ isAppRoute = false, ...restProps }) => {
  const { showFullDescription, setShowFullDescription, ...footerRestProps } = restProps || {}

  return isAppRoute ? (
    <FooterWithProps
      data={footerRestProps as TFooterWithProps['data']}
      setShowFullDescription={setShowFullDescription}
      showFullDescription={showFullDescription}
    />
  ) : (
    <LegacyFooter {...restProps} />
  )
}

export type FooterProps = {
  showFullDescription?: boolean
  setShowFullDescription?: Dispatch<SetStateAction<boolean>>
  isAppRoute?: boolean
  [key: string]: any
}

export default Footer
