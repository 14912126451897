import { gql } from '@apollo/client'

/** Get Cars for sale listing counts using where  */
export const DEALER_LISTINGS_COUNT = gql`
  query DEALER_LISTINGS_COUNT(
    $where: WhereOptionsDealerListing
    $page: Int = 0
    $perPage: Int = 50
  ) {
    count: DealerListings(where: $where, paginate: { page: $page, pageSize: $perPage }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        currentPage
        pageItemCount
      }
    }
  }
`
