import cx from 'classnames'
import styles from './compareBucketCard.module.scss'
import { FunctionComponent, useMemo } from 'react'
import CompareBucketEngine, {
  CompareBucketEngineStatic
} from '../../../utils/class/CompareBucketEngine'
import { BucketVariant } from '../../../types/compareBucket'
import { CloseIcon } from '../../icons'
import Image from '../../common/image'

/**
 * component which only lives in compare bucket
 * - shows info of bucket variant
 * - provide remove button to remove variant from bucket
 */
const CompareBucketCard: FunctionComponent<CompareBucketCardProps> = ({ carVariant }) => {
  const cbEngine: CompareBucketEngineStatic | null = useMemo(
    () => CompareBucketEngine.getCurrentInstance()?.export() ?? null,
    []
  )
  const handleRemoveBtnClick = () => {
    cbEngine?.removeItem(carVariant?.uuid)
  }

  return (
    <div className={cx(styles['drive-compare-bucket_card'])}>
      {/* Close button */}
      <span
        className={cx(styles['drive-compare-bucket_card__remove-btn'])}
        onClick={handleRemoveBtnClick}
      >
        <CloseIcon
          className={cx(styles['drive-compare-bucket_card__remove-btn-icon'])}
          height='16'
        />
      </span>
      {/* Image */}
      <figure className={cx(styles['drive-compare-bucket_card__image'])}>
        <Image
          sourceUrl={carVariant?.image?.url || carVariant?.image?.sourceUrl}
          tailPipeExternalId={carVariant?.image?.externalId} // required for image from variant page
          loading='eager'
          imageBg='bg-white'
          sizeName={carVariant.imageSizeName ?? 'COMPARE_BUCKET_CARD'}
          cloudinary={carVariant?.image?.cloudinary}
        />
      </figure>
      {/* Name */}
      <span className={cx(styles['drive-compare-bucket_card__variant-name'])}>{`${
        carVariant?.make
      } ${carVariant?.model} ${carVariant?.badge ?? ''}`}</span>
    </div>
  )
}

type CompareBucketCardProps = {
  carVariant: BucketVariant
}

export default CompareBucketCard
