/**
 * External dependencies.
 */
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import styles from './menuTitle.module.scss'

/**
 * MenuTitle component.
 */
const MenuTitle = ({ title = '' }) => {
  if (isEmpty(title)) {
    return null
  }

  return <p className={cx(styles['drive-footer__menu-title'])}>{title}</p>
}

MenuTitle.propTypes = {
  title: PropTypes.string
}

export default MenuTitle
