import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import get from 'lodash/get'
import cloneDeep from 'ramda/src/clone'
import { priceFormat } from './miscellaneous'
/**
 * Converts Min to Hours and Min format eg: 10h 12min
 *
 * @param {Int} minutes number.
 * @param {Boolean} trim trim if min or hr is 0.
 * @returns {String} eg: 10h 12min
 */
export const convertMinToHourMin = (minutes, hourText = 'h', minText = 'm', trim = false) => {
  if (!minutes) return ''
  const hour = Math.floor(minutes / 60)
  const min = minutes % 60
  if (hourText !== 'h' && hour > 1) {
    hourText = ' hours'
  }
  if (minText !== 'm' && min > 1) {
    minText = ' mins'
  }
  if (trim) {
    const hString = hour === 0 ? '' : `${hour}${hourText}`
    const mString = min === 0 ? '' : `${min}${minText}`
    return `${hString} ${mString}`.trim()
  }
  return `${hour}${hourText} ${min}${minText}`
}

/**
 * Converts Sec to min and sec
 *
 * @param {Int} seconds number.
 * @returns {String} eg: 2min 30sec | 30 sec | 2min
 */
export const convertSecToMin = (seconds, minText = 'min', secText = 'sec', trim = false) => {
  if (!seconds) return ''

  if (seconds < 60) {
    // its seconds
    return trim ? `${seconds}${secText}` : `${seconds} ${secText}`
  }

  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  if (remainingSeconds > 0) {
    return trim
      ? `${minutes}${minText} ${remainingSeconds}${secText}`
      : `${minutes} ${minText} ${remainingSeconds} ${secText}`
  }

  return trim ? `${minutes}${minText}` : `${minutes} ${minText}`
}

/**
 * Get Car Type hybrid | piston | electric
 *
 * @param {String} engineType Redbook.
 *
 * @returns {String}
 */
export const getCarType = (engineType) => {
  if (isEmpty(engineType)) return undefined

  let carType = engineType
  const isElectricOrHybridCar = engineType.match(/electric|hybrid/gi) !== null

  carType = isElectricOrHybridCar ? 'electric or hybrid' : 'piston'
  carType =
    (engineType.match(/electric/gi) && engineType.match(/piston/gi)) || engineType.match(/hybrid/gi)
      ? 'hybrid'
      : carType
  carType = isElectricOrHybridCar && carType !== 'hybrid' ? 'electric' : carType

  return carType
}

/**
 * Get Battery Short Name
 * @default empty{String}
 * @param {String} batteryFullName redBookdata.
 *
 * @returns {String}
 */
export const getBatteryShortName = (batteryfullName = '') => {
  const fullNames = {
    'lithium ion': 'LI',
    'nickel metal hydride': 'NMH',
    'permanent magnet': 'Perm Magnet'
  }

  return fullNames[batteryfullName.toLowerCase()] ?? batteryfullName // fallback to fullname if not found
}

/**
 * Get Engine Power based on type of vehicle
 * @param {String} carType electric | hybrid | piston
 * @default to piston
 * @param {Object} redBookData Redbook.
 *
 * @returns {array}
 */

export const getEnginePower = (carType, redBookData = {}) => {
  const { power, torque, altEngPower, altEngTorque, combinedPower } = redBookData

  let enginePower = 'NA'
  switch (carType) {
    case 'hybrid':
      enginePower =
        combinedPower && altEngTorque
          ? `${combinedPower + (altEngPower ?? 0)}kW (comb), ${altEngTorque}Nm`
          : enginePower
      break

    case 'electric':
      enginePower =
        combinedPower && altEngTorque ? `${combinedPower}kW, ${altEngTorque}Nm` : enginePower
      break

    default:
      /* piston (petrol | desiel) */
      enginePower = power && torque ? `${power}kW, ${torque}Nm` : enginePower
      break
  }
  return enginePower
}
/**
 * Get Variant specs mapped data
 *
 * @param {Object} redbook Redbook.
 *
 * @returns {array}
 */

export const getVariantSpecsMappedData = (
  redbook,
  dealerListing = {},
  options = { replaceDoorsSeatsWithOdometer: false }
) => {
  if (isEmpty(redbook)) {
    return []
  }

  const {
    doorNum,
    seatCapacity,
    normalChargeMins,
    kmRangeElectricEng,
    engineDescriptionFull,
    cylinders,
    fuelType,
    fuelCombined,
    ronRating,
    warrantyKms,
    warrantyYears,
    ancapYear,
    ancapRating,
    engineType,
    altEngEngineType,
    altEngBatteryType,
    redbookDescription,
    attributes
  } = redbook || undefined

  const gearTypeDescription = attributes?.[0]?.gearTypeDescription ?? ''
  const driveCode = attributes?.[0]?.driveCode ?? ''

  const ronRatingText = ronRating ? `(${ronRating})` : ''
  /* combine engineType and redbookDescription
   * adds all the keywords that may contain any engine type information
   */
  const carType = getCarType(`${engineType} - ${redbookDescription}`)
  let odometerGeneric = {
    icon: 'CarGenericIcon',
    text: doorNum && seatCapacity ? `${doorNum} doors, ${seatCapacity} seats` : 'NA',
    alt: 'Doors and Seats'
  }
  if (options?.replaceDoorsSeatsWithOdometer) {
    odometerGeneric = {
      icon: 'OdometerIcon',
      text: dealerListing?.odometer ? `${priceFormat(dealerListing.odometer)} km` : 'NA',
      alt: 'odometer'
    }
  }

  const fuelTypeDescription = fuelType?.name ?? fuelType
  const specs = [
    odometerGeneric,
    {
      icon: carType === 'electric' ? 'ElectricEngineIcon' : 'EngineIcon',
      text:
        carType === 'electric' && altEngEngineType && altEngBatteryType
          ? `${getBatteryShortName(altEngEngineType)}, ${getBatteryShortName(altEngBatteryType)}`
          : engineDescriptionFull && cylinders
          ? `${engineDescriptionFull}, ${cylinders} cyl.`
          : 'NA',
      alt: 'Engine'
    },
    {
      icon: 'EnginePowerIcon',
      text: getEnginePower(carType, redbook),
      alt: 'Engine Power'
    },
    {
      icon:
        carType === 'hybrid'
          ? 'FuelTypeHybridIcon'
          : carType === 'electric'
          ? 'ElectricVehicleStationIcon'
          : 'FuelIcon',
      text:
        carType === 'electric'
          ? `${normalChargeMins ? convertMinToHourMin(normalChargeMins) + ' chg' : ''}${
              kmRangeElectricEng
                ? `${normalChargeMins ? ',' : ''} ${kmRangeElectricEng}km range`
                : normalChargeMins
                ? ''
                : 'NA'
            }`
          : fuelTypeDescription && fuelCombined
          ? `${fuelTypeDescription} ${ronRatingText} ${fuelCombined}L/100KM`
          : 'NA',
      alt: 'Fuel'
    },
    {
      icon: 'DrivetrainIcon',
      text: driveCode ?? 'NA',
      alt: 'Manufacturer'
    },
    {
      icon: 'TransmissionIcon',
      text: gearTypeDescription ? `${gearTypeDescription}` : 'NA',
      alt: 'Transmission'
    },
    {
      icon: 'WarrantyIcon',
      text:
        warrantyKms && warrantyYears
          ? `${warrantyYears} Yr, ${warrantyKms > -1 ? warrantyKms : 'Unltd'} KMs`
          : 'NA',
      alt: 'Warranty'
    },
    {
      icon: 'AncapSafetyIcon',
      text: ancapRating && ancapYear ? `${ancapRating}/5 star (${ancapYear})` : 'NA',
      alt: 'Ancap Safety'
    }
  ]
  return specs
}

/** Transform redbook specs */
export const transformSpecs = (variantData, dealerListing, specsAndRatings, specRes) => {
  const driveVariantData = cloneDeep(variantData)

  if (!isEmpty(dealerListing)) {
    if (
      !isEmpty(driveVariantData?.overview?.results) &&
      isArray(driveVariantData.overview.results)
    ) {
      driveVariantData.overview.results.forEach((r) => {
        r.makeName = r.RedbookMake?.description ?? null
        r.modelName = r.RedbookVehicleFamily?.description ?? null
        if (!isEmpty(dealerListing)) {
          r.variant = dealerListing?.redbookVehicle?.vehicleDescription ?? null
          r.colour = dealerListing?.colour ?? null
        }
      })
    } else {
      driveVariantData.overview.makeName =
        driveVariantData.overview.RedbookMake?.description ?? null
      driveVariantData.overview.modelName =
        driveVariantData.overview.RedbookVehicleFamily?.description ?? null
      if (!isEmpty(dealerListing)) {
        driveVariantData.overview.variant =
          dealerListing?.redbookVehicle?.vehicleDescription ?? null
        driveVariantData.overview.colour = dealerListing?.colour ?? null
      }
    }
  }

  const fields = [
    'overview',
    'ownershipAndSafety',
    'engineAndPerformance',
    'transmission',
    'fuelAndEnviroment',
    'dimensions',
    'standardEquipment'
  ]
  let fieldsObj = {}

  fields.forEach((field) => {
    fieldsObj = {
      ...fieldsObj,
      [field]: getSpecsResults(driveVariantData, field)
    }
  })

  return {
    ...driveVariantData,
    ...fieldsObj,
    driveAndRatings: specsAndRatings
  }
}

const getSpecsResults = (data, field) => {
  return !isEmpty(data?.[field]?.results) ? data?.[field]?.results[0] : []
}

/** Combine variant data and redbook specs for compare cards  */
export const combineSpecs = (compareCarCards, specs) => {
  const variantData = !isEmpty(specs) ? transformSpecs(specs) : {}
  const updatedCards = compareCarCards.map((card) => {
    if (isEmpty(card.variantData)) return card

    // Find the specs details of the card using vehicle key
    const specsIndex = variantData?.variant_id?.results?.findIndex(
      (variant) => variant.vehicle_key === card.variantData.redbook.vehicleKey
    )

    if (specsIndex === undefined || specsIndex < 0) return card
    card.variantData.specsData = specs?.specs?.results[specsIndex] //{}

    const ratings = card.variantData?.variantRating?.ratings
    if (ratings?.performance) {
      card.variantData.specsData.driveAndRatings = ratings
    }
    return card
  })

  return updatedCards
}

export const CATEGORY_MAP = {
  overview: [
    'RedbookMake',
    'RedbookVehicleFamily',
    'makeCode',
    'makeName',
    'familyCode',
    'modelCode',
    'modelName',
    'yearGroup',
    'seriesModelYear',
    'badgeDescription',
    'badgeCode',
    'manufacturerBodyStyle',
    'bodyStyleDescription',
    'manufacturerBodyConfig',
    'bodyConfigDescription',
    'limitedEdition',
    'buildCountryOriginDescription',
    'seriesPublic'
  ],
  ownershipAndSafety: [
    'ancapRating',
    'ancapYear',
    'doorNum',
    'seatCapacity',
    'payLoad',
    'towingNoBrakes',
    'towingBrakes',
    'warrantyYears',
    'warrantyCustAssist',
    'firstServiceKm',
    'firstServiceMonths',
    'regServiceKm',
    'regServiceMonths',
    'freeScheduledService'
  ],
  engineAndPerformance: [
    'engineDescription',
    'inductionDescription',
    'engineConfigurationDescription',
    'cylinders',
    'camDescription',
    'fuelDeliveryDescription',
    'power',
    'powerRpmFrom',
    'powerRpmTo',
    'acceleration',
    'altEngTorque',
    'engineLocation',
    'torqueRpmFrom'
  ],
  engineAndPerformanceHybrid: [
    'engineDescription',
    'engineConfigurationDescription',
    'engineLocation',
    'inductionDescription',
    'engineTypeDescription',
    'vehicleTypeCode',
    'altEngEngineType',
    'altEngBatteryType',
    'altEngCurrentType',
    'altEngAmpHours',
    'altEngVolts',
    'altEngPower',
    'altEngPowerFrom',
    'altEngTorque',
    'torque',
    'torqueRpmFrom',
    'torqueRpmTo',
    'combinedPower',
    'altEngEngineCode',
    'altEngChargingMethod',
    'normalChargeMins',
    'quickChargeMins',
    'normalChargeVoltage',
    'quickChargeVoltage',
    'kmRangeElectricEng',
    'electricEngineLocation',
    'topSpeedElectricEng',
    'altEngEngineNumber',
    'altEngDrive',
    'cylinders',
    'camDescription',
    'fuelDeliveryDescription',
    'power',
    'powerRpmFrom',
    'powerRpmTo',
    'acceleration'
  ],
  transmission: ['driveCode', 'gearNum', 'gearTypeDescription', 'gearLocationDescription'],
  fuelAndEnviroment: [
    'ronRating',
    'maxEthanolBlend',
    'fuelCapacity',
    'fuelCombined',
    'fuelUrban',
    'fuelExtraUrban',
    'co2Combined',
    'emissionStandard'
  ],
  dimensions: [
    'manufacturerWheelBaseConfig',
    'wheelBaseConfig',
    'wheelBase',
    'roofline',
    'height',
    'length',
    'width',
    'frontRimDesc',
    'frontTyreSize',
    'rearRimDesc',
    'rearTyreSize',
    'kerbWeight',
    'tareMass',
    'grossVehicleMass',
    'grossCombinationMass'
  ],
  standardEquipment: ['RedbookVehicleEquipmentList']
}

export const getSpecsSection = (section, specsRes) => {
  const specs = specsRes?.specs?.results ? specsRes?.specs?.results[0] : specsRes

  let fields = {}
  const sectionMap = CATEGORY_MAP[section] ?? []

  sectionMap.forEach((field) => {
    if (field === 'makeName') {
      fields = { ...fields, [field]: specs?.['RedbookMake']?.description ?? '' }
    } else if (field === 'modelName') {
      fields = { ...fields, [field]: specs?.['RedbookVehicleFamily']?.description ?? '' }
    } else {
      fields = { ...fields, [field]: specs?.[field] ?? '' }
    }
  })
  return fields
}

export const categoriseSpecs = (specsResponse = {}) => {
  return { variant_id: specsResponse?.variant_id, ...specsResponse }
}

/**
 * @photos array of photos
 * @viewPath path to access view
 * @view string eg. F
 * @return {Object} photo
 */
export const getVariantPhotoByView = ({ photos, viewPath = 'redbookPhoto.view', view = 'F' }) => {
  const photo = photos?.find((photo) => {
    return get(photo, viewPath) === view
  })
  return photo
}

/**
 * Function to check if variant is current release
 * @param {Object} vehicle tailpipe vehicle object
 * @returns {Boolean} Returns if vehicle is current release
 */
export const isCurrentRelease = (vehicle) => {
  return vehicle?.currentRelease && vehicle?.currentRelease === 'T'
}
