import useWindowWidth from '../use-window-width'
import { MOBILE_DEVICE_SIZES, TABLET_DEVICE_SIZES } from '../../../constants/index'

const useResponsive = () => {
  const { windowSize } = useWindowWidth()
  const isMobileScreen = MOBILE_DEVICE_SIZES.includes(windowSize)
  const isTabletScreen = TABLET_DEVICE_SIZES.includes(windowSize)

  return { isMobileScreen, isTabletScreen }
}

export default useResponsive
