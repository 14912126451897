import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import filter from 'lodash/filter'
import isString from 'lodash/isString'
import shuffle from 'lodash/shuffle'
import orderBy from 'lodash/orderBy'
import toString from 'lodash/toString'

import {
  SHOWROOMS_CAR_TYPE_PATHNAME,
  SHOWROOMS_MAKE_PATHNAME,
  SHOWROOMS_DEALS_PATHNAME
} from '../../constants/'
import { DCOTY_CATEGORY_WINNER_TERM_SLUG, DCOTY_FINALIST_TERM_SLUG } from '../../constants/slug'
import { SHOWROOMS_CAR_TYPE_SLUGS_TO_EXCLUDE } from '../../constants/showrooms'
import { getModelDealQuery } from '../../queries/showrooms/deals/get-deals'
import { createVariantDescription } from '../../components/templates/reviews/single/functions'
import find from 'lodash/find'

/**
 * Get Popular Makes.
 *
 * @param {Array} makes Makes.
 *
 * @returns {Array} popularMakes Popular Makes.
 */
export const getPopularMakes = (makes) => {
  if (isEmpty(makes) || !isArray(makes)) {
    return []
  }

  return filter(makes, (make) => {
    return make?.makeMeta?.isPopular === 'yes'
  })
}

export const getFilterOptions = ({ pathname, segments, carTypes, makes, priceRanges }) => {
  const filterOptions = [
    {
      placeHolderText: 'Select Segment',
      filterOptions: segments?.nodes,
      type: 'segment'
    },
    {},
    {
      placeHolderText: 'Select Price Range',
      filterOptions: priceRanges?.nodes,
      type: 'price-range'
    }
  ]

  if (pathname === SHOWROOMS_CAR_TYPE_PATHNAME || pathname === SHOWROOMS_DEALS_PATHNAME) {
    filterOptions[1] = {
      placeHolderText: 'Select Make',
      filterOptions: makes || [],
      type: 'make'
    }
  } else if (pathname === SHOWROOMS_MAKE_PATHNAME) {
    filterOptions[1] = {
      placeHolderText: 'Select Type',
      filterOptions: (carTypes?.nodes ?? []).filter(
        (carType) => !SHOWROOMS_CAR_TYPE_SLUGS_TO_EXCLUDE.includes(carType?.slug ?? '')
      ),
      type: 'carType'
    }
  }

  return filterOptions
}

export const getVariantNameUsingTailpipe = (vehicle) => {
  if (isEmpty(vehicle)) {
    return null
  }
  const model = vehicle?.model?.description ?? ''
  const variant = createVariantDescription(vehicle)
  const year = vehicle?.yearGroup ?? ''
  const make = vehicle?.make?.description ?? ''
  return `${year ?? ''} ${make ?? ''} ${model ?? ''} ${variant ?? ''}`
}

export const getDealsCountToDisplay = (model) => {
  const modelVariants = model?.modelMeta?.variants ? model?.modelMeta?.variants : []
  let dealsCount = 0

  let validVariantDeals = 0
  modelVariants.forEach((variant) => {
    validVariantDeals = validDeals(variant?.deals?.nodes)
    if (validVariantDeals.length > 0) {
      dealsCount = dealsCount + 1
    }
  })

  return dealsCount
}

/**
 * Checks deals based on commencesAt and expiresAt and returns valid deals.
 *
 * @param deals
 * @returns {array} returns array of valid deals
 */
export const validDeals = (deals) => {
  if (isEmpty(deals) || !Array.isArray(deals)) {
    return false
  }
  // Return true if at least one of the deal is valid( commenced and not expired )
  return deals.filter((deal) => {
    const { commencesAt, expiresAt } = deal?.dealsFields || {}

    if (isEmpty(commencesAt) || isEmpty(expiresAt)) {
      return false
    }

    const commencedTimestamp = getTimeStamp(commencesAt)
    const endTimestamp = getTimeStamp(expiresAt)
    const currentTimestamp = getTimeStamp()

    return commencedTimestamp <= currentTimestamp && endTimestamp > currentTimestamp
  })
}

/**
 * Checks if at least one of the deal is valid.
 *
 * @param deals
 * @returns {boolean} returns true if at least one deal is valid
 */
export const hasValidDeal = (deals) => {
  if (isEmpty(deals) || !Array.isArray(deals)) {
    return false
  }

  // Return true if at least one of the deal is valid( commenced and not expired )
  return deals.some((deal) => {
    const { commencesAt, expiresAt } = deal?.dealsFields ?? deal ?? {}

    if (isEmpty(commencesAt) || isEmpty(expiresAt)) {
      return false
    }

    const commencedTimestamp = getTimeStamp(commencesAt)
    const endTimestamp = getTimeStamp(expiresAt)
    const currentTimestamp = getTimeStamp()

    return commencedTimestamp <= currentTimestamp && endTimestamp >= currentTimestamp
  })
}

/**
 * Get timestamp.
 *
 * @param dateString
 * @returns {number} Time stamp of given date ( e.g. "2020-12-01" ), or current time stamp if no date passed.
 */
const getTimeStamp = (dateString = '') => {
  return !isEmpty(dateString)
    ? Math.round(new Date(dateString).getTime() / 1000)
    : Math.round(new Date().getTime() / 1000)
}

/**
 * Filter filterCategoryWinnerOrFinalistTerms
 *
 * @param {String} awardTags
 * @returns {array} Returns array of award term slugs if its either a category winner or finalist term
 */
export const filterCategoryWinnerOrFinalistTerms = (awardTags) => {
  if (isEmpty(awardTags) || !Array.isArray(awardTags)) {
    return []
  }

  return awardTags.filter(
    (slug) => DCOTY_CATEGORY_WINNER_TERM_SLUG === slug || DCOTY_FINALIST_TERM_SLUG === slug
  )
}

/**
 * Get model name
 *
 * @param {object} model Showroom details.
 *
 * @return {string}
 */
export const getModelNameAndUri = (model) => {
  if (isEmpty(model)) {
    return null
  }

  const modelName = model?.title ?? ''
  const modelUri = model?.uri ?? ''
  const makeName = model?.parentMake?.parentMake?.title ?? ''

  if ((!modelName && !makeName) || modelName.toLowerCase().includes(makeName.toLowerCase())) {
    return {
      title: modelName,
      uri: modelUri
    }
  }

  return {
    title: `${makeName} ${modelName}`,
    uri: modelUri
  }
}

/**
 * Function getvehicleUuids
 */
export const getvehicleUuids = (attachedVehicles) => {
  let vehicleUuids = ''
  if (isEmpty(attachedVehicles)) {
    return vehicleUuids
  }

  attachedVehicles.forEach((vehicle) => {
    if (vehicleUuids) {
      vehicleUuids = vehicleUuids + ','
    }
    vehicleUuids = vehicleUuids + '' + vehicle?.vehicleUuid ?? ''
  })

  return vehicleUuids
}

/**
 * Get variant name
 *
 * @param {object} variant Car details.
 *
 * @return {string}
 */
export function getVariantName(variant) {
  if (isEmpty(variant)) {
    return null
  }

  let variantName = variant?.title ?? ''
  const modelName = variant?.parentModel?.parentModel?.title ?? ''
  const makeName = variant?.parentModel?.parentModel?.parentMake?.parentMake?.title ?? ''

  if (!variantName && !modelName && !makeName) {
    return modelName
  }

  if (
    variantName.toLowerCase().includes(modelName.toLowerCase()) &&
    variantName.toLowerCase().includes(makeName.toLowerCase())
  ) {
    return variantName
  }

  variantName = modelName.toLowerCase().includes(variantName.toLowerCase())
    ? variantName
    : `${modelName} ${variantName}`

  if (modelName.toLowerCase().includes(makeName.toLowerCase())) {
    return variantName
  }

  return makeName + ' ' + variantName
}

/**
 * Function getVariantIds
 */
export const getVariantIds = (carVariantRatingsData) => {
  if (isEmpty(carVariantRatingsData)) {
    return ''
  }

  let variantIds = ''

  carVariantRatingsData.map((car) => {
    if (variantIds) {
      variantIds = variantIds + ','
    }
    variantIds = variantIds + '' + car?.variant?.slug ?? ''

    return variantIds
  })

  return variantIds
}

/**
 * Function to sort models by make title
 * @param {Array} models Modes list
 * @returns {Array} Models list sorted by make name ( ASC ) or (DESC)
 */
export const sortModelsByMake = (models, sort = 'asc') => {
  if (sort !== 'asc' && sort !== 'desc') {
    return models
  }
  return orderBy(models, (item) => item.makeDescription, sort)
}

/**
 * Function to sort models by name
 * @param {Array} models Modes list
 * @returns {Array} Models list sorted by name ( ASC ) or (DESC)
 */
export const sortModels = (models, sort = 'asc') => {
  if (sort !== 'asc' && sort !== 'desc') {
    return models
  }
  return orderBy(models, (item) => item.slug, sort)
}

/**
 * Function to return Car Type title eg. "City Cars" => "City"
 * @param {String} title Car Type page title
 * @returns {String} Car Type
 */
export const getCarTypeTitle = (title) => {
  return !isEmpty(title) && isString(title) ? title.replace('Cars', '') : title
}

/**
 * Function to randomize posts in arrray
 * @param {Array} posts Array of post
 * @returns {Array} Randomly mixed posts
 */
export const getMixedPosts = (posts) => {
  return shuffle(posts)
}

export const checkValidDeal = (deal) => {
  const { commences, expires } = deal || {}

  if (isEmpty(commences) || isEmpty(expires)) {
    return false
  }

  const commencedTimestamp = getTimeStamp(commences)
  const endTimestamp = getTimeStamp(expires)
  const currentTimestamp = getTimeStamp()

  return commencedTimestamp <= currentTimestamp && endTimestamp > currentTimestamp
}

/**
 * Function to combine deals data from tailpipe & content fields from wordpress
 * @param {*} dealsResponse
 * @param {*} showroomResponse
 * @returns
 */
export const combineDealsData = (dealsList, showroomResponse) => {
  const models = []
  if (!isEmpty(dealsList) && !isEmpty(showroomResponse))
    for (const familyCode in dealsList) {
      const deal = dealsList[familyCode]
      const { key: vehicleKey, dealsCount = 0 } = deal || {}
      const showroomModelData = showroomResponse?.[vehicleKey]
      if (isEmpty(showroomModelData)) {
        return
      }
      let model = {}
      const showroomModelNode = showroomModelData?.nodes?.[0] ?? {}
      const showroomModel = { ...showroomModelNode, ...showroomModelNode?.parentModel?.parentModel }

      model = { ...showroomModel, dealsCount }
      models.push(model)
    }
  return models
}

/**
 * Function to combine deals data from tailpipe & content fields from wordpress
 * @param {*} dealsList - Record<string, {key: string, dealsCount: number, variantsCount: number, makeUuid:string }>[]
 * @param {*} wpVehicleModelPosts nodes of vehicleModelPosts response data
 * @param {*} wpVehicleMakePosts nodes of wpVehicleMakePosts response data
 * @returns
 */
export const combineDealsWithWpImageData = (dealsList, wpVehicleModelPosts, wpVehicleMakePosts) => {
  const models = []
  if (!isEmpty(dealsList) && !isEmpty(wpVehicleModelPosts)) {
    for (const familyCode in dealsList) {
      const deal = dealsList[familyCode]
      const { key: modelUuid, makeUuid } = deal || {}
      const vehicleModelPost = find(wpVehicleModelPosts, { uuid: modelUuid })
      const vehicleMakePost = find(wpVehicleMakePosts, { uuid: makeUuid })
      if (isEmpty(vehicleModelPost)) {
        return
      }
      const model = {
        ...vehicleModelPost,
        ...deal,
        logo: vehicleMakePost?.logo
      }
      models.push(model)
    }
  }
  return models
}

/**
 * Function to map deals data from tailpipe using wp models data vehicleKeys and sync in place
 * @param {client} Required Grille Apollo client instance
 * @param {models} Array Models from wp that has vehicle keys in variants
 * @returns {models} New array of mapped models
 */
export const mapTailPipeDealsToWpMakeDeals = async (client, models = []) => {
  return await Promise.all(
    models.map(async (model) => {
      const modelDescription = model?.title ?? ''
      const {
        data: { deals = {} }
      } = await client.query({
        query: getModelDealQuery(modelDescription),
        context: { tailpipe: true }
      })

      if (!isEmpty(deals?.results)) {
        const transformedVariants = []
        const { variants } = deals.results[0]
        if (!isEmpty(variants)) {
          variants.forEach((variant, index) => {
            const { nodes } = variant?.vehiclable || {}
            transformedVariants.push({ deals: { nodes: [] } })
            if (!isEmpty(nodes)) {
              nodes.forEach((node) => {
                transformedVariants[index].deals.nodes.push({
                  dealsFields: node
                })
              })
            }
          })
        }
        return {
          ...model,
          modelMeta: {
            ...model.modelMeta,
            variants: transformedVariants
          }
        }
      }
      return model
    })
  )
}

/**
 * Generate model name from car article details
 */
export const generateModelName = (carDetails) => {
  const { brand, model, made, variant } = carDetails ?? {}
  const year = isString(made) ? made : toString(made)

  if (isEmpty(brand) && isEmpty(model) && isEmpty(variant) && isEmpty(made)) {
    return null
  }
  let title = brand ?? ''
  if (!isEmpty(model)) {
    title += ` ${model ?? ''}`
  }
  if (!isEmpty(variant)) {
    title += ` ${variant ?? ''}`
  }
  if (!isEmpty(year)) {
    title += ` ${year ?? ''}`
  }
  return title
}
