import React, { FunctionComponent, MutableRefObject, useEffect, useMemo } from 'react'
import GlideSlider from '../../glideSlider'
import { SLIDER_SETTINGS_GLIDE } from '../../../../constants'
import { useState, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import ArticleCard from '../../article-card'
import AdArticleCard from '../../article-card/ad-article-card'
import {
  Article,
  ArticleAd,
  ArticleCardProps,
  ArticleSlidesInfo,
  SliderCallBackProps
} from './types'
import { defaultGetAdArrayIndexByArticleIndex, defaultIsAllFeched } from './funtions'
import { Properties } from '@glidejs/glide'

import {
  fetchCommentCounts,
  getThreadIdentifier,
  getThreadIdentifiers
} from '../../../../utils/functions/social-counts/comments'

import {
  fetchShareCounts,
  getArticleUri,
  getArticleUris
} from '../../../../utils/functions/social-counts/shares'

const ArticleSliderContainer: FunctionComponent<ArticleSliderContainerProps> = ({
  sliderSettings,
  articles,
  removeExcess,
  cardWrapperTag,
  cardWrapperClass,
  articleCardProps,
  isAllFetched = defaultIsAllFeched,
  getAdArrayIndexByArticleIndex = defaultGetAdArrayIndexByArticleIndex,
  glideMountHandle,
  shouldUpdateGlideAfterAllAdFecthed = false,
  processAds = true
}) => {
  const [articleSlides, setArticleSlides] = useState<{
    articles: (Article | ArticleAd)[]
    fetched: boolean
  }>({
    articles: isEmpty(articles) || !Array.isArray(articles) ? [] : [...articles],
    fetched: false
  })

  const fetchedAdInfoRef = useRef<SliderCallBackProps[]>([])
  const [allFetched, setAllFetched] = useState(false)
  const [glideInstance, setGlideInstance] = useState<Properties | null>(null)

  // updates  articleSlides once all Ad's are fetched
  useEffect(() => {
    if (allFetched && processAds) {
      const newArticleSlides = [...articleSlides.articles]

      let filtered: Array<{
        [key: string]: any
      }> = []

      //filter and remove ad articles that didnt serve ad
      newArticleSlides?.forEach((article, i) => {
        if (article?.type === 'adArticleCard') {
          const index = getAdArrayIndexByArticleIndex(i)
          const fetchedAd = fetchedAdInfoRef?.current?.[index] ?? null
          // at a time only one ad gets rendered
          if (!isEmpty(fetchedAd?.data) && fetchedAd?.adStatus) {
            filtered.push({ ...article, node: fetchedAd?.data, isAdFetched: true })
          }
        } else {
          filtered.push(article)
        }
      })

      // remove extra articles from more than 6 articles
      const removeExcessMax = removeExcess?.max ?? 0 // 6 articles
      const filteredCount = filtered?.length
      const deleteCount = filteredCount - removeExcessMax
      const startIndex = -1 * (filteredCount - removeExcessMax)
      filtered.splice(startIndex, deleteCount)

      if (!articleSlides.fetched) {
        // Updated articleSlides state with articles + 2 Ad's and sets fetched to true
        setArticleSlides({ articles: filtered, fetched: true })
      }
    }
  }, [allFetched, articleSlides, getAdArrayIndexByArticleIndex, processAds, removeExcess?.max])

  // updates articleSlides state to re-render component
  useEffect(() => {
    if (articleSlides.fetched) {
      setArticleSlides({ ...articleSlides })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleSlides.fetched])

  useEffect(() => {
    if ((glideInstance && articleSlides.fetched, shouldUpdateGlideAfterAllAdFecthed)) {
      glideInstance?.update({})
    }
  }, [articleSlides.fetched, glideInstance, shouldUpdateGlideAfterAllAdFecthed])

  const settings = {
    ...SLIDER_SETTINGS_GLIDE,
    ...sliderSettings
  }

  // Checks if all ad's are fetched and update allFetched state to true
  const adSliderCallBack = (event: SliderCallBackProps): void => {
    // use ref to prevent re-renders, here we are getting 2 ads
    fetchedAdInfoRef.current = [...fetchedAdInfoRef.current, event]
    // if all ad articles have been worked on
    if (isAllFetched(fetchedAdInfoRef, articleSlides)) {
      setAllFetched(true) // sets true when we gets 2 ads
    }
  }
  const glideOptions = { ...settings }

  const [commentCounts, setCommentCounts] = useState<any>({})

  const threadIdentifiers = useMemo(() => {
    return getThreadIdentifiers(articles)
  }, [articles])

  useEffect(() => {
    let mounted = true
    const fetchAndShow = async () => {
      const threads = await fetchCommentCounts(threadIdentifiers)
      mounted && setCommentCounts(threads)
    }
    threadIdentifiers?.length > 0 && fetchAndShow()
    return () => {
      mounted = false
    }
  }, [threadIdentifiers])

  const [shareCounts, setShareCounts] = useState<any>({})
  const articleUris = useMemo(() => {
    return getArticleUris(articles)
  }, [articles])

  useEffect(() => {
    let mounted = true
    const fetchAndShow = async () => {
      const shares = await fetchShareCounts(articleUris)
      mounted && setShareCounts(shares)
    }
    articleUris?.length > 0 && fetchAndShow()
    return () => {
      mounted = false
    }
  }, [articleUris])

  return (
    <div>
      <GlideSlider
        glideOptions={glideOptions}
        afterMountCallBack={(mountedGlide) => {
          glideMountHandle && glideMountHandle(mountedGlide)
          setGlideInstance(mountedGlide)
        }}
      >
        {articleSlides.articles.map((article, index) => {
          const threadIdentifier = getThreadIdentifier(article)
          const commentCount = threadIdentifier
            ? commentCounts?.[threadIdentifier]?.count ?? null
            : null

          const articleUri = getArticleUri(article)
          const shareCount = articleUri ? shareCounts?.[articleUri]?.shareCount ?? null : null

          return (
            <React.Fragment key={`articleCard-${index}`}>
              {React.createElement(cardWrapperTag || 'div', {
                className: cardWrapperClass || 'card-wrapper',
                children: (
                  <>
                    {('type' in article &&
                      article?.type === 'adArticleCard' &&
                      'slotName' in article) ||
                    'impressionTrackingUrls' in article ? (
                      <AdArticleCard
                        article={article}
                        slotId={article.slotName}
                        slideIndex={index}
                        sliderCallBack={adSliderCallBack}
                        {...articleCardProps}
                      />
                    ) : (
                      <ArticleCard
                        article={'node' in article ? article?.node ?? {} : {}}
                        commentCount={commentCount}
                        shareCount={shareCount}
                        {...articleCardProps}
                      />
                    )}
                  </>
                )
              })}
            </React.Fragment>
          )
        })}
      </GlideSlider>
    </div>
  )
}
type ArticleSliderContainerProps = {
  sliderSettings?: {
    [key: string]: any
  }
  image?: Record<string, any>
  processAds?: boolean

  removeExcess?: {
    // removeExcess.shouldRemove Feature flag whether should remove excess or not
    shouldRemove?: boolean
    // removeExcess.max If shouldRemove is feature is true, how many articles should we keep
    max?: number
    [key: string]: any
  }
  showArticleExcerpt?: boolean
  articles: (Article | ArticleAd)[]
  cardWrapperTag?: string
  cardWrapperClass?: string //only works if cardWrapperTag tag is provided
  isAllFetched?: (
    fetchedAdInfoRef: MutableRefObject<SliderCallBackProps[]>,
    articleSlidesInfo: ArticleSlidesInfo
  ) => boolean
  getAdArrayIndexByArticleIndex?: (index: number) => number
  articleCardProps?: ArticleCardProps
  glideMountHandle?: (mountedGlide: Properties) => void
  shouldUpdateGlideAfterAllAdFecthed?: boolean
}

export default ArticleSliderContainer
