import { COMPARE_BUCKET_CACHE } from './../../../constants/localStorage'
import { BucketVariant } from '../../../types/compareBucket'
import PubSub, { PubSubStatic } from '../PubSub'
import { NEXT_PUBLIC_COMPARE_BUCKET_VERSION } from '../../../constants/localStorage'
import {
  COMPARE_BUCKET_ITEM_LIMIT,
  NOTIFICATION_CHANNEL_ID
} from '../../../constants/compareBucket'
import { getEnvLocalStorageVersion } from '@grille/utils/functions/get-env-local-storage-version'
import { DriveCache, IDriveCache } from '../DriveCache'

/**
 * @interface CompareBucketEngineStatic
 * @description
 *  - interface for exposing methods to interact with comparebucket engine
 *  - contains initializer, getter, setters and subscriber
 */
export interface CompareBucketEngineStatic {
  getItems: () => BucketVariant[]
  /**
   * - add item to bucket
   * - triggers error banner if failed to add due to maximum reached
   */
  addItem: (item: BucketVariant) => void
  removeItem: (variantUuid: any) => void
  clearBucket: () => void
  /**
   * - subscribe to bucket change, it always passes a new array to callback
   * - channel must be unique for every subscriber, later subscription of the same channel overrides the previous
   * - it does not take in 'NOTIFICATION_CHANNEL_ID' as it is reserved. (note that Exclude<string, 'NOTIFICATION_CHANNEL_ID'> will not work as it resolves as type string)
   */
  subscribe: <T extends string>(
    channel: T & (T extends typeof NOTIFICATION_CHANNEL_ID ? never : string),
    callback: (items: BucketVariant[]) => void
  ) => void
  /**only for notification banner component */
  subscribeToNotification: (callback: (message: string) => void) => void
  unsubscribe: (channel: string) => void
}

/**
 * @class CompareBucketEngine
 * @description
 *  - handles compare bucket item storage, updates, and subscriptions
 *  - singleton class, only initializes once
 *  - methods are exposed via exports method from instance
 *  - client side only as it communicates with local storage
 * @export {Object}  @required contains initializer, getter, setters and subscriber
 */
class CompareBucketEngine {
  private static instance?: CompareBucketEngine

  private static pubSub: PubSubStatic
  private static subscriberChannels: Set<string>
  private static cacheStore: IDriveCache
  private static defaultCacheValue: Cache

  /**short alias of CompareBucketEngine*/
  private readonly engine: typeof CompareBucketEngine = CompareBucketEngine

  static initialize(): void {
    if (typeof window === 'undefined') return undefined

    if (!this.instance) {
      this.instance = new CompareBucketEngine()
    }
  }

  static getCurrentInstance(): CompareBucketEngine | undefined {
    return this.instance
  }

  /**for singleton class, instance should only be accessed from getCurrentInstance method*/
  constructor(
    private readonly currentVersion: string = getEnvLocalStorageVersion(
      NEXT_PUBLIC_COMPARE_BUCKET_VERSION
    )
  ) {
    this.engine.pubSub = new PubSub().export()
    this.engine.subscriberChannels = new Set<string>()
    this.engine.defaultCacheValue = {
      version: currentVersion,
      data: []
    }

    //initialize drive cache with fallback
    this.engine.cacheStore = new DriveCache(this.engine.defaultCacheValue, {
      persist: true,
      cacheKey: COMPARE_BUCKET_CACHE
    })
    const storedVersion: Cache['version'] = this.engine.cacheStore.get('version')
    //reset bucket if landed on url with clear-bucket param set to true (regex is used to handle compare specs url)
    const href: string = window?.location?.href ?? ''

    // reset data to default either if: version mismatch, query string has clear-bucket set to true
    if (
      (this.currentVersion !== storedVersion ||
        href.includes('?clear-bucket=true') ||
        href.includes('&clear-bucket=true')) &&
      this.engine.cacheStore?.reset
    ) {
      this.engine.cacheStore.reset(this.engine.defaultCacheValue)
    }
  }

  private hasReachedMaximum = (): boolean => {
    const cache: Cache = this.engine.cacheStore.get()
    return (cache?.data?.length ?? 0) >= COMPARE_BUCKET_ITEM_LIMIT
  }

  private hasDuplicate = (item: BucketVariant): boolean => {
    const cache: Cache = this.engine.cacheStore.get()
    return (cache?.data ?? []).some((v) => v.uuid === item.uuid)
  }

  private addItem = (item: BucketVariant) => {
    const cache: Cache = this.engine.cacheStore.get()
    const bucketVariants: BucketVariant[] = cache?.data ?? []
    if (!this.hasDuplicate(item)) {
      if (this.hasReachedMaximum()) {
        this.engine.pubSub.publish(
          NOTIFICATION_CHANNEL_ID,
          'You’ve reached your limit, please remove a car before adding another to compare.'
        )
      } else {
        bucketVariants.push(item)
        this.engine.cacheStore.set('data', [...bucketVariants])
        this.publishToAll(bucketVariants)
      }
    }
  }

  private removeItem = (variantUuid: string) => {
    const data = this.engine.cacheStore.get('data')
    const bucketVariants: BucketVariant[] = (data ?? []).filter(
      (v: BucketVariant) => v.uuid !== variantUuid
    )
    this.engine.cacheStore.set('data', [...bucketVariants])
    this.publishToAll(bucketVariants)
  }

  private clearBucket = () => {
    this.engine.cacheStore.set('data', [])
    this.publishToAll([])
  }

  /**
   * publish to all channels that subscribers subscribed to
   */
  private publishToAll = (newBucket: BucketVariant[]) => {
    this.engine.subscriberChannels.forEach((channel: string) => {
      //ensures the published bucket has a new reference, for triggering state update
      this.engine.pubSub.publish(channel, [...newBucket])
    })
  }

  private subscribe = (channel: string, callback: (items: BucketVariant[]) => void) => {
    // overrides the previous subscription callback if duplicate
    this.engine.subscriberChannels.add(channel)
    this.engine.pubSub.subscribe(channel, callback)
  }

  private unsubscribe = (channel: string) => {
    this.engine.subscriberChannels.delete(channel)
  }

  private getItems = () => {
    return this.engine.cacheStore.get('data') ?? []
  }

  private subscribeToNotification = (callback: (message: string) => void) => {
    this.engine.pubSub.subscribe(NOTIFICATION_CHANNEL_ID, callback)
  }

  // expose methods
  public export = (): CompareBucketEngineStatic => ({
    getItems: this.getItems,
    addItem: this.addItem,
    removeItem: this.removeItem,
    clearBucket: this.clearBucket,
    subscribe: this.subscribe,
    unsubscribe: this.unsubscribe,
    subscribeToNotification: this.subscribeToNotification
  })
}

export default CompareBucketEngine

type Cache = {
  version: string
  data: BucketVariant[]
}
