import { MutableRefObject } from 'react'
import { SliderCallBackProps } from '../../../showrooms/showroom-articles-slider/types'
import { ArticleSlidesInfo } from './types'

export const defaultIsAllFeched = (
  fetchedAdInfoRef: MutableRefObject<SliderCallBackProps[]>,
  articleSlidesInfo: ArticleSlidesInfo
): boolean => {
  return (
    (fetchedAdInfoRef?.current?.length === 2 && articleSlidesInfo.articles?.length > 3) ||
    (fetchedAdInfoRef?.current?.length === 1 && articleSlidesInfo.articles?.length <= 3)
  )
}

export const defaultGetAdArrayIndexByArticleIndex = (ArticleIndex: number): number => {
  // when it is index 1 which is the 2nd article card, it is always an ad, which is index 0 in ad array
  return ArticleIndex == 1 ? 0 : 1
}
