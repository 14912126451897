/**
 * External dependencies
 */
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './copyright.module.scss'

/**
 * CopyRightText component.
 */
const CopyRightText = ({ className = '' }) => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()

  return (
    <div className={className}>
      <p className={cx(styles['drive-footer__copyrights'])}>
        Copyright Drive.com.au {year}
        <span className={cx(styles['drive-footer__copyrights__abn'])}>ABN: 84 116 608 158</span>
      </p>
    </div>
  )
}

CopyRightText.protoTypes = {
  className: PropTypes.string
}

export default CopyRightText
