import React, { FunctionComponent, useEffect, useState } from 'react'
import { Status, GlideSubscriber, GlideOptions, MountedGlide } from '../index'
import cx from 'classnames'
import styles from './navigation.module.scss'

/**
 * GlideNavArrows Component.
 * @type {Specific Component}
 * @param {Object} classNames @optional general classnames for component parts
 * @returns {JSX.Element}
 */
export const GlideNavArrows: FunctionComponent<GlideNavArrowsProps> = (props) => {
  const { classNames, status, subscriber, glideOptions, mountedGlide } = props || {}

  const { eventId, subscribe } = subscriber

  const [navStatus, setNavStatus] = useState(status)
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    subscribe(eventId, (newStatus) => {
      setCurrentIndex(mountedGlide?.index ?? 0)
      setNavStatus(newStatus)
    })
  }, [eventId, subscribe, mountedGlide])

  const hasPrev: boolean | undefined = navStatus.navArrows.prev
  const hasNext: boolean | undefined = navStatus.navArrows.next

  const isArrowInactive = (shouldCheck: boolean) => {
    return shouldCheck !== undefined ? !shouldCheck : false
  }

  let shouldForceActive = false

  if (glideOptions?.type === 'carousel') {
    shouldForceActive = true
  }

  return (
    <div className={cx([styles['glide__arrows'], classNames?.wrapper])} data-glide-el='controls'>
      <button
        className={cx([styles['glide__arrow'], styles['glide__arrow--left'], classNames?.prev], {
          [styles['glide__arrow--inactive']]: shouldForceActive ? false : isArrowInactive(hasPrev),
          [styles['glide__arrow--force-active']]: shouldForceActive
        })}
        data-glide-dir={
          glideOptions?.ignorePerViewInControls ? (hasPrev ? `=${currentIndex - 1}` : '<') : '|<'
        }
      >
        Previous
      </button>
      <button
        className={cx([styles['glide__arrow'], styles['glide__arrow--right'], classNames?.next], {
          [styles['glide__arrow--inactive']]: shouldForceActive ? false : isArrowInactive(hasNext),
          [styles['glide__arrow--force-active']]: shouldForceActive
        })}
        data-glide-dir={
          glideOptions?.ignorePerViewInControls ? (hasNext ? `=${currentIndex + 1}` : '>') : '|>'
        }
      >
        Next
      </button>
    </div>
  )
}

export type GlideNavArrowsProps = {
  classNames?: {
    wrapper?: string
    prev?: string
    next?: string
  }
  status: Status
  subscriber: GlideSubscriber
  glideOptions?: GlideOptions
  mountedGlide: MountedGlide | null
}
