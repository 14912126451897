import { ButtonHTMLAttributes } from 'react'

export * from './enquiry'
export * from './modal-enquiry'
export * from './subscribe-newsletter'
export * from './cars-for-sale/contact-seller'
export * from './cars-for-sale/enquiry'
export * from './sell-my-car/sell-my-car-customer-details'
export * from './sell-my-car/whats-my-car-worth'

export type FormField = {
  labelClassName?: string
  placeholder?: string
  name: string
  label?: string
  type?: string
  rules?: { [key: string]: any }[] | { [key: string]: any }
  options?: { [key: string]: any }[]
  checkedOptions?: { [key: string]: any }[]
  heading?: string
  hidden?: boolean
  defaultHidden?: boolean
  defaultOption?: { [key: string]: any } | string
  className?: string
  showError?: boolean
  header?: string
  [key: string]: any
}

export type FormButton = {
  className?: string
  color?: string
  text?: string
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  secondary?: boolean
  specialType?: string
}

export type Form = {
  fields: FormField[]
  buttons: FormButton[]
  labels?: { [key: string]: any }
}
