import { CFS_CUSTOM_FACADE_BASE_PATH } from '@grille/components/cars-for-sale/listings/constants'
import {
  Filters,
  HashFilters,
  MultiSelect,
  SearchConfig,
  SlugFilters
} from '@grille/types/listings'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import {
  CFS_SEARCH_BASE_PATH,
  DEFAULT_CFS_HASH_FILTERS,
  DEFAULT_CFS_SLUG_FILTERS
} from '../constants'

const getFilterUrlHashValueStr = (
  key: string,
  value: MultiSelect<string | number> | string | number | null
): string => {
  let fragment = ''

  if (isEmpty(value)) {
    return fragment
  }
  if (isArray(value) && value.length > 0) {
    const arrayClone = [...value]
    // remove the first element if already it belongs to slug
    if (key in DEFAULT_CFS_SLUG_FILTERS && arrayClone.length) {
      arrayClone.shift()
    }
    fragment = isEmpty(arrayClone) ? '' : `${JSON.stringify(arrayClone)}`
  } else {
    fragment = `${value}`
  }
  return fragment
}

const getHashFragmentsStr = (data: Record<string, any>): string => {
  const hashFragment = Object.entries(data)
    .reduce((str, [key, value]) => {
      const valueStr: string = getFilterUrlHashValueStr(key, value)
      // append only if not empty
      if (!isEmpty(valueStr)) {
        return `${str}&${key}=${valueStr}`
      }
      return str
    }, '')
    .replace(/^&/, '')

  if (isEmpty(hashFragment)) {
    return ''
  }
  return `#${hashFragment}`
}

const getSlugPathFromFilters = (
  { listingType, state, region, model, make }: SlugFilters,
  pageNo?: number
): string => {
  let filteredSlugs = [listingType?.[0], state ?? 'all', region ?? 'all', make, model].filter(
    (v) => !isEmpty(v)
  )

  // remove slugs if they are the last single all or consecutive alls, until a non-all slug is found
  for (let i = filteredSlugs.length - 1; i >= 0; i--) {
    if (filteredSlugs[i] === 'all') {
      filteredSlugs[i] = null
    } else {
      break
    }
  }
  filteredSlugs = filteredSlugs.filter((v) => !isEmpty(v))
  if (pageNo !== undefined && pageNo > 1) {
    filteredSlugs = [...filteredSlugs, 'page', `${pageNo}`]
  }

  let slugPath = filteredSlugs.join('/')

  // Return empty slug path
  if (isEmpty(slugPath)) {
    return ''
  }

  return `${slugPath}/`
}

export const generateCFSUrl = (
  { filters, pageNo, sortBy }: SearchConfig,
  otherData: { [key: string]: any } = {}
): { url: string; slugPath: string; hashFragments: string } => {
  // order listing type
  const listingType = filters.listingType ?? []
  const customOrder: Record<string, number> = { new: 1, demo: 2, used: 3 }
  listingType.sort((a, b) => {
    const v1 = customOrder?.[a] ?? 0
    const v2 = customOrder?.[b] ?? 0
    return v1 - v2
  })
  const slugFilters: SlugFilters = Object.keys(DEFAULT_CFS_SLUG_FILTERS).reduce(
    (object: SlugFilters, key: string) => {
      //@ts-ignore slugFilters is partial of filters
      object[key] = filters[key]
      return object
    },
    { ...DEFAULT_CFS_SLUG_FILTERS }
  )
  const hashFilters: HashFilters = Object.keys(DEFAULT_CFS_HASH_FILTERS).reduce(
    (object: HashFilters, key: string) => {
      //@ts-ignore slugFilters is partial of filters
      object[key] = filters[key]
      return object
    },
    { ...DEFAULT_CFS_HASH_FILTERS }
  )

  const slugPath: string = getSlugPathFromFilters(slugFilters, pageNo)
  const hashFragments: string = getHashFragmentsStr({ ...hashFilters, sortBy, ...otherData })

  return {
    url: `${CFS_SEARCH_BASE_PATH}${slugPath}${hashFragments}`,
    slugPath,
    hashFragments
  }
}

export const generateCFSSearchFacadeUrl = (facade: string, pageNo: number): string => {
  const slugs: string[] = [facade]
  if (pageNo > 1) {
    slugs.push('page')
    slugs.push(`${pageNo}`)
  }
  const slugPath: string = slugs.join('/')
  return `${CFS_CUSTOM_FACADE_BASE_PATH}${slugPath}/`
}

/**
 * triggers query update and refetch
 * @param filters
 * @param sortBy
 */
// TODO: need to handle noscroll
export const updateCFSUrl = (
  filters: Filters,
  sortBy: string,
  pageNo?: number,
  isTest?: boolean
) => {
  const { url } = generateCFSUrl({ filters, sortBy, pageNo })
  // jest does not have window object to perform pushState, so change href directly for testing
  if (isTest) {
    window.location.href = url
  } else {
    window.history.pushState(null, '', url)
    window.dispatchEvent(new PopStateEvent('popstate', { state: null }))
  }
}
