import { getRuntimeEnvKeys } from '@grille/utils/functions/get-runtime-env'

const envKeys = [
  'NEXT_PUBLIC_YOUTUBE_URL',
  'NEXT_PUBLIC_FACEBOOK_URL',
  'NEXT_PUBLIC_TWITTER_URL',
  'NEXT_PUBLIC_INSTAGRAM_URL',
  'NEXT_PUBLIC_LINKEDIN_URL',
  'NEXT_PUBLIC_SPOTIFY_URL'
]

export const FOLLOW_ICON_URLS = getRuntimeEnvKeys(envKeys)
