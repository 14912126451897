import { useState, useEffect } from 'react'

const useIsTabletScreen = () => {
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    const isDesk = typeof window !== 'undefined' && window?.innerWidth > 1023
    setIsTablet(!isDesk)
  }, [])

  return { isTablet }
}

export default useIsTabletScreen
