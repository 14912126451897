import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'

const hasSpecificTag = (tags: Tag, tagName = '') => {
  if (!isEmpty(tags) && !isEmpty(tagName)) {
    return findIndex(tags, { name: tagName }) !== -1 ? true : false
  }

  return false
}

type Tag = Array<{
  name: string
}>

export default hasSpecificTag
