import cx from 'classnames'
import { useContext } from 'react'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import Seo from '../../seo'
import Head from 'next/head'
import Breadcrumb from './../../breadcrumbs'
import { GTMComponent } from '../../../utils/functions/gtm'
import { PageContext } from '../../../utils/contexts/pageContext'
import { useTransitionState } from '../../common/ad-units/transitions'
import { usePathname } from 'next/navigation'
import styles from './pageWrapper.module.scss'

const PageWrapper = ({
  children,
  seoData,
  className,
  pageName,
  breadCrumbs,
  pubSub,
  hideBreadCrumbsLastElem = false,
  hideBreadcrumb = false,
  hasCustomContainerClass = false,
  customPageTitle = '',
  customOgTitle = '',
  customSeoMeta = {},
  customPublishedTime = '',
  breadCrumbClassName = '',
  breadCrumbContainerClassName = '',
  preloadImageData = {},
  metaTags = {
    rssFeed: []
  },
  isArchivePage = false,
  blackLabel = null,
  breadCrumbIconColor = null,
  hubLabel = null,
  linkedGuides = null,
  isAppRoute = false
}) => {
  const pathname = usePathname()
  const targeting = metaTags?.targeting ? metaTags.targeting : {}
  const dataLayer = metaTags?.dataLayer ? metaTags.dataLayer : []
  const { coorelator } = useTransitionState()
  const { pageStore } = useContext(PageContext) || {}
  const hasPageTakeoverAd = pageStore?.gam_oop_pos0?.img ?? false
  const isShowroomPage = pathname?.indexOf('/showrooms') !== -1

  return (
    <>
      {isAppRoute ? null : (
        <>
          <Head>
            {/* Need to dangerously set for ssr pagesource so it wont render with quotes in pageview source
             * we can safely output object as string instead of doing sanitize
             */}
            {seoData?.seo?.schemaDetails && (
              <script
                type='application/ld+json'
                className='yoast-schema-graph'
                key='yoastSchema'
                dangerouslySetInnerHTML={{ __html: `${seoData.seo.schemaDetails}` }}
              />
            )}
            {preloadImageData && Array.isArray(preloadImageData) ? (
              preloadImageData.length &&
              preloadImageData.map((imageData, index) => {
                return (
                  <link
                    rel='preload'
                    as='image'
                    key={`preloadImageData-${index}`}
                    fetchPriority='high'
                    href={imageData?.preloadImageData?.src}
                    imageSrcSet={imageData?.preloadImageData?.srcSet}
                    imageSizes={imageData?.preloadImageData?.sizes}
                  />
                )
              })
            ) : !isEmpty(preloadImageData) ? (
              <link
                rel='preload'
                as='image'
                fetchPriority='high'
                href={preloadImageData?.src}
                imageSrcSet={preloadImageData?.srcSet}
                imageSizes={preloadImageData?.sizes}
              />
            ) : null}
            {!isEmpty(metaTags?.rssFeed) && isArray(metaTags?.rssFeed)
              ? metaTags?.rssFeed.map((feedUrl, index) => {
                  return (
                    <link
                      rel='alternate'
                      type='application/rss+xml'
                      title='Drive.com.au Feed'
                      href={feedUrl}
                      key={index}
                    />
                  )
                })
              : null}
          </Head>

          <Seo
            seo={seoData?.seo}
            uri={seoData?.uri}
            customPageTitle={customPageTitle}
            customSeoMeta={customSeoMeta}
            pageName={pageName}
            yoastSeo={seoData?.yoastSeo}
          />
        </>
      )}
      <GTMComponent dataLayer={dataLayer} targeting={targeting} coorelator={coorelator} />
      <div
        className={cx(
          { [cx(styles['drive-page-wrapper__container'])]: !hasCustomContainerClass },
          { [cx(styles['drive-page-wrapper__archive'])]: isArchivePage },
          { [cx(styles['drive-page-wrapper__take-over'])]: hasPageTakeoverAd && isShowroomPage },
          className
        )}
      >
        {!hideBreadcrumb ? (
          <div
            className={cx(
              {
                [cx(styles['drive-page-wrapper__breadcrumbs-label-wrapper'])]: blackLabel
              },
              breadCrumbContainerClassName
            )}
          >
            <Breadcrumb
              data={breadCrumbs ?? seoData?.seo?.breadcrumbs ?? seoData?.breadcrumbs}
              hideLast={hideBreadCrumbsLastElem}
              className={cx(styles['drive-page-wrapper__breadcrumbs'], breadCrumbClassName)}
              iconColor={breadCrumbIconColor}
              pubSub={pubSub}
            />
            {blackLabel && (
              <div className={cx(styles['drive-page-wrapper__name-container'])}>
                <span className={cx(styles['drive-page-wrapper__name'])}>{blackLabel}</span>
              </div>
            )}
          </div>
        ) : null}
        {children}
      </div>
    </>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  seoData: PropTypes.shape({
    seo: PropTypes.object,
    uri: PropTypes.string,
    yoastSeo: PropTypes.object
  }),
  hideBreadCrumbsLastElem: PropTypes.bool,
  hideBreadcrumb: PropTypes.bool,
  pageNo: PropTypes.string,
  className: PropTypes.string,
  hasCustomContainerClass: PropTypes.bool,
  breadCrumbClassName: PropTypes.string,
  breadCrumbContainerClassName: PropTypes.string,
  preloadImageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  metaTags: PropTypes.object,
  isArchivePage: PropTypes.bool,
  customOgTitle: PropTypes.string,
  customMetaDesc: PropTypes.string,
  customImageMeta: PropTypes.object,
  customPublishedTime: PropTypes.string,
  pageName: PropTypes.string,
  customPageTitle: PropTypes.string,
  customSeoMeta: PropTypes.object,
  breadCrumbs: PropTypes.array,
  breadCrumbIconColor: PropTypes.string,
  pubSub: PropTypes.object,
  linkedGuides: PropTypes.array
}

export default PageWrapper
