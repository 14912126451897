import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getFormattedDate } from '../../../utils/functions/date'
import { doesHeroImageExist, sanitize } from '../../../utils/functions/miscellaneous'
import { getAdUnit } from '../ad-units/adTaglessFeature'
import ArticleLoadingCard from './article-loading-card'
import MainThumbnail from './main-thumbnail'
import styles from './ad-article-card.module.scss'
import cx from 'classnames'

import {
  fetchCommentCount,
  getThreadIdentifier
} from '../../../utils/functions/social-counts/comments'
import { fetchShareCount, getArticleUri } from '../../../utils/functions/social-counts/shares'

/**
 * Ad ArticleCard Component.
 *
 * @param {Object}  article    Post data.
 * @param {Boolean} compact    Compact size card.
 *
 * @returns {JSX.Element}
 */
const AdArticleCard = (adArticleProps) => {
  const {
    slotId,
    border,
    rateOutOf,
    showRate,
    sliderCallBack,
    slideIndex,
    article: slide,
    commentIconProps,
    shareCountIconProps,
    showArticleExcerpt,
    showCircleRating,
    imageSizeName
  } = adArticleProps
  const [articleData, setArticleData] = useState(null)

  useEffect(() => {
    const fetchAdUnit = async () => {
      const adResponse = (await getAdUnit({ slot: slotId })) || {}

      const data = adResponse?.image ? adResponse : adResponse.data
      if (!isEmpty(data)) {
        sliderCallBack && sliderCallBack({ adStatus: true, data, slideIndex })
      } else {
        sliderCallBack && sliderCallBack({ adStatus: false, data: null })
      }
    }

    if (slide?.isAdFetched === undefined) {
      fetchAdUnit()
    } else {
      setArticleData({ article: { ...slide?.node }, fetched: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide])

  const threadIdentifier = getThreadIdentifier(articleData?.article)
  const articleUri = getArticleUri(articleData?.article)

  const [commentCount, setCommentCount] = useState(0)
  useEffect(() => {
    const fetchAndShow = async () => {
      const count = await fetchCommentCount(threadIdentifier)
      setCommentCount(count)
    }
    threadIdentifier?.length > 0 && fetchAndShow(threadIdentifier)
  }, [threadIdentifier])

  const [shareCount, setShareCount] = useState(0)
  useEffect(() => {
    let mounted = true
    const fetchAndShow = async () => {
      const count = await fetchShareCount(articleUri)
      mounted && setShareCount(count)
    }
    articleUri?.length > 0 && fetchAndShow()
    return () => {
      mounted = false
    }
  }, [articleUri])

  if (!articleData?.article && !isEmpty(articleData?.article)) {
    if (articleData?.fetched) {
      return null
    } else {
      return <ArticleLoadingCard />
    }
  }

  if (isEmpty(articleData)) {
    return <ArticleLoadingCard />
  }

  const article = articleData?.article

  let { categories, date, title, author = {}, uri, singleReview } = article || {}
  const hero = doesHeroImageExist(singleReview?.reviewsMeta?.hero)
    ? singleReview?.reviewsMeta?.hero
    : article?.image?.node || article?.thumbnail?.node || article?.image
  let isExternalLink = false

  // Add ad link tracking URLs if present for the article.
  if (article?.clickthroughUrl) {
    article.uri = article.clickthroughUrl
    uri = article.clickthroughUrl
    isExternalLink = true
  }

  // Add ad link tracking url if image article is present
  if (article?.image?.isExternal) {
    uri = article?.url
    isExternalLink = true
  }

  const sanitizedTitle = sanitize(title)
  const formattedDate = getFormattedDate(date, 'd MMM yyyy')

  let ratings = ''
  if (!isEmpty(article?.carVariantRatings?.carVariantRatings)) {
    ratings = article.carVariantRatings.carVariantRatings[0]?.ratings
  }

  if (!isEmpty(article?.ownerCarVariantRatings?.ownerCarVariantRatings)) {
    ratings = article.ownerCarVariantRatings.ownerCarVariantRatings[0]?.ratings
  }

  return (
    <div className={cx(styles['d-ad-article-card'], 'ad-article-card')}>
      <MainThumbnail
        uri={uri}
        isExternalLink={isExternalLink}
        image={hero}
        border={border}
        article={article}
        rateOutOf={rateOutOf}
        author={author?.node ? author?.node : null}
        title={sanitizedTitle}
        categories={categories}
        ratings={showRate && ratings}
        showArticleExcerpt={showArticleExcerpt}
        commentIconProps={commentIconProps}
        shareCountIconProps={shareCountIconProps}
        showCircleRating={showCircleRating}
        isAddArticleCard={true}
        date={formattedDate}
        imageSizeName={imageSizeName}
        commentCount={commentCount}
        shareCount={shareCount}
      />

      {!isEmpty(article?.impressionTrackingUrls) &&
        article.impressionTrackingUrls.map((trackingUrl, index) => {
          return (
            <img
              src={trackingUrl}
              width='1'
              height='1'
              className='gam-impression-tracker'
              key={index}
              data-component='articles-slider'
            />
          )
        })}
    </div>
  )
}

AdArticleCard.propTypes = {
  article: PropTypes.any,
  slideIndex: PropTypes.number,
  sliderCallBack: PropTypes.func,
  border: PropTypes.bool,
  showRate: PropTypes.bool,
  rateOutOf: PropTypes.string,
  slotId: PropTypes.string,
  commentIconProps: PropTypes.object,
  shareCountIconProps: PropTypes.object,
  showArticleExcerpt: PropTypes.bool,
  showCircleRating: PropTypes.bool,
  imageSizeName: PropTypes.string,
  commentCount: PropTypes.number
}

AdArticleCard.defaultProps = {
  rateOutOf: '',
  border: true,
  showRate: true
}

export default AdArticleCard
