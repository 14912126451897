import isEmpty from 'lodash/isEmpty'
import { priceFormat } from './miscellaneous'
/**
 * No. of posts to show on page no. 1
 * 18
 */
export const PER_PAGE_FIRST = 30
export const DRIVE_TV_PER_PAGE_FIRST = 19
export const DRIVE_TV_ARTICLES_LOAD_MORE = 18
export const DEALER_NEWS_ARTICLES_FIRST = 28

// TODO: Change to 30 Listings after implementing ghost listings
export const PER_PAGE_FIRST_LISTING = 30
export const PER_PAGE_GHOST_LISTINGS = 6

export const NO_OF_PAGINATION_LINKS = 8

/**
 * No. of posts to show on every page after page no 1.
 * 48
 */
export const PER_PAGE_REST = 30

export const getPageOffset = (pageNo) => {
  let offset = 0

  if (pageNo === 1 || !pageNo) offset = 0
  else if (pageNo === 2) offset = PER_PAGE_FIRST
  else {
    offset = PER_PAGE_FIRST + (Number(pageNo) - 2) * PER_PAGE_REST
  }
  return offset
}

export const totalPagesCount = (totalPostsCount) => {
  return Math.ceil((totalPostsCount - PER_PAGE_FIRST) / PER_PAGE_REST + 1)
}

export const redirectUserBySlug = (pageNo, req, res, location) => {
  if (pageNo === 1) {
    if (req) {
      res.writeHead(302, { Location: location })
      res.end()
    }
  }
}

/**
 * Creates Ghost block Pagination Link Arrays
 * @param {int} currentPage Current page no.
 * @param {int} totalPages Count of total no of pages.
 * @returns {Object} { activeBlocks : [], ghostBlocks : [] }
 */
export const createGhostBlockPaginationLinks = (currentPage, totalPages) => {
  const blockSize = 10
  let activeBlocks = []
  let ghostBlocks = []

  if (currentPage > totalPages) {
    activeBlocks = [totalPages]
    return {
      activeBlocks,
      ghostBlocks
    }
  }

  // Calculate First Page of Block
  let activeBlockFirstPage = Math.floor(currentPage / blockSize) * blockSize + 1

  //  If First page is greater then current, set first page to previous block
  if (activeBlockFirstPage > currentPage) {
    activeBlockFirstPage = activeBlockFirstPage - blockSize
  }

  //  If First  greater then total pages, set first page from last block
  if (activeBlockFirstPage === totalPages) {
    activeBlockFirstPage = totalPages - blockSize
  }

  // Show Active block only if Starting page is less then total pages
  if (activeBlockFirstPage > 0 && activeBlockFirstPage < totalPages) {
    // Next 10 Pages After First page
    let activeBlockLastPage = activeBlockFirstPage + blockSize - 1

    // If Last Page is Greater then total pages,set last page to last page from total pages
    if (activeBlockLastPage > totalPages) {
      activeBlockLastPage = totalPages
    }

    // Create List of Page Numbers
    activeBlocks = Array(activeBlockLastPage - activeBlockFirstPage + 1)
      .fill()
      .map((_, index) => activeBlockFirstPage + index)
  }

  const ghostBlockFirstPage = currentPage * blockSize + 1

  // Show Ghost block only if Starting page is less then total pages
  if (ghostBlockFirstPage > 0 && ghostBlockFirstPage < totalPages) {
    // Get Next 10 Pages
    let ghostBlockLastPage = ghostBlockFirstPage + blockSize - 1

    // If Last page is Greater then Count, set Last page to last page from total pages
    if (ghostBlockLastPage > totalPages) {
      ghostBlockLastPage = totalPages
    }

    ghostBlocks = Array(ghostBlockLastPage - ghostBlockFirstPage + 1)
      .fill()
      .map((_, index) => ghostBlockFirstPage + index)
  }
  return { activeBlocks, ghostBlocks }
}

/**
 * Returns formatted string eg. " Showing 1-20 of 1880"
 * @param {Number} currentPage Current page number
 * @param {Number} total No of total articels
 */
export const getShowingArticlesMessage = (
  currentPage,
  total,
  perPage,
  onCurrentPage,
  filtersApplied = false
) => {
  if (!currentPage || !total || !perPage) {
    return null
  }

  const pageNo = Number(currentPage) - 1

  const from = pageNo === 0 ? 1 : pageNo * Number(perPage)

  let till = from === 1 ? Number(onCurrentPage) : from + Number(onCurrentPage)

  if (till > total || till + 1 === total) {
    till = total
  }

  return `Showing ${from} – ${till} ${!filtersApplied ? `of ${priceFormat(total)}` : ''}`
}

/**
 * Returns seo data with useCanonical as true and adds /page/[pageNo] to the canonical url
 * if pageNo is greater than zero
 *
 * @param {Object} seo Seo Data
 * @param {Int} pageNo pageNo
 */
export const getSeoWithPaginationCanonical = (seo = {}, pageNo = 0) => {
  if (typeof seo?.canonical === 'string' && !isEmpty(seo.canonical.trim()) && pageNo > 0) {
    return {
      ...seo,
      useCanonical: true,
      canonical: `${seo.canonical}${seo.canonical.endsWith('/') ? '' : '/'}page/${pageNo}/`
    }
  }

  return seo
}
