import { FunctionComponent } from 'react'
const AppRouterSchema: FunctionComponent<AppRouterSchemaProps> = ({
  schema,
  classNames = 'yoast-schema-graph'
}) => {
  return (
    <>
      <script
        id='yoastSchema'
        type='application/ld+json'
        className={classNames}
        key='yoastSchema'
        dangerouslySetInnerHTML={{ __html: `${schema}` }}
      />
    </>
  )
}

export type AppRouterSchemaProps = {
  schema: string
  classNames?: string
}

export default AppRouterSchema
