import PropTypes from 'prop-types'
import PageLink from '../../link'

const AnnexCardLink = ({ href, children, className = '', isExternal = false }) => {
  return (
    <PageLink href={href} isExternal={isExternal} className={className}>
      <a className={className}>{children}</a>
    </PageLink>
  )
}

AnnexCardLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string
}

export default AnnexCardLink
