import { EnquiryVariant } from '../../../types/enquiry'

const getEnquiryVehicle = (vehicle: {
  [key: string]: any
  variant: string
  vehicleKey: string
  attributes?: {
    longDescription?: string
  }[]
}): EnquiryVariant => {
  const { vehicleKey = '' } = vehicle
  const updatedVariants = {
    ...vehicle,
    title: vehicle?.attributes?.[0]?.longDescription ?? '',
    variant: vehicle?.attributes?.[0]?.longDescription ?? '',
    make: { ...(vehicle?.make ?? {}), title: vehicle?.make?.description ?? '' },
    model: vehicle?.model?.description,
    makeObj: {
      ...(vehicle?.make ?? {})
    },
    modelObj: {
      ...(vehicle?.model ?? {})
    },
    vehicleKey,
    vehicleableUuid: vehicle?.vehicleable?.uuid ?? ''
  }

  return updatedVariants
}

export default getEnquiryVehicle
