import isEmpty from 'lodash/isEmpty'
import { observeRef } from './observeRef'

/**
 * This function is to detect if an element in a form of ref has been intersected by browser,
 * by giving an option of root: null, meaning it will be relative to browser viewport
 * see: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 *
 * This will call two callbacks: stickyCallback and aboveElementCallback
 * @param  {Object} props
 */
export const mobileInreadsIntersection = (refs, options) => {
  if (!Array.isArray(refs) && isEmpty(refs)) {
    return
  }

  const filteredRefs = refs.filter((ref) => !isEmpty(ref.ref))

  filteredRefs.forEach((ref) => {
    const observer = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // intersectionRatio === 1 means target is visible
        if (entry.isIntersecting || entry.intersectionRatio === 1) {
          ref.stickyCallback()
        }
      })
    }, options)
    observer.observe(ref.ref)

    observeRef(ref.ref, ({ scrollIsAboveTarget, scrollIsBelowTarget }) => {
      if (scrollIsAboveTarget) {
        ref.aboveElementCallback()
      }
      if (scrollIsBelowTarget && ref.belowElementCallback) {
        ref.belowElementCallback()
      }
    })
  })
}
