import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import ErrorBag from '../errorBag/ErrorBag'
import styles from './checkbox.module.scss'
import { isTestEnv } from '../../../../utils/functions/isTestEnv'
const isTest = isTestEnv()

/**
 * Checkbox Component.
 *
 * @param {Object} { name, checkedOptions, className, validation, onChange, type, label } as props
 * @returns {JSX.Element}
 */
const Checkbox = (props) => {
  const {
    className = '',
    labelClassName = 'mt-1',
    itemsHeightControlClassNames = '',
    onChange = () => null,
    dataCy = ''
  } = props

  const wasChanged = useRef(false)
  const handleCheckedOptionsChange = useCallback(() => {
    const options = {}
    if (props?.options && props?.options?.length) {
      props.options.map((option) => {
        options[option.value] =
          !!props?.checkedOptions && props.checkedOptions.includes(option.value)
      })
    }
    return options
  }, [props.checkedOptions, props.options])

  const [errorMessages, setErrorMessages] = useState([])
  useEffect(() => {
    props?.errorMessage && setErrorMessages(props?.errorMessage)
  }, [props.errorMessage])

  const [localCheckedOptions, setLocalCheckedOptions] = useState(handleCheckedOptionsChange())

  useEffect(() => {
    if (props?.reset) {
      setLocalCheckedOptions({})
    }
  }, [props.reset])

  const handleOnchange = (event) => {
    const option = event.target.value
    const value = !localCheckedOptions[option]
    setLocalCheckedOptions((localCheckedOptions) => ({
      ...localCheckedOptions,
      [option]: value
    }))

    wasChanged.current = true

    if (props?.validation) {
      const messages = props.validation(value.toString(), option) || []
      setErrorMessages((errorMessages) => ({ ...errorMessages, ...messages[0] }))
    }
    onChange && onChange(event)
  }

  useEffect(() => {
    if (wasChanged.current) {
      onChange(localCheckedOptions)
    }
    wasChanged.current = false
  }, [localCheckedOptions, onChange])

  return (
    // Pass the itemsHeightControlClassNames to control the height and overflow of the container example 'max-h-96 overflow-auto'
    <div className={cx(className, itemsHeightControlClassNames)}>
      {props?.header && <h3 className={cx(props?.headerClassName ?? '')}>{props?.header ?? ''}</h3>}
      {props?.options &&
        props?.options?.length &&
        props?.options?.map((option, index) => {
          /** For Terms and conditions checkbox, we want to change the color of the label instead of showing error message */
          let hasTcError = false
          if (option?.value === 'tc') {
            hasTcError = errorMessages?.tc
          }

          return (
            <span
              onFocusCapture={() => {
                props?.onFocus && props.onFocus()
              }}
              tabIndex={0}
              key={index}
              className={cx(labelClassName, {
                [cx(styles['drive-form-checkbox__label-page'])]: props?.isPage
              })}
            >
              <div
                className={cx('seperator-full', styles['drive-form-checkbox__separator'], {
                  hidden: !props?.seperatorIndex || props?.seperatorIndex !== index,
                  inline: props?.seperatorIndex && !props?.seperatorIndex === index
                })}
              />
              {/* FIX: Global CSS refactor */}
              <label className={cx('label', labelClassName)}>
                <span className={cx(styles['drive-form-checkbox__input-wrapper'])}>
                  <input
                    type='checkbox'
                    className='hidden'
                    name={option?.name}
                    value={option?.value}
                    checked={!!localCheckedOptions[option?.value]}
                    onChange={(e) => handleOnchange(e)}
                    data-testid={isTest ? `${dataCy}-${option?.value}` : undefined}
                    data-cy={`${dataCy}-${option?.value}`}
                  />
                  <span
                    className={cx('checkbox', {
                      'checkbox-error':
                        hasTcError || (props?.hideCheckBoxErrors && props?.errorMessage)
                    })}
                  />
                  <span
                    className={cx(
                      {
                        [cx(styles['drive-form-checkbox__tc-error'])]:
                          hasTcError || (props?.hideCheckBoxErrors && props?.errorMessage)
                      },
                      'tc'
                    )}
                    dangerouslySetInnerHTML={{ __html: option?.label }}
                  />
                </span>
                {/* Hide error messages for Terms and conditions checkbox as it is handled in the label */}
                {option?.value === 'tc' || props?.hideCheckBoxErrors ? null : (
                  <ErrorBag
                    messages={
                      errorMessages?.[option?.value]
                        ? errorMessages?.[option?.value]
                        : Array.isArray(errorMessages)
                        ? errorMessages
                        : null
                    }
                  />
                )}
              </label>
            </span>
          )
        })}
    </div>
  )
}

Checkbox.propTypes = {
  reset: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  validation: PropTypes.func,
  className: PropTypes.string,
  itemsHeightControlClassNames: PropTypes.string,
  labelClassName: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checkedOptions: PropTypes.array,
  dataCy: PropTypes.string,
  header: PropTypes.string,
  headerClassName: PropTypes.string
}

export default Checkbox
