import { PriceDropInfo, PriceChange } from '@grille/types/singleListing'
import { isValidPrice } from '../miscellaneous'
import isEmpty from 'lodash/isEmpty'

/**
 * Determines whether there has been a price drop and identifies the original price value.
 * Considers IGC price as current price if no EGC price is not valid
 * Original price is most recent higher price from current price
 *
 * @param priceHistory - An array of price changes, ordered from most recent to oldest.
 * @param usePriceEgc - Flag indicating whether to use EGC price
 * @returns {PriceDropInfo}
 */
export const getPriceDropInfo = (
  priceHistory: PriceChange[] = [],
  usePriceEgc?: boolean
): PriceDropInfo => {
  const noPriceDrop = {
    isPriceDrop: false,
    originalPriceValue: null
  }
  if (isEmpty(priceHistory)) {
    return noPriceDrop
  }

  const currentPrice = priceHistory?.[0]?.newValues
  const priceType: 'price_egc' | 'price_igc' =
    usePriceEgc && isValidPrice(currentPrice?.['price_egc']) ? 'price_egc' : 'price_igc'
  const currentPriceValue: number | null = currentPrice?.[priceType] ?? null

  // if there is no valid current price, early return.
  if (!isValidPrice(currentPriceValue)) {
    return noPriceDrop
  }

  // find most recent price value which is higher than current price value
  let originalPriceValue: number | null = null
  for (const priceChange of priceHistory) {
    const originalPrice = priceChange.oldValues[priceType]
    if (isValidPrice(originalPrice) && (originalPrice as number) > (currentPriceValue as number)) {
      originalPriceValue = originalPrice ?? null
      break
    }
  }

  // if no recent price value higher than current, return no price drop.
  if (originalPriceValue === null) {
    return noPriceDrop
  }

  return {
    isPriceDrop: true,
    originalPriceValue: originalPriceValue
  }
}
