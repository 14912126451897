import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as validator from '../../../utils/validation'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import Button from '../button'
import { sanitize } from '../../../utils/functions/miscellaneous'
import Checkbox from './checkbox'
import Select from './select'
import Input from './input'
import cx from 'classnames'
import styles from './form.module.scss'

// FIX: Global CSS Refactor
/**
 * @param {*} {
 *   formClasses,
 *   fieldsClasses,
 *   buttonsClasses,
 *   onSubmit,
 *   initialData,
 *   formData,
 * } Props
 *
 * @return {JSX.Element}
 */
const Form = ({
  formClasses,
  fieldsClasses,
  buttonsClasses,
  responseObj,
  onSubmit,
  onBeforeSubmit,
  initialData,
  formData,
  childrenBeforeSubmitButton,
  onFormChange,
  hideCheckBoxErrors = false
}) => {
  const [errorMessages, setErrorMessages] = useState({})
  const [values, setValues] = useState(undefined)
  const [fieldsError, setFieldsError] = useState([])
  const [allowSubmit, setAllowSubmit] = useState(false)

  const onFormChangeHandle = (event) => {
    onFormChange && onFormChange(event, setFieldsError, setErrorMessages, setAllowSubmit)
  }

  const { success, message } = responseObj || {}
  useEffect(() => {
    // setFieldsError([])
    if (isEmpty(errorMessages) && values !== undefined && onSubmit) {
      onSubmit(values)
      setValues(undefined)
      setErrorMessages({}) // clear the errors once submitted
    }
  }, [errorMessages, onSubmit, values])

  const handleSubmit = (event) => {
    event.preventDefault()

    onBeforeSubmit && onBeforeSubmit()

    const { fields } = formData || {}
    const { elements } = event.target || {}
    const values = {}
    fields.length &&
      fields.map((field) => {
        let { name } = field || {}
        if (field.type === 'checkbox') {
          values.checkboxes = []
          field.options.map((option) => {
            name = option.name
            const target = elements[name]
            if (target.value) {
              values.checkboxes.push(handleValidationOnSubmit(target.value, field, target.checked))
            }
          })
        } else {
          const target = elements[name]
          if (typeof target === 'object' && !target.type) {
            values[name] = map(target, (option) => {
              return handleValidationOnSubmit(option.value, field, option.checked)
            })
          } else {
            values[`${name}`] = handleValidationOnSubmit(target.value, field)
          }
        }
      })

    setValues(values)
  }

  const handleValidationOnSubmit = (value, field, checked) => {
    const { name } = field || {}
    let { rules } = field || {}
    let optionName = null
    if (rules?.[value]) {
      rules = rules?.[value]
      optionName = value
    }

    if (!rules) {
      return []
    }

    rules &&
      rules.length &&
      rules.map((item) => {
        const args = item.args || []
        const { rule, message, minLength } = item || {}

        /** Since isValidString is our custom method */
        if (rule !== 'isValidString') {
          const isValid = validator[rule](optionName ? String(checked) : String(value), ...args)

          if (!isValid) {
            addErrorElement(name)
            setErrorMessages((state) => ({
              ...state,
              [name]: optionName ? { [optionName]: [message] } : message
            }))
          }
        } else {
          if ((value || '').length < minLength) {
            addErrorElement(name)
            setErrorMessages((state) => ({
              ...state,
              [name]: optionName ? { [optionName]: [message] } : message
            }))
          }
        }
      })

    if (field.options && field.type === 'checkbox' && !checked) {
      return null
    }
    return sanitize(value)
  }

  const addErrorElement = (name) => {
    if (!fieldsError.includes(name)) {
      const tempFieldsError = fieldsError
      tempFieldsError.push(name)
      setFieldsError([...tempFieldsError])
    }
  }

  const removeErrorElement = (name) => {
    const tempFieldsError = fieldsError
    const index = tempFieldsError.indexOf(name)
    if (index > -1) {
      tempFieldsError.splice(index, 1)
      setFieldsError([...tempFieldsError])
    }
  }

  const handleValidation = (value, rules, optionName = null, field) => {
    if (!rules) {
      return []
    }

    // for multi checkboxes
    if (optionName) {
      rules = rules[optionName]
    }
    setAllowSubmit(true)

    return (
      rules &&
      rules.length &&
      rules.map((item) => {
        const args = item.args || []
        const { rule, message, minLength } = item || {}
        if (!rule) {
          return null
        } else if (rule === 'isValidString') {
          /** This is a custom rule that only checks for string length */
          if ((value || '').length < minLength) {
            addErrorElement(field.name)
            return optionName ? { [optionName]: [message] } : [message]
          } else {
            removeErrorElement(field.name)
            return null
          }
        } else {
          const isValid = validator[rule](value, ...args)
          if (!isValid) {
            addErrorElement(field.name)
            return optionName ? { [optionName]: [message] } : [message]
          } else {
            removeErrorElement(field.name)
            return optionName ? { [optionName]: null } : null
          }
        }
      })
    )
  }

  const getField = (field, key) => {
    const { name, label, className, labelClassName, rules, type, options, placeholder } =
      field || {}

    const value = initialData && name in initialData ? initialData[name] : ''
    const errorMessage = errorMessages && name in errorMessages ? errorMessages[name] : ''

    const fieldProps = {
      name,
      label,
      className,
      labelClassName,
      errorMessage,
      validation: (value, optionName) => handleValidation(value, rules, optionName, field)
    }

    switch (type) {
      case 'select':
        return (
          <Select
            {...fieldProps}
            options={options}
            placeholder={placeholder}
            value={value}
            key={key}
          />
        )
      case 'checkbox':
        return (
          <Checkbox
            key={key}
            {...fieldProps}
            options={field.options}
            onChange={onFormChangeHandle}
            checkedOptions={value || []}
            hideCheckBoxErrors={hideCheckBoxErrors}
          />
        )
      default:
        return (
          <Input {...fieldProps} type={type} placeholder={placeholder} value={value} key={key} />
        )
    }
  }

  return (
    <form onSubmit={handleSubmit} className={formClasses}>
      <div className={fieldsClasses}>
        {(formData?.fields ?? []).map((field, index) => getField(field, index))}
      </div>

      {childrenBeforeSubmitButton ? childrenBeforeSubmitButton : null}

      <div className={buttonsClasses}>
        {formData.buttons.map((button, index) => {
          const { color, type, handleOnClick, secondary, text, className } = button || {}

          return (
            <Button
              key={index}
              className={cx(className, {
                'error-btn': isArray(fieldsError) && fieldsError.length > 0
              })}
              disabled={!(type === 'submit' && allowSubmit)}
              color={fieldsError.length ? 'red' : color}
              type={type}
              onClick={() => {
                // eslint-disable-next-line
                handleOnClick
              }}
              secondary={secondary}
            >
              {text}
            </Button>
          )
        })}
      </div>
      {!isEmpty(message) && (
        <div className='errors'>
          <p
            className={
              success ? cx(styles['drive-form__success']) : cx(styles['drive-form__failure'])
            }
          >
            {message}
          </p>
        </div>
      )}
    </form>
  )
}

Form.propTypes = {
  formData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onFormChange: PropTypes.func,
  onBeforeSubmit: PropTypes.func,
  initialData: PropTypes.object,
  fieldsClasses: PropTypes.string,
  formClasses: PropTypes.string,
  buttonsClasses: PropTypes.string,
  childrenBeforeSubmitButton: PropTypes.element,
  hideCheckBoxErrors: PropTypes.bool
}

export default Form
