import cx from 'classnames'
import { useEffect, useState, ReactNode } from 'react'
import { GenerateAdSpacing } from '../../../utils/functions/ads/GenerateAdSpacing'
import styles from './adSpacing.module.scss'

//@TODO add a callback to pass on style options
const AdSpacing = ({
  type = 'defaultAd',
  isInreadSticky = false,
  customStyle = null,
  dataTestid = '',
  children,
  className = '',
  overwriteSpacing = false,
  adHasNoData
}: AdSpacingProps) => {
  const [adSpacingState, setAdSpacingState] = useState<AdSpacingStyles>({
    paddingTop: '2.25rem', //36px
    paddingBottom: '2.25rem'
  })

  useEffect(() => {
    if (process.browser && type) {
      const generate = new GenerateAdSpacing(isInreadSticky, window.innerWidth < 768).export()
      setAdSpacingState(generate[type]({ ...customStyle }))
    }

    if (overwriteSpacing && customStyle) {
      setAdSpacingState({ ...customStyle })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInreadSticky, customStyle, overwriteSpacing])

  return (
    <div
      data-testid={dataTestid}
      style={adSpacingState}
      className={cx(styles['drive-ad-spacing'], className, {
        [styles['drive-ad-spacing--no-data']]: adHasNoData
      })}
    >
      {children}
    </div>
  )
}

type AdSpacingStyles = {
  [key: string]: any
}

type AdSpacingProps = {
  type?: 'articleAd' | 'bestCarListSingleAd' | 'roofrack' | 'defaultAd'
  customStyle?: { [key: string]: string } | null
  isInreadSticky?: boolean
  adHasNoData?: boolean
  dataTestid?: string
  className?: string
  children: ReactNode
  overwriteSpacing?: boolean
}

export default AdSpacing
