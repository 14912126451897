import React from 'react'
import { FunctionComponent } from 'react'

import MarketplaceUSPBanner from './banner'
import MarketplaceUSPPoster from './poster'

const MarketplaceUSP: FunctionComponent<MarketplaceUSPProps> = ({ isBanner = true }) => {
  const usps: string[] = [
    'New & quality used cars',
    'Handpicked dealers',
    'Dealer warranty',
    'No private sellers'
  ]

  if (isBanner) {
    return <MarketplaceUSPBanner usps={usps} />
  } else {
    return <MarketplaceUSPPoster usps={usps} />
  }
}

type MarketplaceUSPProps = {
  isBanner?: boolean
}

export default MarketplaceUSP
