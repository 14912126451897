import fetch from 'cross-fetch'
import logger from '../../utils/logger'
import isEmpty from 'lodash/isEmpty'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'

export type EmailSubscriptionRequest = {
  email: string
  firstName: string
  lastName: string
  preferredMake?: string | null
  preferredModel?: string | null
  postcode?: string | null
  mobileNumber?: string | null
  contactPreference?: string | null
  marketingOptInFirstParty?: boolean | null
  marketingOptInThirdParty?: boolean | null
  commsOptin?: {
    E2: boolean | null
  }
}

const subscriptionApiUrl: string =
  (getRuntimeEnv('NEXT_PUBLIC_EMAIL_SUBSCRIPTION_API_URL') as string) || ''

export const subscribeToNewsletter = async (values: EmailSubscriptionRequest) => {
  if (!values || isEmpty(values)) {
    throw new Error('MissingEmailSubscriptionValues')
  }

  try {
    let response = await fetch(subscriptionApiUrl, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(values)
    })
    const responseData = response.ok && response.status === 200 ? await response.json() : {}
    return { ...responseData, success: true }
  } catch (error: any) {
    logger.error('NewsletterSubscribeError', error)
    return { success: false, error: error.message }
  }
}
