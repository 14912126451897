import cx from 'classnames'
import { memo, createElement } from 'react'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import Link from '../../link'
import SubTexts from '../sub-texts'
import Thumbnail from '../thumbnail'
import hasSpecificTag from '../../../../utils/functions/hasSpecificTag'
import { VideoCameraIcon } from '../../../icons'
import { hasEllipsis } from '../../../../utils/functions/hasEllipsis'
import { useRef, useState } from 'react'
import styles from './articleAnnexCard.module.scss'

const ArticleAnnexCard = (props) => {
  const {
    article,
    headingElement,
    onHomePage,
    thumbnail: CardThumbnail,
    subTexts: CardSubTexts,
    isCarsForSaleDefaultImage = false,
    thumbnailClassName = '',
    imageAttributes
  } = props

  const extendedProps = {
    ...props,
    showDate: true,
    showStickyType: false,
    showCategory: false,
    onHomePage: false,
    headingElement: 'h4'
  }

  const titleRef = useRef(null)
  const [showHoverTitle, setShowHoverTitle] = useState(false)

  const onHover = () => {
    const show = hasEllipsis(titleRef.current, true)
    if (show !== showHoverTitle) {
      setShowHoverTitle(show)
    }
  }

  let { title, uri } = article || {}

  let isExternal = false

  // Add ad link tracking URLs if present for the article.
  if (article?.clickthroughUrl) {
    article.uri = article.clickthroughUrl
    uri = article.clickthroughUrl
    isExternal = true
  }

  const wrapperClass = onHomePage
    ? cx(styles['drive-annex-card__home-page-wrapper'])
    : cx(styles['drive-annex-card__wrapper'])

  const containerClasses = onHomePage
    ? cx(styles['drive-annex-card__home-page-container'])
    : cx(styles['drive-annex-card__container'])

  const contentClasses = onHomePage
    ? cx(styles['drive-annex-card__home-page-content'])
    : cx(styles['drive-annex-card__content'])

  if (isEmpty(article)) {
    return null
  }
  const isArticleIncludesVideo = hasSpecificTag(article?.tags?.nodes ?? {}, 'video')

  return (
    <div className={cx(styles['drive-annex-cards__wrapper'], wrapperClass)}>
      <div className={cx(styles['drive-annex-cards__container'], containerClasses)}>
        {CardThumbnail ? (
          <CardThumbnail
            isArticleIncludesVideo={isArticleIncludesVideo}
            isExternal={isExternal}
            {...extendedProps}
            isCarsForSaleDefaultImage={isCarsForSaleDefaultImage}
            thumbnailClassName={thumbnailClassName}
            imageAttributes={imageAttributes}
          />
        ) : (
          <Thumbnail
            isArticleIncludesVideo={isArticleIncludesVideo}
            isExternal={isExternal}
            {...extendedProps}
            isCarsForSaleDefaultImage={isCarsForSaleDefaultImage}
            thumbnailClassName={thumbnailClassName}
            imageAttributes={imageAttributes}
          />
        )}
        <div className={cx(styles['drive-annex-cards__content'], contentClasses)}>
          <Link href={uri} isExternal={isExternal}>
            <a>
              {createElement(headingElement || 'h4', {
                onMouseOver: onHover,
                ref: titleRef,
                className: cx(styles['drive-annex-cards__heading'], {
                  ' truncate-4': onHomePage,
                  ' truncate-3': !onHomePage
                }),
                title: showHoverTitle ? title : '',
                children: (
                  <>
                    {isArticleIncludesVideo ? (
                      <VideoCameraIcon className={cx(styles['drive-annex-cards__video-camera'])} />
                    ) : null}
                    {title}
                  </>
                )
              })}
            </a>
          </Link>
          {CardSubTexts ? <CardSubTexts {...extendedProps} /> : <SubTexts {...extendedProps} />}
        </div>
        {!isEmpty(article.impressionTrackingUrls) &&
          article.impressionTrackingUrls.map((trackingUrl, index) => {
            return (
              <img
                src={trackingUrl}
                width='1'
                height='1'
                className='gam-impression-tracker'
                key={index}
                data-component='article-annex'
              />
            )
          })}
      </div>
      {onHomePage && <hr className={cx(styles['drive-annex-cards__space'])} />}
    </div>
  )
}

ArticleAnnexCard.propTypes = {
  article: PropTypes.shape({
    databaseId: PropTypes.number,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    image: PropTypes.shape({
      node: PropTypes.shape({
        title: PropTypes.string,
        altText: PropTypes.string,
        caption: PropTypes.string,
        sourceUrl: PropTypes.string,
        cloudinary: PropTypes.object
      })
    }),
    author: PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string,
        uri: PropTypes.string,
        slug: PropTypes.string,
        avatar: PropTypes.shape({
          url: PropTypes.string
        })
      })
    })
  }),
  onHomePage: PropTypes.bool,
  showDate: PropTypes.bool,
  showStickyType: PropTypes.bool,
  showCategory: PropTypes.bool,
  thumbnail: PropTypes.func,
  subTexts: PropTypes.func,
  loading: PropTypes.string
}

export default memo(ArticleAnnexCard)
