import { LeadType, SiteSection } from '@grille/components/enquiry/types'

export const CFS_META_PIXEL_ACCOUNT_ID: number = 754567515023641
export const CFS_META_PIXEL_TRACKING_EVENTS: string[] = ['PageView', 'SubmitApplication']
export const LEAD_NEW_CAR: LeadType = 'new-car'
export const LEAD_CARS_FOR_SALE: LeadType = 'cars-for-sale'
export const SITE_SECTION_CARS_FOR_SALE: SiteSection = 'cars-for-sale'

//Form tracking events action
export const ACTION_ENQUIRE_NOW: string = 'enquireNow'
export const ACTION_SEND_ENQUIRY: string = 'sendEnquiry'
