import logger from '../../../utils/logger'

const Error = ({ status, children }) => {
  logger.error(`Component Error Status : ${status?.code} Message : ${status?.message}`)
  if (children) {
    return children
  }
  return null
}

export default Error
