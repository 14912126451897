export const checkBrowserType = () => {
  let isBrowserIE = false
  let isBrowserSafari = false
  // IF IE > 10
  if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
    isBrowserIE = true
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    isBrowserSafari = true
  }

  return {
    isBrowserIE,
    isBrowserSafari
  }
}
