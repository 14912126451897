import isEmpty from 'lodash/isEmpty'
import { GlideOptions } from '../../../components/common/glideSlider'
import { LARGE_WINDOW_WIDTH, MEDIUM_WINDOW_WIDTH } from '../../../constants'

/**
 * returns active index which is used in bullets in glide slider
 * @param currentSlide what index are we currently in
 * @param perView how many slides do we want to show per scroll
 * @returns number
 */
const getActiveIndex = ({
  currentSlide,
  perView
}: {
  currentSlide: number
  perView: number
}): number => {
  return Math.ceil(currentSlide / perView)
}

/**
 * returns current breakpoint properties if any
 * @param glideOptions all breakpoints object
 * @returns {Object | undefined} of matching breakpoint properties
 */

const getCurrentBreakpointOptions = (
  breakPoints: Record<string, GlideOptions> | undefined
): GlideOptions | undefined => {
  if (isEmpty(breakPoints)) return undefined

  if (typeof window === 'undefined') return undefined

  const windowWidth: number = window.innerWidth
  let prevBreakPointKey
  let matchedBreakPoint = undefined
  /* collect and compare matched breakpoints and find the closest matched using diff
   * against the window width
   */
  for (const key in breakPoints) {
    if (parseInt(key) >= windowWidth) {
      if (prevBreakPointKey) {
        const prevDiff = prevBreakPointKey - windowWidth
        const currDiff = parseInt(key) - windowWidth
        // compare prev breakpoint with new one

        if (prevDiff > currDiff) {
          prevBreakPointKey = parseInt(key)
          matchedBreakPoint = breakPoints[key]
        }
      } else {
        matchedBreakPoint = breakPoints[key]
        prevBreakPointKey = parseInt(key)
      }
    }
  }

  return matchedBreakPoint
}

/**
 * returns current navStatus
 * @param {number} totalSlides
 * @param {number} perView
 * @param {number} currentSlideIndex
 * @returns {NavStatusKey, boolean} as Nav status
 */
const getNavStatus = (
  totalSlides: number,
  perView: number,
  currentSlideIndex: number
): Record<NavStatusKey, boolean> => {
  const remainingMoveCount: number = Math.ceil((totalSlides - currentSlideIndex) / perView) - 1
  return {
    prev: currentSlideIndex > 0,
    next: remainingMoveCount !== 0
  }
}

export type NavStatusKey = 'prev' | 'next'

const getPostBlockGlideSettings = (options: PostBlockGlideOptions) => {
  if (options.isDCOTYEverGreen && options.parsedBlock?.name === 'drive-blocks/gallery') {
    return {
      showDots: false,
      breakpoints: {
        [LARGE_WINDOW_WIDTH]: {
          showDots: false
        },
        [MEDIUM_WINDOW_WIDTH]: {
          showDots: false
        }
      }
    }
  }
}

type PostBlockGlideOptions = {
  isDCOTYEverGreen: boolean
  parsedBlock: {
    name: string
  }
}

export { getActiveIndex, getCurrentBreakpointOptions, getNavStatus, getPostBlockGlideSettings }
