import React from 'react'
import { formatPrice } from '../../../utils/functions/miscellaneous'

import cx from 'classnames'
import styles from './priceRange.module.scss'

const PriceRange = ({ min, max, className, customFormatPrice, noSpace, type }: PriceRangeProps) => {
  if (min === undefined || max === undefined) {
    return null
  }
  const priceUnavailable = type === 'DRIVE' && (min === 0 || max === 0)
  const formattedMinPrice = customFormatPrice ? customFormatPrice(min) : formatPrice(min, noSpace)
  const formattedMaxPrice = customFormatPrice ? customFormatPrice(max) : formatPrice(max, noSpace)

  let range = ''
  if (!priceUnavailable) {
    if (min === max) {
      range = formattedMinPrice
    } else {
      range = `${formattedMinPrice} - ${formattedMaxPrice}`
    }
  } else {
    range = '$ Price unavailable'
  }

  return (
    <div className={cx(styles['drive-model-card__price'], className)}>
      {range}
      {priceUnavailable ? null : (
        <>
          <sup>*</sup> <span className={cx(styles['drive-model-card__price-type'])}>MRLP</span>
        </>
      )}
    </div>
  )
}

type PriceRangeProps = {
  className?: string
  noSpace?: boolean
  min?: number | undefined
  max?: number | undefined
  type?: string
  customFormatPrice?: (price: number | undefined) => string
}

export default PriceRange
