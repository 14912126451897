/**
 * External dependencies
 */
import DOMPurify from 'dompurify'

import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import capitalize from 'lodash/capitalize'
import isString from 'lodash/isString'
import toString from 'lodash/toString'
import { TAKE_OUR_PAGE_ROUTES } from '@grille/constants/pages'
import { getGrilleAsset } from '@grille/utils/getGrilleAsset'
import logger from '@grille/utils/logger'

/**
 * Sanitize markup or text when used inside dangerouslysetInnerHTML
 *
 * @param {string} content Plain or html string.
 *
 * @return {string} Sanitized string
 */
export const sanitize = (content) => {
  return process.browser ? DOMPurify.sanitize(content, { ADD_ATTR: ['target'] }) : content
}

/**
 * Get singular or plural form of given text
 *
 * Calculates basis the count.
 *
 * @param {Integer} count count.
 * @param {String} text Text.
 * @returns {string} Singular or plural form of text.
 */
export const getSingularOrPluralText = (count, text) => {
  return count > 1 ? `${text}s` : text
}

/**
 * Function to change price format.
 *
 * @param {Integer} number Price to change format.
 *
 * @return {Integer}
 *
 * Example:
 *   Input: 99444500
 *   Output: 99,444,500
 */
export const priceFormat = (number) => {
  if (number === 0) {
    return 0
  }
  if (!number) {
    return null
  }

  return new Intl.NumberFormat().format(number)
}

/**
 * Get formatted price string
 * @param {Number} price Price
 * @param {Boolean} noSpace option to skip space $ and price
 * @returns {String} Formatted price string eg. $ 40,000
 */
export const formatPrice = (price, noSpace = false) => {
  if (price === '') {
    return ''
  }
  return !isNaN(price) ? `$${noSpace ? '' : ' '}${priceFormat(price)}` : ''
}

/**
 * Get date in format of d.m.Y
 *
 * @param {string} dateString  Date string, example 2020-11-30 => 30.11.2020
 *
 * @return {string}
 */
export const getFormattedDate = (dateString) => {
  if (!dateString) {
    return ''
  }

  const date = new Date(dateString)

  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

/**
 * Converts the Hyphen Separated String into Capitalize String
 * e.g: price-range = priceRange
 * If string doesn't contains hyphen then it will return the same string
 *
 * @param {String} value
 */
export const convertHyphenStringToCapitalize = (value) => {
  if (!value || typeof value !== 'string') {
    return
  }

  const valueArray = value.split('-')

  /** If there is no hyphen available */
  if (valueArray.length === 0) {
    return value
  }

  /** Converts other string parts into capitalize except first */
  const capitalizeItems = valueArray.map((item, index) => {
    if (index !== 0) {
      return capitalize(item)
    }
    return item
  })

  return capitalizeItems.join('')
}

/**
 * Add apostrophe to a name
 *
 * Example: John's, Bayes'
 *
 * @param {string} name
 *
 * @return {string}
 */
export const addApostrophe = (name) => {
  if (typeof name === 'string') {
    if (name[name.length - 1].toLowerCase() === 's') {
      name += "'"
    } else {
      name += "'s"
    }
  }

  return name
}

/**
 * Function to check hero image is exist or not.
 *
 * @param {object} hero
 *
 * @return bool
 */
export const doesHeroImageExist = (hero) => {
  if (isEmpty(hero?.fileType)) {
    return false
  }

  if (hero?.fileType === 'video' && isEmpty(hero?.video)) {
    return false
  }

  if (hero?.fileType === 'image' && isEmpty(hero?.mediumThumbnail)) {
    return false
  }

  return true
}

/**
 * Merges List of Objects to single Array using Key field
 *
 * @param {Array} array1 List of Objects
 * @param {Array} arrray2 List of Objects
 * @param {String} identifier Key used for Comparing objects from Arrays
 * @returns {Array} Merged Array
 */
export const mergeObjectArray = (array1, arrray2, identifier) => {
  if (!isArray(array1) || !isArray(arrray2) || isEmpty(identifier)) {
    return []
  }

  return [
    ...array1
      .concat(arrray2)
      .reduce((m, o) => {
        const key = isString(o[identifier]) ? o[identifier] : toString(o[identifier])

        return m.set(key, Object.assign(m.get(o[identifier]) || {}, o))
      }, new Map())
      .values()
  ]
}

/**
 * Separates List of Model Object by rating
 *
 * @param {Array} models List of Model Objects
 * @returns {WithRatings, WithoutRatings} containing withRatings and withoutRatings
 */
export const getModelsWithAndWithoutRatings = (models = []) => {
  const modelsWithoutRatings = []
  const modelWithRatings = models.filter((model) => {
    const rating = model?.latestRating?.nodes?.[0]?.rating?.overall
    if (rating) {
      return true
    } else {
      modelsWithoutRatings.push(model)
      return false
    }
  })
  return { withRatings: modelWithRatings, withoutRatings: modelsWithoutRatings }
}

/**
 * Add a value to an array at the given position.
 *
 * @param {Array} arr
 * @param {Integer} index
 * @param {Object} value
 * @returns {Array}
 */
export const sqeezeIntoArray = (arr, index, value) => {
  let newarr = []
  if (index > 0) newarr = arr.slice(0, index)
  newarr.push(value)
  if (index < arr.length) newarr = [...newarr, ...arr.slice(index, arr.length)]
  return newarr
}

/**
 * Get the Price info.
 *
 * @param {Array} deals Deals
 *
 * @returns {string}
 */
export const getInclusionPriceInfo = (deals) => {
  if (isEmpty(deals) || !isArray(deals)) {
    return null
  }

  let priceInfo = ''
  let dealInfo = ''

  const offerMap = {
    DAP: 'Drive Away Price',
    FIN: 'Finance Offer',
    GEN: 'Extras Offer',
    PRMP: 'Premium Paint',
    OPT: 'Vehicle Option'
  }

  for (let i = 0; i < deals.length; i++) {
    if (deals[i]?.value !== '0') {
      const prefix = 'DAP' === deals[i]?.type ? '$' : ''
      priceInfo = `From ${prefix}${deals[i]?.value ? priceFormat(deals[i]?.value) : ''}`
    }
    dealInfo += `${offerMap?.[deals[i]?.type]} `
  }

  return `${priceInfo} ${dealInfo}`
}

/**
 * Get the Price info.
 *
 * @param {Array} deals Deals
 *
 * @returns {string}
 */
export const getShowroomDealTitle = (deals) => {
  if (isEmpty(deals) || !isArray(deals)) {
    return null
  }

  let priceInfo = ''
  let dealInfo = ''

  const offerMap = {
    DAP: 'Drive Away',
    FIN: 'Finance Offer',
    GEN: 'Extras Offer',
    PRMP: 'Premium Paint',
    OPT: 'Vehicle Option'
  }

  for (let i = 0; i < deals.length; i++) {
    if (!isEmpty(deals[i]) && deals[i]?.value !== '0') {
      const prefix = 'DAP' === deals[i]?.type ? '$' : ''
      if (prefix === '$') {
        priceInfo = `From $${priceFormat(deals[i]?.value)}\n`
      }
    }
    dealInfo += `${offerMap?.[deals[i]?.type]} `
  }
  return `${priceInfo} ${dealInfo}`
}

/**
 * Function to quick condition check before setting elements in array.
 * @param {Bool} condition condition to check
 * @param {Array} elements elements to return if condition is statisfied
 * @returns {Array} elements or empty array
 */
export const insertIf = (condition, elements = []) => {
  return condition ? elements : []
}

export const getYouTubeId = (url) => {
  const parsedUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return parsedUrl[2] !== undefined ? parsedUrl[2].split(/[^0-9a-z_\-]/i)[0] : parsedUrl[0]
}

/**
 * Function to check if its a pageTakeover page or not.
 * @param {Route} path of the page
 * @returns {bool} true or false based on the route
 */
export const isGamTakeOverPage = (route) => {
  return TAKE_OUR_PAGE_ROUTES.indexOf(route) !== -1
}

/**
 * Function to convert hash query parameters to key value pair
 * @param {String} hashQuery String hash value
 * @returns {Object} Return hash object
 */
export const parseHashParams = (hash) => {
  let hashObject = {}
  if (!isEmpty(hash)) {
    const hashQuery = hash.replace('#', '')
    const pieces = hashQuery.split('&')
    let parts = []
    // process each query pair
    for (let i = 0; i < pieces.length; i++) {
      parts = pieces[i].split('=')
      if (parts.length < 2) {
        parts.push('')
      }
      if (!isEmpty(decodeURIComponent(parts[0]))) {
        hashObject[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1])
      }
    }
  }
  return hashObject
}

export const insertElementAtIndex = (arr = [], index, element) => {
  if (isEmpty(element) || isEmpty(arr)) {
    return arr
  }
  return [...arr.slice(0, index), element, ...arr.slice(index)]
}

export const checkMakeModelSlug = async (makeSlug, modelSlug) => {
  try {
    const validSlugs = await getGrilleAsset({ fileName: 'valid-slugs.json' })
    return {
      isValidMake: () => !!validSlugs?.['make-model-data']?.[`${makeSlug}`],
      isValidModel: () => !!validSlugs?.['make-model-data']?.[`${makeSlug}`]?.[`${modelSlug}`]
    }
  } catch (error) {
    logger.error(
      `[validSlug error] - isValidMakeModelSlug [makeSlug - ${makeSlug}] [modelSlug - ${modelSlug}] [message:${JSON.stringify(
        error
      )}]`
    )
  }
}
