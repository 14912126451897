import React from 'react'
import cx from 'classnames'
import styles from './d-usp-item.module.scss'
import { FunctionComponent } from 'react'
import { CheckCircleIcon } from '@grille/components/icons'
import { Color } from '@grille/constants/colors'

const UspItem: FunctionComponent<UspItemProps> = ({
  uspItemValue,
  className,
  iconColor = Color.black
}) => {
  return (
    <div className={cx(styles['d-usp-item'], className)}>
      <CheckCircleIcon width='24' height='24' loading='eager' color={iconColor} />
      <span>{uspItemValue}</span>
    </div>
  )
}

type UspItemProps = {
  uspItemValue: string
  isBanner?: boolean
  className?: string
  iconColor?: Color
}

export default UspItem
