export const EVENT_ACTION_PRODUCT_IMPRESSION = 'product impression'
export const EVENT_ACTION_PRODUCT_STANDALONE_IMPRESSION = 'product standalone impression'
export const EVENT_ACTION_STANDALONE_COMPLETE = 'product standalone complete'
export const EVENT_ACTION_ADD_TO_CART = 'add to cart'
export const EVENT_ACTION_CHECKOUT_COMPLETE = 'checkout complete'
export const DEFAULT_POST_CODE = 'NULL'
export const DEFAULT_DEALER = 'Private'
export const PRIVATE_CATEGORY_TYPE = 'Private'
export const GENERIC_CATEGORY_TYPE = 'Generic'
export const FINANCE_CATEGORY_TYPE = 'Finance'
export const DEFAULT_CAR_ID = 'NULL'
export const ACTION_FIELD_LISTING_TYPE = 'Cars for Sale'

export const enum FORM_FIELDS_TYPES {
  ANIMATED_TEXT = 'animated-text',
  ANIMATED_TEXTAREA = 'animated-textarea',
  ANIMATED_PHONE = 'animated-phone',
  SELECT = 'select',
  //it is possible to have multiple checkboxes under this type
  CHECKBOX = 'checkbox',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number'
}
