/**
 * External dependencies.
 */
import cx from 'classnames'
import Link from '../common/link'
import { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import { ArrowForwardIcon, HomeIcon, ArrowBackIcon } from '../icons'
import { sanitize } from '../../utils/functions/miscellaneous'
import styles from './breadcrumbs.module.scss'
import { isTestEnv } from '../../utils/functions/isTestEnv'
import BTYB from '../common/ad-units/BTYB'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'
const isTest = isTestEnv()

const normaliseUrl = (url) => {
  // URL is null OR Relative OR Has correct hostname
  if (
    !url ||
    url.indexOf('http') !== 0 ||
    url.indexOf(getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL')) === 0
  ) {
    return url
  }
  try {
    const urlObject = new URL(url)
    // Replace http://localhost etc. with configured FE URL
    return url.replace(
      `${urlObject.protocol}//${urlObject.hostname}`,
      getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL')
    )
  } catch (error) {
    // URL is invalid
    return url
  }
}

/**
 * Breadcrumbs Component.
 *
 * @returns {JSX.Element}
 */
const Breadcrumb = ({
  data = [],
  className = '',
  hideLast = false,
  iconColor,
  pubSub,
  containerClassName = ''
}) => {
  const { subscribe } = pubSub ?? {}
  const [showBTYB, setBTYB] = useState(false)

  // Subscribe to showBTYB event
  useEffect(() => {
    if (subscribe) {
      subscribe('showBTYB', () => {
        setBTYB(true)
      })
    }
  }, [subscribe])

  if (isEmpty(data) || !isArray(data)) {
    return null
  }

  const ArrowIcon = () => (
    <ArrowForwardIcon
      color={iconColor}
      height={16}
      className={cx(styles['drive-breadcrumbs__arrow-forward'], 'black')}
    />
  )

  return (
    <div className={cx({ flex: showBTYB, 'items-center': showBTYB })}>
      <div className={cx(styles['drive-breadcrumbs'], className)}>
        <Link href='/'>
          <a>
            <HomeIcon
              height='14'
              color={iconColor ?? '#2960c5'}
              className={cx(styles['drive-breadcrumbs__home-icon'])}
              data-testid={isTest ? 'home-icon' : undefined}
            />
          </a>
        </Link>
        {data.length > 1 && <ArrowIcon />}

        {data.map((link, index) => {
          return (
            link.text !== 'Home' && (
              <Fragment key={`page-breadcrumbs-${index}`}>
                {data.length !== index + 1 ? (
                  <Link href={normaliseUrl(link?.url)}>
                    <a
                      className={cx(styles['drive-breadcrumbs__back'])}
                      dangerouslySetInnerHTML={{ __html: sanitize(link?.text) }}
                    />
                  </Link>
                ) : !hideLast ? (
                  <span
                    className={cx(styles['drive-breadcrumbs__title'])}
                    dangerouslySetInnerHTML={{ __html: sanitize(link?.text) }}
                  />
                ) : null}
                {(data.length >= index + 3 && hideLast) ||
                (!hideLast && data.length !== index + 1) ? (
                  <ArrowIcon />
                ) : null}
              </Fragment>
            )
          )
        })}
      </div>
      <div className={cx(className, 'md:hidden mobile')}>
        {data[data.length - 2] ? (
          <Link href={normaliseUrl(data[data.length - 2].url)}>
            <a className='flex items-center'>
              <ArrowBackIcon
                color={iconColor}
                height={16}
                className={cx(styles['drive-breadcrumbs__arrow-back'])}
              />
              <span className={cx(styles['drive-breadcrumbs__link'])}>
                {data[data.length - 2].text}
              </span>
            </a>
          </Link>
        ) : null}
      </div>
      {showBTYB && (
        <div className={cx(styles['drive-breadcrumbs__btyb'])}>
          <BTYB
            slot={'gam_btyblogo_pos1'}
            styleProps={{ imageBg: 'bg-transparent' }}
            linkClassName='relative z-10 block'
          />
        </div>
      )}
    </div>
  )
}

Breadcrumb.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  hideLast: PropTypes.bool,
  iconColor: PropTypes.string,
  pubSub: PropTypes.object,
  containerClassName: PropTypes.string
}

export default Breadcrumb
