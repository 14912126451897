import AnnexCardLink from '../link'
import { PlayIconRounded } from '../../../icons'
import ThumbnailImage from '../../thumbnail-image'
import AnnexCardLabel from '../../label/annex-card-label'
import styles from './thumbnail.module.scss'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

const Thumbnail = ({
  article,
  sizeName,
  showCategory,
  isArticleIncludesVideo = false,
  isExternal = false,
  isCarsForSaleDefaultImage = false,
  loading,
  thumbnailClassName = '',
  imageAttributes = {}
}) => {
  const { uri, title, image, thumbnail, categories } = article || {}
  let imageObject = image?.node ?? thumbnail?.node ?? {}
  imageObject = { ...imageObject, loading, ...imageAttributes }

  const stickyType = isEmpty(article?.stickyPosts?.stickyType)
    ? null
    : article?.stickyPosts?.stickyType

  return (
    <AnnexCardLink
      href={uri}
      isExternal={isExternal}
      className={cx(styles['drive-annex-card__link'], thumbnailClassName)}
    >
      {stickyType ? (
        <span className={cx(styles['drive-annex-card__sticky'])}>{stickyType}</span>
      ) : null}
      <ThumbnailImage
        postTitle={title || ''}
        image={imageObject}
        object={article}
        sizeName={sizeName || 'ARTICLE_CARD_THUMBNAIL'}
        compact
        imageLoading={loading}
        isCarsForSaleDefaultImage={isCarsForSaleDefaultImage}
      />
      {isArticleIncludesVideo && (
        <span className={cx(styles['drive-annex-card__play-btn-wrapper'])}>
          <PlayIconRounded className={cx(styles['drive-annex-card__play-icon'])} />
        </span>
      )}
      {showCategory && categories?.nodes?.[0]?.name && (
        <AnnexCardLabel
          text={categories.nodes[0].name}
          className={cx(styles['drive-annex-card__label'])}
        />
      )}
    </AnnexCardLink>
  )
}

export default Thumbnail
