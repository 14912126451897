import { FunctionComponent, useEffect, useState } from 'react'
import { getAdUnit } from './adTaglessFeature'
import Image from '../image'
import Link, { linkDefaultProps } from '../link'
import AdImpressionTrack from './ad-impression-track'

const BTYB: FunctionComponent<Props> = ({ slot, styleProps, linkClassName }) => {
  const [adData, setAdData] = useState<LogoAdData | null>(null)

  useEffect(() => {
    if (slot) {
      const getAd = async () => {
        const adResponse = (await getAdUnit({ slot: slot })) as LogoAdData | null | undefined
        if (adResponse && adResponse?.image) {
          setAdData({
            url: adResponse?.url,
            image: adResponse.image,
            isExternalURL: true,
            impressionTrackingUrls: adResponse?.impressionTrackingUrls
          })
        }
      }
      getAd()
    }
  }, [slot])

  return (
    adData && (
      <>
        <Link {...linkDefaultProps} href={adData.url} isExternal={adData.isExternalURL}>
          <a className={linkClassName ? linkClassName : undefined}>
            <Image {...adData.image} loading='lazy' {...styleProps} />
          </a>
        </Link>
        <AdImpressionTrack impressionTrackingUrls={adData.impressionTrackingUrls} />
      </>
    )
  )
}

type LogoAdData = {
  url: string
  image: Record<string, any>
  isExternalURL: boolean
  impressionTrackingUrls: string[]
}

type Props = {
  slot: string
  styleProps?: Record<string, string>
  linkClassName?: string
}

export default BTYB
