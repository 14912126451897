import isEmpty from 'lodash/isEmpty'
import range from 'lodash/range'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { BucketVariant } from '../../types/compareBucket'
import { SMALL_WINDOW_WIDTH } from '@grille/constants'
import CompareBucketEngine, {
  CompareBucketEngineStatic
} from '../../utils/class/CompareBucketEngine'
import styles from './compareBucket.module.scss'
import cx from 'classnames'
import CompareBucketCard from './compare-bucket-card'
import BucketComparisonCTA from './bucket-comparison-cta'
import { COMPARE_BUCKET_ITEM_LIMIT } from '../../constants/compareBucket'
import React from 'react'
import { useTouch } from '../../utils/hooks/useTouch/useTouch'

import useWindowWidth from '@grille/utils/hooks/use-window-width'
const CompareBucket: FunctionComponent<Props> = () => {
  const cbEngine: CompareBucketEngineStatic | null = useMemo(
    () => CompareBucketEngine.getCurrentInstance()?.export() ?? null,
    []
  )

  const [items, setItems] = useState<BucketVariant[]>([])
  const needFirstCarAnimationRef = useRef<boolean>(false)
  const itemCountRef = useRef<number>(0)
  const { windowWidth } = useWindowWidth()

  const channelId: string = 'compare bucket'
  const [showCompare, setShowCompare] = useState<boolean>(false)
  const [showAddAnimation, setShowAddAnimation] = useState<boolean>(false)

  const variantUUIDs = items.map((item) => item.uuid).join(',')
  const handleRef = useRef<HTMLElement | null>(null)
  const compareHandleTouchStatus = useTouch(handleRef.current)
  const comnpareURI = `/compare-specs/#compare=${variantUUIDs}?clear-bucket=true`

  useEffect(() => {
    if (compareHandleTouchStatus !== null) {
      const { touchStart, touchEnd } = compareHandleTouchStatus
      if (touchStart !== null && touchEnd !== null) {
        // check if the drag was from left to right
        if (touchEnd > touchStart) {
          setShowCompare(false)
        }
        // check if the drag was from right to left
        else if (touchEnd < touchStart) {
          setShowCompare(true)
        } else if (+touchEnd.toFixed(2) === +touchStart.toFixed(2)) {
          setShowCompare((pervValue) => !pervValue)
        }
      }
    }
  }, [compareHandleTouchStatus])

  //Initialize and Subscribe to bucket changes
  useEffect(() => {
    if (typeof window !== 'undefined' && cbEngine) {
      // load items to bucket when initialize
      const items = cbEngine?.getItems() ?? []
      //only need animation if bucket is initialized with 0 items
      needFirstCarAnimationRef.current = items.length === 0
      setItems(items)
      cbEngine?.subscribe(channelId, (newItems: BucketVariant[]) => {
        setItems(newItems)
      })
    }

    return () => {
      cbEngine?.unsubscribe(channelId)
    }
  }, [cbEngine])

  useEffect(() => {
    if (windowWidth < SMALL_WINDOW_WIDTH) {
      if (items.length == 0) {
        setShowCompare(false)
        // needFirstCarAnimation.current is true only if bucket was empty when loaded
      } else if (needFirstCarAnimationRef.current) {
        // only show once
        needFirstCarAnimationRef.current = false
        setShowCompare(true)
        setTimeout(() => {
          setShowCompare(false)
        }, 2000)
        // when first car animation is not taken place, trigger Add animation when adding to bucket
      } else if (itemCountRef.current < items.length) {
        setShowAddAnimation(true)
        setTimeout(() => {
          setShowAddAnimation(false)
        }, 250)
      }
      itemCountRef.current = items.length
    }
  }, [items.length, windowWidth])

  if (isEmpty(items)) return null

  return (
    <>
      <div
        className={cx(styles['drive-compare-bucket_container'], {
          [styles['drive-compare-bucket_container--show']]: showCompare,
          [styles['drive-compare-bucket_container--hide']]: !showCompare
        })}
      >
        <span
          className={cx(styles['drive-compare-bucket_compare-btn'], {
            [styles['drive-compare-bucket_compare-btn--shadowed']]: !showCompare,
            [styles['drive-compare-bucket_compare-btn--add-animation']]: showAddAnimation
          })}
          ref={handleRef}
        >
          Compare
        </span>
        <div
          className={cx(styles['drive-compare-bucket_cards-container'], {
            [styles['drive-compare-bucket_cards-container--show']]: showCompare,
            [styles['drive-compare-bucket_cards-container--hide']]: !showCompare
          })}
        >
          {' '}
          {range(COMPARE_BUCKET_ITEM_LIMIT).map((index) => {
            return (
              <div
                className={cx(styles['drive-compare-bucket_card-wrapper'], 'lmd:first:pl-0', {
                  [styles['drive-compare-bucket_card-wrapper--bordered']]: index <= 1,
                  [styles['drive-compare-bucket_card-wrapper--highted']]: index > 0
                })}
                key={`${items[index]?.uuid ?? ''}-${index}`}
              >
                {items[index] && <CompareBucketCard carVariant={items[index]} />}
              </div>
            )
          })}
          <BucketComparisonCTA uri={comnpareURI} />
        </div>
      </div>
    </>
  )
}

type Props = {}

export default CompareBucket
