const initialCarVariantDetails = {
  make: '',
  model: '',
  variant: '',
  variantDescription: '',
  modelUri: '',
  id: ''
}

export default class SingleCarDetails {
  constructor(carVariantDetails = initialCarVariantDetails) {
    this.carVariantDetails = carVariantDetails
  }

  create() {
    return {
      make: this.carVariantDetails.make || '',
      model: this.carVariantDetails.model || '',
      variant: this.carVariantDetails.variant || '',
      variantDescription: this.carVariantDetails.variantDescription || '',
      modelUri: this.carVariantDetails.modelUri || '',
      id: this.carVariantDetails.id || ''
    }
  }
}
