import { RULES } from '@grille/utils/validation'
import { Form } from '.'

export const SUBSCRIBE_TO_NEWSLETTER: Form = {
  fields: [
    {
      labelClassName: 'white field-regular-width',
      placeholder: 'First name',
      name: 'firstName',
      type: 'text',
      rules: [
        {
          rule: RULES.isValidString,
          minLength: 1,
          message: 'Please enter a first name.'
        }
      ]
    },
    {
      labelClassName: 'white field-regular-width',
      placeholder: 'Last name',
      name: 'lastName',
      type: 'text',
      rules: [
        {
          rule: RULES.isValidString,
          minLength: 1,
          message: 'Please enter a last name.'
        }
      ]
    },
    {
      labelClassName: 'white field-regular-width',
      placeholder: 'Email',
      name: 'email',
      type: 'text',
      rules: [{ rule: RULES.isEmail, message: 'Invalid email adress.' }]
    },
    {
      className: 'checkboxes text-xs items-start md:items-end',
      name: 'checkboxes',
      type: 'checkbox',
      options: [
        {
          name: 'newsAndReviews',
          value: 'newsAndReviews',
          label: 'Stay up to date with the latest car news & reviews'
        },
        {
          name: 'offerNewAndUsed',
          value: 'offerNewAndUsed',
          label: 'Get Marketplace news & offers for new & used cars'
        },
        {
          name: 'updatesAndPromotions',
          value: 'updatesAndPromotions',
          label: "Receive updates & promotions from Drive's partners"
        }
      ],
      rules: []
    }
  ],
  labels: [
    {
      id: 'enquiry-agree-label',
      name: 'enquiryAgreeLabel',
      hidden: false,
      className: 'text-white',
      label: `By clicking the Send Enquiry button you acknowledge that you have read and agree to the
      Drive <a style="color: inherit; text-decoration: underline;" target="_blank" href="/about-drive/terms-conditions/">Terms and Conditions </a>
      and <a style="color: inherit; text-decoration: underline;" target="_blank" href="/about-drive/privacy-policy/">Privacy Policy.</a>`
    }
  ],
  buttons: [
    {
      className: 'bg-white text-15px font-semibold',
      color: 'white',
      text: 'Subscribe',
      type: 'submit'
    }
  ]
}
